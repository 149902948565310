// src\components\POIContent.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import L from 'leaflet';
import { Tree } from 'antd';
import { IoClose } from "react-icons/io5";
import styles from '../styles/poi.module.css';
import { RiDragMove2Line } from "react-icons/ri";
import { FaTrashCan } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { FaMapMarkerAlt } from "react-icons/fa";

import { GetAllPOIDetails, createNewGroupPOI, createNewPoi } from "../apiCalls";


const POIContent = ({ onClose, showToast, mapRef, handleShowPopup, handleClosePopup, setMarkers, markers, clearMeasurementData,
  checkedKeys, setCheckedKeys , hiddenMarkers, drawPoisOnMap
 }) => {
  
  const [mode, setMode] = useState("list"); // 'list', 'new', 'edit'
  const [icons, setIcons] = useState([]); // รายการไอคอน
  const [isLoading, setisLoading] = useState(false); // สำหรับแสดงสถานะโหลด
  const [isPinMode, setIsPinMode] = useState(false); // ตรวจสอบว่าอยู่ในโหมดปักหมุดหรือไม่
  const [ZL, setZL] = useState(13);
  const markerRef = useRef(null); // อ้างอิงถึง icon ที่ปักในแผนที่
  const mapClickHandlerRef = useRef(null); // ใช้เก็บฟังก์ชัน Listener
  const [canMove, setCanMove] = useState(false);
  const [groups, setGroups] = useState([]); // เก็บข้อมูลกลุ่ม
  const [treeData, setTreeData] = useState([]);

  // โหลดไฟล์ SVG ทั้งหมดในโฟลเดอร์
  useEffect(() => {
    const loadIcons = () => {
      const requireIcons = require.context("../assets/icons", false, /\.svg$/); // โหลดไฟล์ SVG
      const iconPaths = requireIcons.keys().map((key) => requireIcons(key)); // ดึงพาธไอคอน
      setIcons(iconPaths); // เก็บพาธไอคอนใน state
    };

    loadIcons();
  }, []);



 // ฟังก์ชันสร้าง Tree Data
 const generatePoiTreeData = (groups) => {
  const formattedTreeData = groups.map((group) => ({
    title: group.name, // ชื่อกลุ่ม
    key: `group-${group.id}`, // ใช้ id ของกลุ่มเป็น key
    children: group.pois?.map((poi, index) => {
      // ตรวจสอบว่า poi.id มีค่าหรือไม่
      if (!poi.id) {
        console.error(`POI ID is missing for ${poi.name}`);
      }

      return {
        title: `${poi.name}`, // ชื่อ POI
        key: `group-${group.id}-poi-${poi.id}`, // ใช้ POI id หรือ index เป็น Key
        description: poi.description || "", // เพิ่ม description หากจำเป็น
        icon: poi.icon || "", // เพิ่มไอคอนถ้ามี
        color: poi.color || "#000000", // สีของ POI
        size: poi.size || 10, // ขนาดของ POI
        zl_show: poi.zl_show || null,
        isVisible: poi.isVisible || false,
      };
    }) || [],
  }));

  console.log("Formatted POI Tree Data:", formattedTreeData); // ตรวจสอบข้อมูล

  // อัปเดต Tree Data โดยตรง
  setTreeData(formattedTreeData);

  // เรียกฟังก์ชัน drawPoisOnMap
    drawPoisOnMap();
};



const handleTreeCheck = useCallback((checkedKeys, info) => {

  // เก็บแค่ `poiId` ออกมาจาก checkedKeys
  const poiIds = checkedKeys
    .filter(key => key.startsWith('group-') && key.includes('poi-'))  // กรองเฉพาะ POI
    .map(key => key.split('-')[3]); // ตัดแค่ `poiId`



  setCheckedKeys(poiIds);  // เก็บเฉพาะ `poiId` ใน checkedKeys
}, []);






  //  ####################### ฟังชั่นเชื่อมต่อ api บันทึกข้อมูล โหลดข้อมูล #############################

  // state เก็บค่าเพื่อส่งเรียก api 
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null); // เก็บไอคอนที่เลือก
  const [group, setGroup] = useState(""); // เก็บค่ากลุ่มจาก dropdown
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null }); // เก็บพิกัด พิกัดของหมุดที่ปักลงไป
  const [size, setSize] = useState(50); // ค่าเริ่มต้นของ range
  const [zoomLevel, setZoomLevel] = useState(13);
  const [color, setColor] = useState("#b5b5b5"); // ค่าเริ่มต้นของสี
  const [selectedGroup, setSelectedGroup] = useState(""); // เก็บค่าที่เลือก


    // ใช้ useEffect สำหรับการโหลดข้อมูลครั้งแรก
    useEffect(() => {
      fetchPoiData(); // เรียกฟังก์ชันที่แยกไว้
    }, []); // เรียกครั้งเดียวตอน component mount


// ฟังก์ชันสำหรับดึงข้อมูล POI
const fetchPoiData = async () => {
  setisLoading(true);
  const response = await GetAllPOIDetails(); // เรียก API
  if (response.success) {
    const storedGroups = localStorage.getItem("poi");
    if (storedGroups) {
      const parsedGroups = JSON.parse(storedGroups);
      setGroups(parsedGroups.data || []);

      // สร้าง Tree Data สำหรับ POI
      generatePoiTreeData(parsedGroups.data || []);
    } else {
      console.error("No groups found in LocalStorage.");
      setGroups([]);
      setTreeData([]); // เคลียร์ Tree Data
    }
  } else {
    console.error("Error fetching POI details:", response.message);
  }
  setisLoading(false);
};


  
    

    
  // ฟังชั่นกด save ในหน้าสร้าง poi 
  const handlePoiSave = async (e) => {

    setisLoading(true);

    e.preventDefault();
  
    // ตรวจสอบค่าในฟอร์มก่อน
    if (!name.trim()) {
      alert("กรุณากรอกชื่อ");
      setisLoading(false); 
      return;
    }
  
    if (!description.trim()) {
      alert("กรุณากรอกคำอธิบาย");
      setisLoading(false); 
      return;
    }
  
    if (!selectedIcon) {
      alert("กรุณาเลือกไอคอน");
      setisLoading(false); 
      return;
    }
  
    if (!coordinates.lat || !coordinates.lng) {
      alert("กรุณาระบุพิกัด");
      setisLoading(false); 
      return;
    }
  
    if (!selectedGroup) {
      alert("กรุณาเลือกกลุ่ม");
      setisLoading(false); 
      return;
    }
  
    // เตรียมข้อมูลเพื่อส่งไปยัง API
    const data = {
      name: name.trim(),
      description: description.trim(),
      icon: selectedIcon,
      group_id: selectedGroup, // ส่ง group_id เพื่อสร้างความสัมพันธ์
      boundary: {
        lat: coordinates.lat, // พิกัด Latitude
        lng: coordinates.lng, // พิกัด Longitude
      },
      size: size,
      zl_show: zoomLevel, // แสดงที่ zoom level
      color: color,
    };
  
    console.log("Data to be submitted:", data); // ตรวจสอบข้อมูลก่อนส่ง
  
    try {
      setisLoading(true);
  
      // เรียก API
      const response = await createNewPoi(data); // createNewPoi คือฟังก์ชันที่เรียก API
  
      if (response.success) {
        showToast("ดำเนินการสำเร็จ!", "success");
        setMode("list"); // กลับไปหน้าแสดงรายการ
        clearMeasurementData();
        fetchPoiData();
        setisLoading(false);
      } else {
        showToast(response.message, "error");
        setisLoading(false); 
      }
    } catch (error) {
      console.error("Error saving data:", error);
      showToast("เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", "error");
      setisLoading(false); 
    } finally {
      setisLoading(false); 
    }
  };
  

  

  // ฟังชั่นนร save ในหน้าสร้างกลุ่ม 
  const handleGroupSave = async (e) => {
    e.preventDefault();

    setisLoading(true);

    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || !user.id) {
      console.error("User not found in localStorage. Current value:", localStorage.getItem("user"));
      alert("User authentication failed. Please login again.");
      return;
    }
  
    const data = {
      name: name,
      description: description,
      user_id: user.id, 
    };
  
    const response = await createNewGroupPOI(data);
  
    if (response.success) {
      showToast("กลุ่มถูกสร้างสำเร็จ!", "success");
      setMode("list");
      fetchPoiData();
      setisLoading(false);
    } else {
      alert(response.message);
      showToast(response.message, "error");
    }  
  };



  //  ####################### ฟังชั่นปักหมุด #############################

  // เมื่อสี, ขนาด, พิกัด, หรือไอคอนเปลี่ยน, อัปเดตไอคอนบนแผนที่
  useEffect(() => {
    if (markerRef.current) {
      const updatedIcon = L.divIcon({
        className: "custom-div-icon",
        html: `
          <div style="
            background-color: ${color};
            border-radius: 50%;
            width: ${size}px;
            height: ${size}px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
            <img src="${selectedIcon}" style="width: ${size * 0.6}px; height: ${size * 0.6}px;" />
          </div>
        `,
        iconSize: [size, size], // อัปเดตขนาดไอคอน
        iconAnchor: [size / 2, size / 2], // ยึดไอคอนตรงกลาง
        color,
      });
      markerRef.current.setIcon(updatedIcon); // อัปเดตไอคอนของหมุด
    }
  }, [color, size, selectedIcon, coordinates]); // ทำงานเมื่อ color, size หรือ selectedIcon เปลี่ยน


  // เริ่มโหมดปักหมุด
  const handleIconClick = (icon) => {
    setSelectedIcon(icon);

    if (!markerRef.current) {
      setIsPinMode(true);
      handleShowPopup("คลิกซ้ายเพื่อปักหมุด คลิกขวาเพื่อยกเลิก");

      const boundHandleMapClick = (event) => handleMapClick(event, icon);
      mapRef.current.addEventListener("click", boundHandleMapClick);
      mapRef.current.addEventListener("contextmenu", handleCancelPinMode);

      mapClickHandlerRef.current = boundHandleMapClick; // เก็บฟังก์ชัน Listener
    } else {
      const currentColor = markerRef.current.options.icon.options.color;
      const updatedIcon = L.divIcon({
        className: "custom-div-icon",
        html: `
          <div style="
            background-color: ${currentColor};
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
            <img src="${icon}" style="width: 30px; height: 30px;" />
          </div>
        `,
        iconSize: [50, 50],
        iconAnchor: [25, 25],
        color: currentColor,
      });
      markerRef.current.setIcon(updatedIcon);
    }
  };

  
  // ปักหมุดในแผนที่
// ปักหมุดในแผนที่
const handleMapClick = (event, icon) => {
  if (markerRef.current) {
    console.log("หมุดถูกปักแล้ว ไม่สามารถปักหมุดได้อีก");
    return;
  }

  const { lat, lng } = event.latlng || { lat: 0, lng: 0 };

  // อัปเดตพิกัดใน state
  setCoordinates({ lat, lng });

  const customIcon = L.divIcon({
    className: "custom-div-icon",
    html: `
      <div style="
        background-color: ${color};
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        display: flex;
        align-items: center;
        justify-content: center;
      ">
        <img src="${icon}" style="width: ${size * 0.6}px; height: ${size * 0.6}px;" />
      </div>
    `,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    color,
  });

  const marker = L.marker([lat, lng], {
    icon: customIcon,
    draggable: canMove, // เปิดการลาก (draggable)
  }).addTo(mapRef.current);

  markerRef.current = marker;

  // เพิ่ม Event Listener สำหรับการลากหมุด
  marker.on("dragend", () => {
    const { lat, lng } = marker.getLatLng();
    setCoordinates({ lat, lng }); // อัปเดตพิกัดใน state เมื่อหมุดถูกลาก
  });

  // เพิ่มหมุดในรายการ
  setMarkers((prevMarkers) => [...prevMarkers, marker]);

  setIsPinMode(false);
  handleClosePopup();

  // ลบ Listener
  if (mapClickHandlerRef.current) {
    mapRef.current.removeEventListener("click", mapClickHandlerRef.current);
  }
};

  

  // ยกเลิกโหมดปักหมุด
  const handleCancelPinMode = (event) => {
    if (event?.preventDefault) event.preventDefault();

    if (mapRef.current) {
      mapRef.current.removeEventListener("click", mapClickHandlerRef.current);
      mapRef.current.removeEventListener("contextmenu", handleCancelPinMode);
    }
    setIsPinMode(false);
    handleClosePopup();
  };

  // อัปเดต Marker เมื่อค่า canMove เปลี่ยน
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.dragging[canMove ? 'enable' : 'disable']();
    }
  }, [canMove]);



// ฟังชั่นกดแก้ไขใน tree
const [currentPOIId, setCurrentPOIId] = useState(null);
const handleEdit = (key) => {
  // ดึงข้อมูลจาก Local Storage
  const storedData = JSON.parse(localStorage.getItem("poi")) || {};
  console.log("ข้อมูลจาก Local Storage:", storedData);

  // ตรวจสอบว่ามี `data` และเป็นอาร์เรย์
  const groups = Array.isArray(storedData.data) ? storedData.data : [];
  
  if (key.includes("poi")) {
    console.log("แก้ไข POI:", key);

    // ค้นหา POI ที่ต้องการแก้ไข
    const poi = groups
      .flatMap((group) =>
        group.pois?.map((poi) => ({
          ...poi,
          groupId: group.id,
        })) || []
      )
      .find((poi) => `group-${poi.groupId}-poi-${poi.id}` === key);

    if (poi) {
      console.log("POI ที่พบ:", poi);

      // ตั้งค่าข้อมูลฟอร์ม
      setCurrentPOIId(poi.id);
      setName(poi.name);
      setDescription(poi.description || "");
      setCoordinates({ lat: poi.lat || 0, lng: poi.lng || 0 });
      setColor(poi.color || "#000000");
      setSize(poi.size || 1);
      setCanMove(poi.canMove || false);
      setZL(poi.zoomLevel || 13);
      setMode("editPoi");
    } else {
      console.error("ไม่พบ POI ที่ต้องการแก้ไข:", key);
    }
  } else {
    console.log("แก้ไขกลุ่ม:", key);

    // ค้นหากลุ่มที่ต้องการแก้ไข
    const group = groups.find((group) => `group-${group.id}` === key);

    if (group) {
      console.log("Group ที่พบ:", group);

      // ตั้งค่าข้อมูลฟอร์ม
      setName(group.name);
      setDescription(group.description || "");
      setMode("editGroup");
    } else {
      console.error("ไม่พบกลุ่มที่ต้องการแก้ไข:", key);
    }
  }
};


const handlePin = (key) => {
  // ดึงข้อมูลจาก Local Storage
  const storedData = JSON.parse(localStorage.getItem("poi")) || {};

  // ตรวจสอบว่า storedData มีฟิลด์ data และเป็นอาร์เรย์
  const groups = Array.isArray(storedData.data) ? storedData.data : [];

  // ค้นหา POI ที่ตรงกับ key
  const poi = groups
    .flatMap((group) =>
      group.pois?.map((poi) => ({
        ...poi,
        groupId: group.id,
      })) || []
    )
    .find((poi) => `group-${poi.groupId}-poi-${poi.id}` === key);

  if (!poi) {
    console.error("POI not found for key:", key);
    return;
  }

  // แปลง boundary เป็นพิกัด
  const poiCenter = parseBoundary(poi.boundary);

  if (!poiCenter) {
    console.error("Unable to parse boundary:", poi.boundary);
    return;
  }

  // ดึงค่า zoomLevel
  const zoomLevel = poi.zl_show || 15; // ถ้าไม่มี `zl_show` ใช้ค่าเริ่มต้น 15

  // ซูมและย้ายไปยังตำแหน่ง POI
  if (mapRef.current) {
    mapRef.current.setView([poiCenter.lat, poiCenter.lng], zoomLevel);
    console.log(`Map moved to POI at:`, poiCenter, `with zoom level: ${zoomLevel}`);
  } else {
    console.error("Map reference is not available.");
  }
};


const parseBoundary = (boundary) => {
  if (!boundary || !boundary.startsWith("POINT(")) {
    console.error("Invalid boundary format:", boundary);
    return null;
  }

  // ตัดคำว่า "POINT(" และ ")" ออก แล้วแยกค่าพิกัดด้วยช่องว่าง
  const coords = boundary.replace("POINT(", "").replace(")", "").split(" ");
  
  // คืนค่าเป็นออบเจกต์ { lat, lng }
  return {
    lng: parseFloat(coords[0]), // ลองติจูด
    lat: parseFloat(coords[1]), // ละติจูด
  };
};


  const handleEditGroupSave = (key) => {}
  const handleEditPoiSave = (key) => {}

  // --------------------------------------------------------------------- HTML -------------------------------------------------------

  return (

    <div className={styles.cardSidebar}>
      {/* ปุ่มปิด */}
      <button className={styles.closeButton} onClick={onClose}>
        <IoClose size={24} />
      </button>

      {/* ชื่อหัวข้อ */}
      <p className={styles.POITitle}>


        {mode === 'list' && 'POI'}
        {mode === 'newGroup' && 'Create Group'}
        {mode === 'newPoi' && 'Create POI'}
        {mode === 'editGroup' && 'Edit Group'}
        {mode === 'editPoi' && 'Edit Poi'}


      </p>


      {/* Spin loading */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinnerX"></div>
        </div>
      )}



      {!isLoading && mode === 'list' && (
        <>
          {/* ค้นหา */}
          <div className={styles.inputField}>
            <input type="text" placeholder="Please enter a name" />
          </div>

          {/* กลุ่มปุ่ม */}
          <div className={styles.buttonGroup}>
            {/* ปุ่มด้านซ้าย */}
            <div className={styles.leftButtons}>
              <button
                className={styles.button}
                onClick={() => setMode('newPoi')}
              >
                + New
              </button>
              <button 
                className={styles.button}
                onClick={() => setMode('newGroup')}
              >
                + Add group

                </button>
            </div>

            {/* ไอคอนด้านขวา */}
            <div className={styles.rightIcons}>
              <RiDragMove2Line size={20} className={styles.icon} />
              <FaTrashCan size={18} className={styles.icon} />
            </div>
          </div>

          {/* ส่วนรายการกลุ่ม */}

          <div className={styles.treeWrapper}>



            <Tree
              checkable
              defaultExpandAll
              treeData={treeData}
              selectable={false}
              onCheck={handleTreeCheck}
              titleRender={(nodeData) => {
                const isParent = nodeData.children && nodeData.children.length >= 0;

                return (
                  <div
                    className={`${styles.treeNodeTitleWrapper} ${
                      isParent ? styles.parentNode : styles.childNode
                    }`}
                  >
                    <div className={styles.treeNodeTitle}>
                      <strong>{nodeData.title}</strong>
                      {nodeData.description && (
                        <span style={{ marginLeft: "10px", color: "gray" }}>
                          {nodeData.description}
                        </span>
                      )}
                    </div>
                    <div className={styles.treeNodeIcon}>
                      <CiEdit
                        title="แก้ไข"
                        size={16}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (typeof handleEdit === "function") handleEdit(nodeData.key);
                        }}
                      />
                      {!isParent && (
                        <FaMapMarkerAlt
                          title="ปักหมุด"
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (typeof handlePin === "function") handlePin(nodeData.key);
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            />
            {/* treeWrapper */}
          </div> 

        </>
      )}



      {mode === 'newGroup' && (
        <>
      <form className={styles.form} onSubmit={handleGroupSave}>
        {/* ชื่อ */}
        <label htmlFor="name">ชื่อ</label>
        <input
          id="name"
          type="text"
          placeholder="Enter POI name"
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* คำอธิบาย */}
        <label htmlFor="newPoi_desc" className={styles.label}>
          คำอธิบาย
        </label>
        <textarea
          id="newPoi_desc"
          placeholder="Enter POI description"
          className={styles.textarea}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>

        {/* ปุ่ม Save และ Back */}
        <div className={styles.footerButtons}>
          <button type="submit" className={styles.saveButton}>
            Save
          </button>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => setMode('list')}
          >
            Back
          </button>
        </div>
      </form>

        </>
      )}

      {mode === 'newPoi' && (
        <>

            {/* กล่องแสดงไอคอนที่มี Scrollbar */}
            <div
              style={{
                maxWidth: "400px",
                maxHeight: "300px",
                overflowY: "scroll",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              {/* แสดงรายการไอคอน */}
              <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }}>
                {icons.map((icon, index) => (
                  <div
                    key={index}
                    onClick={() => handleIconClick(icon)} // เรียก handleIconClick เมื่อคลิกไอคอน
                    style={{
                      margin: "10px",
                      cursor: "pointer",
                      textAlign: "center",
                      borderRadius: "50%", // รูปทรงวงกลม
                      backgroundColor: selectedIcon === icon ? color : "transparent",
                      padding: "10px",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <img
                      src={icon}
                      alt={`Icon ${index}`}
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>


            <form className={styles.form}>
              {/* ชื่อ */}
              <label htmlFor="newPoi_name" className={styles.label}>
                ชื่อ
              </label>
              <input
                id="newPoi_name"
                type="text"
                placeholder="Enter Poi name"
                className={styles.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              {/* คำอธิบาย */}
              <label htmlFor="newPoi_desc" className={styles.label}>
                คำอธิบาย
              </label>
              <textarea
                id="newPoi_desc"
                placeholder="Enter Poi description"
                className={styles.textarea}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>


                {/* Dropdown */}
                <label htmlFor="dropdownSelect">กลุ่ม:</label>
                <select
                  id="dropdownSelect"
                  className={styles.selectG}
                  value={selectedGroup}
                  onChange={(e) => setSelectedGroup(e.target.value)} // อัปเดตกลุ่มที่เลือก
                >
                  <option value="" disabled>
                    -- กรุณาเลือกกลุ่ม --
                  </option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>

                {/* แสดงกลุ่มที่เลือก */}
                <p>Selected Group: {selectedGroup}</p>



                {/* พิกัด */}
                <label className={styles.label}>พิกัด</label>
                <p>
                  Latitude: {coordinates.lat || "N/A"}, Longitude: {coordinates.lng || "N/A"}
                </p>

                {/* Checkbox สำหรับ CanMove */}
                <div style={{ marginTop: "10px" }}>
                  <label>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={canMove}
                      onChange={(e) => setCanMove(e.target.checked)}
                    />
                     ลากเพื่อเปลี่ยนพิกัด
                  </label>
                </div>


              {/* ขนาด */}
              <label htmlFor="newPoi_size" className={styles.label}>
                ขนาด
              </label>
              <input
                id="newPoi_size"
                type="range"
                min="1"
                max="100"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              />
              <p>Size: {size}</p>

              {/* zoom level */}
              <label htmlFor="newPoi_name" className={styles.label}>
                แสดงที่ ZL มากกว่าหรือเท่ากับเท่าไหร่ ?
              </label>
              <input
                id="zoom_level"
                type="number"
                defaultValue={13}
                onChange={(e) => setZL(Number(e.target.value))} 
                min={1}
                max={20}
                className={styles.input}
              />

              {/* สี */}
              <label htmlFor="newPoi_color" className={styles.label}>
                สี
              </label>
              <input
                id="newPoi_color"
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
              <p>Selected Color: <span style={{ color }}>{color}</span></p>
            </form>


          {/* ปุ่ม Save และ Back */}
          <div className={styles.footerButtons}>
            <button
              type="submit"
              className={styles.saveButton}
              onClick={handlePoiSave}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
            <button
              className={styles.backButton}
              onClick={() => setMode("list")}
            >
              Back
            </button>
        </div>
      </>
    )}


{mode === 'editGroup' && (
  <>
    <form className={styles.form} onSubmit={handleEditGroupSave}>
      {/* ชื่อ */}
      <label htmlFor="editGroup_name">ชื่อ</label>
      <input
        id="editGroup_name"
        type="text"
        placeholder="Enter group name"
        className={styles.input}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      {/* คำอธิบาย */}
      <label htmlFor="editGroup_desc" className={styles.label}>
        คำอธิบาย
      </label>
      <textarea
        id="editGroup_desc"
        placeholder="Enter group description"
        className={styles.textarea}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>

      {/* ปุ่ม Save และ Back */}
      <div className={styles.footerButtons}>
        <button type="submit" className={styles.saveButton}>
          Save
        </button>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => setMode('list')}
        >
          Back
        </button>
      </div>
    </form>
  </>
)}


{mode === 'editPoi' && (
  <>
    <form className={styles.form} onSubmit={handleEditPoiSave}>
      {/* ชื่อ */}
      <label htmlFor="editPoi_name" className={styles.label}>
        ชื่อ
      </label>
      <input
        id="editPoi_name"
        type="text"
        placeholder="Enter POI name"
        className={styles.input}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      {/* คำอธิบาย */}
      <label htmlFor="editPoi_desc" className={styles.label}>
        คำอธิบาย
      </label>
      <textarea
        id="editPoi_desc"
        placeholder="Enter POI description"
        className={styles.textarea}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>

      {/* Dropdown */}
      <label htmlFor="dropdownSelect">กลุ่ม:</label>
      <select
        id="dropdownSelect"
        className={styles.selectG}
        value={selectedGroup}
        onChange={(e) => setSelectedGroup(e.target.value)}
      >
        <option value="" disabled>
          -- กรุณาเลือกกลุ่ม --
        </option>
        {groups.map((group) => (
          <option key={group.id} value={group.id}>
            {group.name}
          </option>
        ))}
      </select>

      {/* พิกัด */}
      <label className={styles.label}>พิกัด</label>
      <p>
        Latitude: {coordinates.lat || "N/A"}, Longitude: {coordinates.lng || "N/A"}
      </p>

      {/* Checkbox สำหรับ CanMove */}
      <div style={{ marginTop: "10px" }}>
        <label>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={canMove}
            onChange={(e) => setCanMove(e.target.checked)}
          />
          ลากเพื่อเปลี่ยนพิกัด
        </label>
      </div>

      {/* ขนาด */}
      <label htmlFor="editPoi_size" className={styles.label}>
        ขนาด
      </label>
      <input
        id="editPoi_size"
        type="range"
        min="1"
        max="100"
        value={size}
        onChange={(e) => setSize(e.target.value)}
      />
      <p>Size: {size}</p>

      {/* zoom level */}
      <label htmlFor="zoom_level" className={styles.label}>
        แสดงที่ ZL มากกว่าหรือเท่ากับเท่าไหร่ ?
      </label>
      <input
        id="zoom_level"
        type="number"
        defaultValue={13}
        onChange={(e) => setZL(Number(e.target.value))}
        min={1}
        max={20}
        className={styles.input}
      />

      {/* สี */}
      <label htmlFor="editPoi_color" className={styles.label}>
        สี
      </label>
      <input
        id="editPoi_color"
        type="color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
      />
      <p>
        Selected Color: <span style={{ color }}>{color}</span>
      </p>

      {/* ปุ่ม Save และ Back */}
      <div className={styles.footerButtons}>
        <button type="submit" className={styles.saveButton}>
          Save
        </button>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => setMode('list')}
        >
          Back
        </button>
      </div>
    </form>
  </>
)}



    </div>
  );
};


export default POIContent;
