// src/apiCalls.js

import api from './api';


// ฟังก์ชันล็อกอิน
export const login = async (username, password, rememberMe, deviceId) => {
    try {
        const response = await api.post('/login', {
            username,
            password,
            remember_me: rememberMe,
            device_id: deviceId,
        });

        // console.log('Full Login Response:', response);

        let responseData = response.data;

        // หาก response.data เป็น string ที่มีข้อความแปลกปลอม
        if (typeof responseData === 'string') {
            const jsonStartIndex = responseData.indexOf('{');
            responseData = JSON.parse(responseData.slice(jsonStartIndex)); // แปลง string เป็น JSON
        }

        const { token, user } = responseData;

        if (!token || !user) {
            console.error('Invalid login response:', responseData);
            return { success: false, message: 'Invalid login response from server.' };
        }

        // บันทึกข้อมูลใน Local Storage
        localStorage.setItem('authToken', token);
        localStorage.setItem('user', JSON.stringify(user));

        return { success: true, token, user };
    } catch (error) {
        console.error('Login failed:', error);
        const message = error.response?.data?.error || 'An unexpected error occurred. Please try again later.';
        return { success: false, message };
    }
};


// ฟังก์ชันตรวจสอบสถานะการล็อกอิน
export const checkLoginStatus = () => {
    const token = localStorage.getItem('authToken');
    return !!token; // ส่งคืน true ถ้ามี token (ล็อกอินแล้ว), false ถ้าไม่มี (ยังไม่ล็อกอิน)
};


// ฟังก์ชันสำหรับออกจากระบบ
export const logout = async () => {
    try {
        const token = localStorage.getItem('authToken');
        const deviceId = localStorage.getItem('device_id'); // ดึง device_id จาก Local Storage

        if (!deviceId) {
            console.error('Device ID is missing.');
            return;
        }

        if (token) {
            await api.post(
                '/logout',
                { device_id: deviceId },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
        }
    } catch (error) {
        console.error('Error during logout:', error);
    } finally {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
    }
};





// ฟังก์ชันสำหรับสร้างกลุ่มใหม่
export const createGroup = async (data) => {
    try {
        const token = localStorage.getItem("authToken");

        const response = await api.post(
            "/groups/store",
            data, // ส่งข้อมูล group name และ user_id ไปยัง API
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        return { success: true, group: response.data };
    } catch (error) {
        console.error("Error creating group:", error);

        if (error.response && error.response.data && error.response.data.error) {
            return { success: false, message: error.response.data.error };
        }

        return { success: false, message: "An unexpected error occurred. Please try again later." };
    }
};

// ฟังก์ชันดึงข้อมูลรายละเอียดของ user เพื่ออัพเดทสถานะรายการรถหรือกลุ่ม
export const getUserDetails = async (userId) => {
  try {
      const token = localStorage.getItem("authToken");
      if (!token) {
          throw new Error("Authentication token is missing.");
      }

      const response = await api.get(`/user-details/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
      });

      // console.log("API response:", response.data);

      // ดึงข้อมูล `user` และ `gps_data`
      const user = response.data?.data?.user || null;
      const gpsData = response.data?.data?.gps_data || null;

      if (!user) {
          throw new Error("User data is missing from the response.");
      }

      // บันทึก `user` ลงใน localStorage
      localStorage.setItem("user", JSON.stringify(user));

      // ถ้ามี `gps_data` บันทึกลง localStorage
      if (gpsData) {
          localStorage.setItem("gps_data", JSON.stringify(gpsData));
      }

      return { success: true, user, gpsData };
  } catch (error) {
      console.error("Error fetching user details:", error);

      if (error.response?.data?.error) {
          return { success: false, message: error.response.data.error };
      }

      return { success: false, message: "An unexpected error occurred. Please try again later." };
  }
};



// ฟังชั่นอัปเดทข้อมูล 

export const updateData = async (type, data) => {
    try {
        const token = localStorage.getItem("authToken"); // ดึง Token จาก Local Storage
        if (!token) {
            throw new Error("Authentication token is missing.");
        }

        const response = await api.post(`/update`, { type, data }, { 
            headers: { Authorization: `Bearer ${token}` } // เพิ่ม Header
        });

        return response.data; // คืนค่าผลลัพธ์จาก API
    } catch (error) {
        console.error(`Error updating ${type}:`, error);

        if (error.response?.data?.error) {
            return { success: false, message: error.response.data.error };
        }

        throw error;
    }
};



// ฟังชั่นลบกลุ่ม

export const deleteGroupFromAPI = async (groupId) => {
    try {
        const token = localStorage.getItem("authToken"); // ดึง token จาก Local Storage
        if (!token) {
            throw new Error("Authentication token is missing.");
        }

        const response = await api.delete(`/groups/delete/${groupId}`, {
            headers: { Authorization: `Bearer ${token}` }, // เพิ่ม Header
        });

        return response.data; // คืนค่าผลลัพธ์จาก API
    } catch (error) {
        console.error("Error deleting group:", error);

        if (error.response?.data?.error) {
            return { success: false, message: error.response.data.error };
        }

        throw error;
    }
};

  // ฟังชั่นย้ายกลุ่ม
  
export const moveVehicleToGroup = async (vehicleId, newGroupId) => {
    try {
      const token = localStorage.getItem("authToken"); // ดึง Token จาก Local Storage
      if (!token) {
        throw new Error("Authentication token is missing.");
      }
  
      const response = await api.post(
        `/vehicles/move-group`,
        { vehicleId, newGroupId }, // Payload
        { headers: { Authorization: `Bearer ${token}` } } // Header
      );
  
      return response.data; // คืนค่าผลลัพธ์จาก API
    } catch (error) {
      console.error("Error moving vehicle to group:", error);
  
      if (error.response?.data?.error) {
        return { success: false, message: error.response.data.error };
      }
  
      throw error;
    }
  };
  
  // แก้ชื่อกลุ่ม 
  
  export const updateGroupName = async (groupId, newName) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await api.put(
        `/groups/update/${groupId}`,
        { name: newName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating group name:", error);
      throw error;
    }
  };



// ฟังก์ชันสำหรับอัปเดตข้อมูลผู้ใช้
export const userUpdate = async () => {
    try {
        const token = localStorage.getItem('authToken'); // ดึง Token จาก Local Storage

        if (!token) {
            throw new Error('Authentication token is missing.');
        }

        // ส่งคำขอไปยัง API
        const response = await api.put(
            '/users/update',
            {
                device_token: localStorage.getItem('fcmToken'),
                ip_address: localStorage.getItem('ipAddress'),
                location: localStorage.getItem('userLocation'),
                browser: navigator.userAgent,
                platform: 'web',
                device_name: navigator.userAgent,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error updating user:', error);

        if (error.response?.status === 401) {
            console.warn('Token expired. Logging out...');
            logout(); // เรียก logout อัตโนมัติเมื่อ Token หมดอายุ
        }

        throw error;
    }
};

  
// ฟังก์ชันสำหรับสร้าง Geo-f group ใหม่
export const createGeoFenceGroup = async (data) => {
    try {
        const token = localStorage.getItem('authToken'); 

        if (!token) {
            throw new Error('Authentication token is missing.');
        }

        const response = await api.post(
            '/geo-fence-groups',
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error creating geo-fence group:', error);

        return {
            success: false,
            message: error.response?.data?.message || 'An error occurred',
        };
    }
};


// ฟังชั่นสร้าง Geo-f 

export const geofenceCreate = async (data) => {
    try {
        // ดึง Token จาก localStorage
        const token = localStorage.getItem('authToken');

        if (!token) {
            throw new Error('Authentication token is missing.');
        }

        // ส่งคำขอไปยัง API
        const response = await api.post(
            '/geo-fence-create',
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`, // เพิ่ม Bearer Token
                    'Content-Type': 'application/json', // ระบุประเภทข้อมูล
                },
            }
        );

        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error creating geofence:', error);

        // คืนค่า error message
        return {
            success: false,
            message: error.response?.data?.message || 'An error occurred',
        };
    }
};


// ดึงข้อมูล Geo-F เรียกจาก GeofenceContent ตอน useEffect
export const getAllGe0fDetails = async () => {
    try {
        const token = localStorage.getItem("authToken");
        if (!token) {
            throw new Error("Authentication token is missing.");
        }

        const response = await api.get(`/geofence-details`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        console.log("Geo-fence details:", response.data);

        const geoFenceGroups = response.data?.data || [];

        // บันทึก geoFenceGroups ลงใน localStorage
        localStorage.setItem("geoFenceGroups", JSON.stringify(geoFenceGroups));
        

        return { success: true, geoFenceGroups };
    } catch (error) {
        console.error("Error fetching geo-fence details:", error);

        return { success: false, message: error.message };
    }
};

// ลบ Geo-f พร้อมกลุ่ม
export const deleteGeoFenceGroup = async (geoFenceGroupId) => {
    try {
        const token = localStorage.getItem("authToken");
        if (!token) {
            throw new Error("Authentication token is missing.");
        }

        const response = await api.delete(`/geofence-groups/${geoFenceGroupId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // console.log("Geo-fence group deleted successfully:", response.data);

        return { success: true, message: "Geo-fence group deleted successfully." };
    } catch (error) {
        console.error("Error deleting geo-fence group:", error);

        return { success: false, message: error.response?.data?.message || error.message };
    }
};

// ย้าย Geo-f ไปกลุ่มอื่น
export const moveGeoFenceGroup = async (geoFenceGroupIds, targetGroupId) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("Authentication token is missing.");
      }
  
      const response = await api.put(
        "/move-geofence-group",
        {
          ids: geoFenceGroupIds, // รายการ IDs ที่ต้องการย้าย
          targetGroupId, // กลุ่มเป้าหมาย
        },
        {
          headers: { Authorization: `Bearer ${token}` }, // ใส่ Headers
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error moving GeoFence group:", error);
  
      return {
        success: false,
        message: error.response?.data?.message || error.message,
      };
    }
  };  

//   อัพเดทชื่อกลุ่มของ Geo-F 
export const updateGeoFGroup = async (groupData) => {
  try {
      const token = localStorage.getItem("authToken");

      // ตรวจสอบและตั้งค่าที่เหมาะสมก่อนส่ง
      const dataToSend = {
        name: groupData.name || "",
        description: groupData.description || "",
        vehicles: groupData.vehicles ?? [], // ส่ง [] ถ้าไม่ส่งมา
        speed_limit: groupData.speed_limit ?? 0, // ส่ง 0 ถ้าไม่ส่งมา
        duration: groupData.duration ?? 0, // ส่ง 0 ถ้าไม่ส่งมา
        notify_enter: groupData.notify_enter ?? false,
        notify_exit: groupData.notify_exit ?? false,
        updateNotifications: groupData.updateNotifications ?? false, 
        updateVehicles: groupData.updateVehicles ?? false, 
        updateSpeed: groupData.updateSpeed ?? false, 
      };      

      console.log("Data being sent to API:", dataToSend);

      const response = await api.put(
          `/updateGroups/${groupData.id}`, // URL ของ API
          dataToSend,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
      );

      return { success: true, group: response.data };
  } catch (error) {
      console.error("Error updating group:", error);

      if (error.response && error.response.data && error.response.data.error) {
          return { success: false, message: error.response.data.error };
      }

      return { success: false, message: "An unexpected error occurred. Please try again later." };
  }
};


// อัปเดท Geo-F
export const updateGeoFence = async (geoData) => {
    try {
      const token = localStorage.getItem("authToken");
  
      const response = await api.put(
        `/updategeofence/${geoData.id}`, // ใช้ id จาก geoData
        {
          name: geoData.name,
          description: geoData.description,
          color: geoData.color,
          speed_limit: geoData.speed_limit,
          duration: geoData.duration,
          notify_enter: geoData.notify_enter,
          notify_exit: geoData.notify_exit,
          vehicles: geoData.vehicles,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error updating GeoFence:", error);
  
      if (error.response && error.response.data && error.response.data.error) {
        return { success: false, message: error.response.data.error };
      }
  
      return { success: false, message: "An unexpected error occurred. Please try again later." };
    }
  };
  
  // ดึงข้อมูล poi และ กลุ่ม 
  export const GetAllPOIDetails = async () => {
    try {
      const token = localStorage.getItem("authToken"); // รับ Token จาก LocalStorage
      const response = await api.get("/getallpoi", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const groups = response.data;
  
      // เก็บข้อมูลกลุ่มลงใน LocalStorage
      localStorage.setItem("poi", JSON.stringify(groups));
  
      return { success: true, data: groups };
    } catch (error) {
      console.error("Error fetching groups:", error);
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message };
      }
      return { success: false, message: "An unexpected error occurred." };
    }
  };
  
  

// สร้างกลุ่ม POI ใหม่
export const createNewGroupPOI = async (data) => {
    try {
      const token = localStorage.getItem("authToken"); // รับ Token จาก LocalStorage
  
      const response = await api.post(
        `/createnewgrouppoi`, // URL ของ API
        {
          name: data.name,
          description: data.description,
          user_id: data.user_id, 
        },
        {
          headers: { Authorization: `Bearer ${token}` }, // ส่ง Header Authorization
        }
      );
  
      return { success: true, data: response.data }; // ส่งผลลัพธ์กลับไป
    } catch (error) {
      console.error("Error creating new group POI:", error);
  
      // ตรวจสอบ error message จาก response
      if (error.response && error.response.data && error.response.data.error) {
        return { success: false, message: error.response.data.error };
      }
  
      return { success: false, message: "An unexpected error occurred. Please try again later." };
    }
  };
  
// สร้าง poi
  export const createNewPoi = async (data) => {
    try {
      const token = localStorage.getItem("authToken"); // รับ Token จาก LocalStorage
  
      // ส่งข้อมูลไปยัง API
      const response = await api.post(
        "/createnewpoi", // Endpoint API สำหรับสร้าง POI
        {
          name: data.name,
          description: data.description,
          icon: data.icon,
          group_id: data.group_id, // ความสัมพันธ์กับกลุ่ม
          boundary: data.boundary, // พิกัดในรูปแบบ {lat, lng}
          size: data.size,
          zl_show: data.zl_show, // Zoom Level
          color: data.color,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // ส่ง Token สำหรับ Authentication
            "Content-Type": "application/json",
          },
        }
      );
  
      // ส่งผลลัพธ์กลับ
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error creating POI:", error);
  
      // จัดการข้อผิดพลาดจาก API
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message };
      }
  
      // ข้อผิดพลาดอื่น ๆ
      return { success: false, message: "An unexpected error occurred. Please try again." };
    }
  };
  

  // ฟังชั่นทดสอบดึงข้อมูลเส้นทางมาแสดงในหน้าประวัติ

  export const getTripRoutes = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("Authentication token is missing.");
      }
  
      const response = await api.get(`/trip-routes`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Debug: Log response
      console.log("API Response:", response.data);
  
      const routes = response.data?.data || null;
  
      if (!routes) {
        throw new Error("Routes data is missing from the response.");
      }
  
      return { success: true, data: routes };
    } catch (error) {
      console.error("Error fetching trip routes:", error);
  
      if (error.response?.data?.message) {
        return { success: false, message: error.response.data.message };
      }
  
      return { success: false, message: "An unexpected error occurred. Please try again later." };
    }
  };

  // ดึงข้อมูล gps data ตาม loop
  export const getGps_data = async (imeis) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("Authentication token is missing.");
      }
  
      if (!imeis || imeis.length === 0) {
        throw new Error("IMEIs are required to fetch GPS data.");
      }
  
      const response = await api.post(
        `/gps-data`,
        { imeis }, // ส่ง imeis ใน body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Debug: Log response
      // console.log("GPS API Response:", response.data);
  
      const gpsData = response.data?.data || null;
  
      if (!gpsData) {
        throw new Error("GPS data is missing from the response.");
      }
  
      return { success: true, data: gpsData };
    } catch (error) {
      console.error("Error fetching GPS data:", error);
  
      if (error.response?.data?.message) {
        return { success: false, message: error.response.data.message };
      }
  
      return { success: false, message: "An unexpected error occurred. Please try again later." };
    }
  };

  


//   ในการเรียก API ใช้ axios ผ่านตัวแปร api ที่เป็น instance ของ axios ที่กำหนดค่ามาแล้ว ต้องมี headers 