// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// กำหนดค่า Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyC-rE7sut5FJo174h_Gri9DeLQW56sGJQw',
  authDomain: 'gps-track-d94ef.firebaseapp.com',
  projectId: 'gps-track-d94ef',
  storageBucket: 'gps-track-d94ef.firebasestorage.app',
  messagingSenderId: '232755689446',
  appId: '1:232755689446:web:be330c349beb2ca1a78827',
  measurementId: 'G-QRYL0BZ6NT',
};

// เริ่มต้น Firebase
const app = initializeApp(firebaseConfig);

// เริ่มต้น Firebase Cloud Messaging
const messaging = getMessaging(app);

// ขอสิทธิ์การแจ้งเตือนจากผู้ใช้
Notification.requestPermission()
  .then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // รับโทเค็นการลงทะเบียน
      getToken(messaging, { vapidKey: 'BIgQZLkbNkoJqm1yGaelgkFV9ZIZ3vRk9k7zwVqY5HH9bUJywxGEWxn_Cpb8ifMiuDMyDbz1pTLcD-ha0flpOvk' })
        .then((currentToken) => {
          if (currentToken) {
            // console.log('FCM Token:', currentToken);
            // ส่งโทเค็นไปยังเซิร์ฟเวอร์ของคุณเพื่อจัดเก็บ
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    } else {
      console.log('Unable to get permission to notify.');
    }
  })
  .catch((err) => {
    console.error('Error during permission request:', err);
  });

// จัดการข้อความเมื่อแอปอยู่ในโฟร์กราวด์
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // กำหนดวิธีการแสดงการแจ้งเตือนในโฟร์กราวด์
});

// ลงทะเบียน Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((err) => {
        console.log('Service Worker registration failed:', err);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// หากคุณต้องการเริ่มต้นการวัดประสิทธิภาพในแอปของคุณ
// สามารถส่งฟังก์ชันเพื่อบันทึกผลลัพธ์ (เช่น reportWebVitals(console.log))
// หรือส่งไปยัง endpoint สำหรับการวิเคราะห์เพิ่มเติม
// เรียนรู้เพิ่มเติม: https://bit.ly/CRA-vitals
reportWebVitals();
