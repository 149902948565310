import React, { useState, useEffect, useRef } from "react";
import { FaPlay, FaStop, FaPause } from "react-icons/fa";
import L from "leaflet";

const PlaybackW = ({ onPlay, onPause, onStop, onSpeedChange  }) => {
  const containerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);

  useEffect(() => {
    if (containerRef.current) {
      // ป้องกันการคลิกและการเลื่อนมีผลกับแผนที่
      L.DomEvent.disableClickPropagation(containerRef.current);
      L.DomEvent.disableScrollPropagation(containerRef.current);
    }
  }, []);

  const handleTogglePlay = () => {
    if (isPlaying) {
      onPause(); 
    } else {
      onPlay(); 
    }
    setIsPlaying((prev) => !prev); // สลับสถานะ
  };

  const handleStop = () => {

    if (isPlaying) {
        setIsPlaying((prev) => !prev);
      }
      onStop();
  }

  const handleSpeedChange = (event) => {
    const selectedSpeed = parseFloat(event.target.value);
    setSpeed(selectedSpeed);
    onSpeedChange(selectedSpeed); 
  };

  return (
      <div
        ref={containerRef}
        style={{
          position: "absolute",
          zIndex: 2000,
          background: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          margin: "10px 0px 0px 10px",
          width: "400px",
        }}
      >
        <div
          className="drag-handle"
          style={{
            padding: "2px 5px",
            borderBottom: "1px solid #ccc",
          }}
        >
          Playback Control
        </div>
        <div style={{ padding: "5px 5px", display: "flex", alignItems: "center", gap: "5px" }}>
          <button
            onClick={handleTogglePlay}
            style={{
              background: isPlaying ? "red" : "green",
              border: "none",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: isPlaying ? "0px" : "1px",
              cursor: "pointer",
            }}
          >
            {isPlaying ? <FaPause size={10} color="white" /> : <FaPlay size={10} color="white" />}
          </button>
          <button
            onClick={handleStop}
            style={{
              background: "orange",
              border: "none",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
        <FaStop size={10} color="white" />
          </button>
          <input
            type="range"
            min="0"
            max="100"
            style={{ flexGrow: 1 }}
          />
        </div>
      

      {/* ----------- แถว 2  */}


        <div style={{ marginTop: "5px", display: "flex", alignItems: "center", gap: "5px" }}>
          <label htmlFor="speed" style={{ fontSize: "12px", fontWeight: "bold" }}>Speed:</label>
          <select
            id="speed"
            value={speed}
            onChange={handleSpeedChange}
            style={{
              padding: "2px",
              border: "0.1px solid #ccc",
              borderRadius: "4px",
              fontSize: "12px",
            }}
          >
            <option value="0.1">x0.1</option>
            <option value="0.5">x0.5</option>
            <option value="1">x1</option>
            <option value="2">x2</option>
            <option value="4">x4</option>
            <option value="8">x8</option>
          </select>
        </div>



        </div>
  );
};

export default PlaybackW;
