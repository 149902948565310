import React, { useState } from 'react';
import '../styles/AutoReportW3.css';
import { GoTriangleLeft } from "react-icons/go";

const AutoReportW3 = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  return (
    <div className="AutoReportW3-scope">
      <table className="AutoReportW3-table">
        <tbody>
          <tr>
            <td
              className={`AutoReportW3-sidebar ${
                isSidebarCollapsed ? "collapsed" : ""
              }`}
            >
              <div className="AutoReportW3-card-group">
                {!isSidebarCollapsed && (
                  <div className="AutoReportW3-card1">
                    <h3>AutoReportW3</h3>
                    <p>This is the first card.</p>
                  </div>
                )}
                <div className="AutoReportW3-card2" onClick={toggleSidebar}>
                  <GoTriangleLeft size={25} />
                </div>
              </div>
            </td>

            <td className="AutoReportW3-content">
              <div className="AutoReportW3-placeholder">
                <img
                  src="https://via.placeholder.com/300x200"
                  alt="No reports"
                  className="AutoReportW3-placeholderImage"
                />
                <p className="AutoReportW3-placeholderText">
                  No report data to display.
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AutoReportW3;
