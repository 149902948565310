import React, { useState, useEffect, useRef } from "react";
import { Tree, Input, DatePicker, Select, Button } from "antd";
import moment from "moment";

import { FaFolder } from "react-icons/fa";

import TripHistory from "./TripHistory";

import styles from "../styles/AlertW2.module.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AlertW2 = () => {
  const [treeData, setTreeData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const dropdownRef = useRef(null);

  // กดปุ่มค้นหา 
  const handleButtonClick = () => {
    console.log("Selected Range:", dates);
    console.log("Selected Option:", selectedRange);
    // เพิ่มฟังก์ชันการทำงานที่ต้องการ
  };

  
  // ดึงข้อมูลจาก localStorage มาสร้าง tree 
  const loadUserData = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      const gpsStatusData = JSON.parse(localStorage.getItem("gps_status")) || {}; // ดึงสถานะ GPS จาก localStorage
  
      const groups = userData.groups.map((group) => {
        const children = (group.vehicles || []).map((vehicle) => {
          const iconPath = `/assets/car_icon_list/${vehicle.icon ? vehicle.icon + ".svg" : "1.svg"}`;
          const vehicleStatus = gpsStatusData[vehicle.id]?.gpsStatus || "offline";
  
          // กำหนดสีพื้นหลังตามสถานะ
          const backgroundColor =
            vehicleStatus === "offline"
              ? "#424242"
              : vehicleStatus === "stop"
              ? "#f00232"
              : vehicleStatus === "idle"
              ? "#bfbf30"
              : vehicleStatus === "run"
              ? "#00bf17"
              : vehicleStatus === "v"
              ? "#0d7ad4"
              : "#424242";
  
          return {
            title: (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {/* พื้นหลังของไอคอน */}
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    transform: 'scaleX(-1)', // พลิกแนวนอน
                  }}
                >
                  <img src={iconPath} alt="vehicle icon" style={{ width: "14px", height: "14px" }} />
                </div>
                <span>
                  {vehicle.license_plate} [ {vehicle.gps_device?.imei || "No IMEI"} ]
                </span>
              </div>
            ),
            key: `vehicle-${vehicle.id}`, // ใช้ key สำหรับการเลือก
            value: `vehicle-${vehicle.id}`,
            className: "child-node",
          };
        });
  
        return {
          title: (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <FaFolder style={{ color: "#2e2e2e", fontSize: "14px" }} />
              <span>{group.name}</span>
            </div>
          ),
          key: `group-${group.id}`,
          value: `group-${group.id}`,
          children: children.length > 0 ? children : undefined,
          className: "parent-node",
        };
      });
  
      setTreeData(groups); // ตั้งค่า treeData
    }
  };
  

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedKeys, { node }) => {
    if (node.key.startsWith("group-")) {
      // เปิด/ปิดกลุ่ม
      setExpandedKeys((prevKeys) =>
        prevKeys.includes(node.key)
          ? prevKeys.filter((key) => key !== node.key) // ปิดกลุ่ม
          : [...prevKeys, node.key] // เปิดกลุ่ม
      );
      return;
    }
  
    // เลือกโหนดย่อย
    setSelectedValue(node.title.props.children[1].props.children[0]); // เลือก vehicle.license_plate
    setIsDropdownVisible(false); // ปิด dropdown
  };
  


  // dropdown เลือกเวลา

  const [selectedRange, setSelectedRange] = useState(null);
  const [dates, setDates] = useState(null);
  const [clearDates, setClearDates] = useState(false);
  const [clearSelect, setClearSelect] = useState(false);

  const handleRangePickerOpen = (open) => {
    if (open) {
      setDates(null); // เคลียร์ค่า RangePicker
      setSelectedRange(null); // เคลียร์ค่า Select
    }
  };
  
  const handleRangeChange = (value) => {
    setDates(value);
    setSelectedRange(null); // รีเซ็ต Select เมื่อ RangePicker ถูกเปลี่ยน
  };  
  
  const handleSelectChange = (value) => {
    setSelectedRange(value);
  
    // กำหนดค่า RangePicker ตาม Option ที่เลือก
    let newDates = null;
    switch (value) {
      case "วันนี้":
        newDates = [moment().startOf("day"), moment().endOf("day")];
        break;
      case "เมื่อวาน":
        newDates = [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ];
        break;
      case "สัปดาห์นี้":
        newDates = [moment().startOf("week"), moment().endOf("week")];
        break;
      case "สัปดาห์ก่อน":
        newDates = [
          moment().subtract(1, "weeks").startOf("week"),
          moment().subtract(1, "weeks").endOf("week"),
        ];
        break;
      case "เดือนนี้":
        newDates = [moment().startOf("month"), moment().endOf("month")];
        break;
      case "เดือนก่อน":
        newDates = [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ];
        break;
      default:
        newDates = null;
    }
    setDates(newDates); // อัปเดตค่าใน RangePicker
  };
  


    // -------------------------------------------------------------------------- html ---------------------------------------- 

  return (
    <div className={styles.bodyW3}>
      <h2>แจ้งเตือน</h2>
      <span className={styles["span-label"]}> เลือกยานพาหนะ </span>
      <div style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
        <Input
          placeholder="โปรดเลือกยานพาหนะ"
          value={selectedValue}
          onFocus={() => setIsDropdownVisible(true)}
          readOnly
        />
        {isDropdownVisible && (
          <div
            style={{
              position: "absolute",
              paddingTop: "30px",
              zIndex: 1000,
              width: "100%",
              border: "1px solid #d9d9d9",
              background: "#fff",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
              height: "300px",
              overflow: "auto",
            }}
          >
            <Tree
              className="custom-tree"
              blockNode
              treeData={treeData.map((item) => ({
                ...item,
                style: {
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#2e2e2e",
                },
                children: item.children?.map((child) => ({
                  ...child,
                  style: {
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "10px",
                    color: "#2e2e2e",
                  },
                })),
              }))}
              onSelect={handleSelect}
              onExpand={(keys) => setExpandedKeys(keys)}
              expandedKeys={expandedKeys}
              defaultExpandAll={false}
            />
          </div>
        )}
      </div>

      <span className={styles["span-label"]}>วันที่และเวลา</span>

      <RangePicker
        className={styles["range-picker"]}
        style={{ width: "100%" }}
        showTime={{
          format: "HH:mm",
          defaultValue: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
        }}
        format="YYYY-MM-DD HH:mm"
        value={dates}
        onChange={handleRangeChange}
        onOpenChange={handleRangePickerOpen} // ใช้ฟังก์ชันนี้เพื่อเคลียร์ค่าเมื่อเปิด
      />

      <span className={styles["span-label"]}>หรือเลือกช่วงเวลา</span>

      <Select
        style={{ width: "100%", marginBottom: "10px" }}
        value={selectedRange}
        onChange={handleSelectChange}
      >
        <Option value="null">เลือกช่วงเวลา</Option>
        <Option value="วันนี้">วันนี้</Option>
        <Option value="เมื่อวาน">เมื่อวาน</Option>
        <Option value="สัปดาห์นี้">สัปดาห์นี้</Option>
        <Option value="สัปดาห์ก่อน">สัปดาห์ก่อน</Option>
        <Option value="เดือนนี้">เดือนนี้</Option>
        <Option value="เดือนก่อน">เดือนก่อน</Option>
      </Select>


      <div style={{ marginTop: "0px", textAlign: "center" }}>
        <Button type="primary" onClick={handleButtonClick}>
          ค้าหาข้อมูล
        </Button>
      </div>

      <div style={{ padding: "0px" }}>
      <TripHistory />
    </div>

    </div>
  );
};

export default AlertW2;
