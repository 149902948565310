// src/pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { login } from '../apiCalls';

const LoginPage = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [hoveredImage, setHoveredImage] = useState(null); // เก็บสถานะรูปที่กำลังแสดง
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // ตำแหน่งของเมาส์
    const [deviceId, setDeviceId] = useState(null);
    const [rememberMe, setRememberMe] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const result = await login(username, password, rememberMe, deviceId); 
            if (result.success) {
                onLogin();
                navigate('/');
            } else {
                setErrorMessage(result.message);
            }
        } finally {
            setLoading(false);
        }
    };
    

// ------------------------------------------ แสดงภาพ QR code 
const handleMouseEnter = (imageSrc) => {
    setHoveredImage(imageSrc); // ตั้งค่ารูปภาพที่จะแสดง
};

const handleMouseLeave = () => {
    setHoveredImage(null); // ซ่อนรูปเมื่อเมาส์ออก
};

const handleMouseMove = (event) => {
    setMousePosition({
        x: event.pageX + 0, // เพิ่มระยะห่างจากเมาส์ในแนวนอน
        y: event.pageY - 180, // เพิ่มระยะห่างจากเมาส์ในแนวตั้ง
    });
};
// ------------------------------------------ แสดงภาพ QR code 


// ------------------------------------------ สุ่มสร้าง uuid 
useEffect(() => {
    // ตรวจสอบ UUID ใน Local Storage
    let uuid = localStorage.getItem('device_id');

    if (!uuid) {
        // สร้าง UUID ใหม่ถ้าไม่มีใน Local Storage
        uuid = crypto.randomUUID();
        localStorage.setItem('device_id', uuid);
        console.log('Generated new UUID and stored in Local Storage:', uuid);
    } else {
        console.log('UUID already exists in Local Storage:', uuid);
    }

    setDeviceId(uuid); // อัปเดตค่า UUID ใน state
}, []); // ทำงานครั้งเดียวหลัง Component mount


    return (
        <div className="login-page" onMouseMove={handleMouseMove}>
            <div className="login-card">
                <div className="login-left">
                    <h1 className="login-title">ยินดีต้อนรับ</h1>
                    <p className="login-subtitle">Making Connections Simpler</p>
                    <p className="login-subtitle">ติดต่อ</p>
                    <p className="login-subtitle">010000000000</p>
                </div>
                <div className="login-right">

                    {/* เพิ่มโลโก้ด้านบน input */}
                    <img src="/assets/image/logo.svg" alt="Logo" className="logo" />

                    <form onSubmit={handleLogin} className="login-form">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            
                        </div>
                        
                        {errorMessage && <p className="error">{errorMessage}</p>}

                        <div className="options">
                        <label>
                            <input
                                type="checkbox"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            Remember Me
                        </label>
                            <a href="/forgot-password">Forgot Password?</a>
                        </div>
                        
                        <button type="submit" className="login-button" disabled={loading}>
                            {loading ? (
                                <div className="spinner"></div> // แสดงสปินเนอร์
                            ) : (
                                'Log In'
                            )}
                        </button>
                        
                    </form>
                    
                    <div className="store-links">
                        {/* Google Play */}
                        <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                            <img
                                src="/assets/image/google_playstore.svg"
                                alt="Google Play"
                                className="store-logo"
                                onMouseEnter={() => handleMouseEnter('/assets/image/test_qr.svg')}
                                onMouseLeave={handleMouseLeave}
                            />
                        </a>

                        {/* Apple Store */}
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                            <img
                                src="/assets/image/applestore.svg"
                                alt="Apple Store"
                                className="store-logo"
                                onMouseEnter={() => handleMouseEnter('/assets/image/test_qr.svg')}
                                onMouseLeave={handleMouseLeave}
                            />
                        </a>
                    </div>

                    {/* Tooltip แสดงภาพ */}
                    {hoveredImage && (
                        <div
                            className="tooltip"
                            style={{
                                top: `${mousePosition.y}px`,
                                left: `${mousePosition.x}px`,
                            }}
                        >
                            <img src={hoveredImage} alt="Tooltip Image" className="qr-code" />
                        </div>
                    )}





                    <div className="terms">
                        <a href="/terms">เงื่อนไขการให้บริการ</a> | <a href="/privacy">นโยบายความเป็นส่วนตัว</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
