import React from 'react';

const TaskW4 = () => {
  return (
    <div>
      <h1>Task</h1>
      <p>นี่คือหน้าจัดการงาน</p>
    </div>
  );
};

export default TaskW4;
