// src\components\HistoryW3.js

import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import React, { useState, useEffect, useRef } from "react";
import { Tree, Input, DatePicker, Select, Button } from "antd";
import moment from "moment";
import L from "leaflet";
import { FaMapMarkerAlt } from "react-icons/fa";

import { FaFolder } from "react-icons/fa";
import { BiSolidUpArrow } from "react-icons/bi";

import TripHistory from "./TripHistory";
import PlaybackW from "./PlaybackW_3";

import styles from "../styles/HistoryW3.module.css";

import { getTripRoutes } from "../apiCalls";
import { useMap } from "../context/MapContext";

const { RangePicker } = DatePicker;
const { Option } = Select;

const HistoryW3 = () => {
  const [treeData, setTreeData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const dropdownRef = useRef(null);
  const { map } = useMap();

  // กดปุ่มค้นหา 
  const handleButtonClick = async () => {
    try {
      console.log("Selected Range:", dates);
      console.log("Selected Option:", selectedRange);
  
      const response = await getTripRoutes();
      console.log("Trip Routes Response:", response);
  
      if (response.data && response.data.length > 0) {
        drawRoutesOnMap(map, response.data);
        setIsPlaybackVisible(true);
      } else {
        console.warn("No trip data available to draw");
      }
    } catch (error) {
      console.error("Error fetching trip routes:", error);
    }
  };
  


  // ฟังก์ชันสำหรับวาดเส้นทางลงบนแผนที่
  const drawRoutesOnMap = (map, tripData) => {
    try {
      if (!Array.isArray(tripData)) {
        console.warn("Invalid trip data format");
        return;
      }
  
      const markers = []; // เก็บลูกศรที่สร้างไว้
  
      tripData.forEach((trip) => {
        if (Array.isArray(trip.routes) && trip.routes.length > 0) {
          trip.routes.forEach((route) => {
            if (
              route.route &&
              Array.isArray(route.route) &&
              route.route[0]?.geojson?.type === "LineString" &&
              Array.isArray(route.route[0].geojson.coordinates)
            ) {
              const geojson = route.route[0].geojson;
  
              // วาดเส้นทางบนแผนที่
              const polyline = L.geoJSON(geojson, {
                style: {
                  color: "blue", // สีเส้นทาง
                  weight: 4, // ความหนาเส้นทาง
                },
              }).addTo(map);
  
              // ซูมไปยังเส้นทาง
              map.fitBounds(polyline.getBounds());
  
              // เพิ่มลูกศรเคลื่อนที่
              const coords = geojson.coordinates.map(([lng, lat]) => [lat, lng]);
  
              const arrowMarker = L.marker(coords[0], {
                icon: L.divIcon({
                  className: "arrow-icon",
                  html: `<img 
                           src="/assets/icon/arrow.svg" 
                           style="width: 0px; height: 0px; transform: rotate(0deg);" 
                           alt="arrow"
                         />`,
                  iconSize: [20, 20],
                  iconAnchor: [10, 10],
                }),
              }).addTo(map);              
  
              markers.push({ arrowMarker, coords, currentStep: 0 });
            }
          });
          setMarkers(markers);
        }
      });
  
      return markers;
    } catch (error) {
      console.error("Error drawing routes on map:", error);
    }
  };
  
  
  
  // ดึงข้อมูลจาก localStorage มาสร้าง tree 
  const loadUserData = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      const gpsStatusData = JSON.parse(localStorage.getItem("gps_status")) || {}; // ดึงสถานะ GPS จาก localStorage
  
      const groups = userData.groups.map((group) => {
        const children = (group.vehicles || []).map((vehicle) => {
          const iconPath = `/assets/car_icon_list/${vehicle.icon ? vehicle.icon + ".svg" : "1.svg"}`;
          const vehicleStatus = gpsStatusData[vehicle.id]?.gpsStatus || "offline";
  
          // กำหนดสีพื้นหลังตามสถานะ
          const backgroundColor =
            vehicleStatus === "offline"
              ? "#424242"
              : vehicleStatus === "stop"
              ? "#f00232"
              : vehicleStatus === "idle"
              ? "#bfbf30"
              : vehicleStatus === "run"
              ? "#00bf17"
              : vehicleStatus === "v"
              ? "#0d7ad4"
              : "#424242";
  
          return {
            title: (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {/* พื้นหลังของไอคอน */}
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    transform: 'scaleX(-1)', // พลิกแนวนอน
                  }}
                >
                  <img src={iconPath} alt="vehicle icon" style={{ width: "14px", height: "14px" }} />
                </div>
                <span>
                  {vehicle.license_plate} [ {vehicle.gps_device?.imei || "No IMEI"} ]
                </span>
              </div>
            ),
            key: `vehicle-${vehicle.id}`, // ใช้ key สำหรับการเลือก
            value: `vehicle-${vehicle.id}`,
            className: "child-node",
          };
        });
  
        return {
          title: (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <FaFolder style={{ color: "#2e2e2e", fontSize: "14px" }} />
              <span>{group.name}</span>
            </div>
          ),
          key: `group-${group.id}`,
          value: `group-${group.id}`,
          children: children.length > 0 ? children : undefined,
          className: "parent-node",
        };
      });
  
      setTreeData(groups); // ตั้งค่า treeData
    }
  };


  

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedKeys, { node }) => {
    if (node.key.startsWith("group-")) {
      // เปิด/ปิดกลุ่ม
      setExpandedKeys((prevKeys) =>
        prevKeys.includes(node.key)
          ? prevKeys.filter((key) => key !== node.key) // ปิดกลุ่ม
          : [...prevKeys, node.key] // เปิดกลุ่ม
      );
      return;
    }
  
    // เลือกโหนดย่อย
    setSelectedValue(node.title.props.children[1].props.children[0]); // เลือก vehicle.license_plate
    setIsDropdownVisible(false); // ปิด dropdown
  };


  // dropdown เลือกเวลา

  const [selectedRange, setSelectedRange] = useState(null);
  const [dates, setDates] = useState(null);
  const [clearDates, setClearDates] = useState(false);
  const [clearSelect, setClearSelect] = useState(false);

  const handleRangePickerOpen = (open) => {
    if (open) {
      setDates(null); // เคลียร์ค่า RangePicker
      setSelectedRange(null); // เคลียร์ค่า Select
    }
  };
  
  const handleRangeChange = (value) => {
    setDates(value);
    setSelectedRange(null); // รีเซ็ต Select เมื่อ RangePicker ถูกเปลี่ยน
  };  
  
  const handleSelectChange = (value) => {
    setSelectedRange(value);
  
    // กำหนดค่า RangePicker ตาม Option ที่เลือก
    let newDates = null;
    switch (value) {
      case "วันนี้":
        newDates = [moment().startOf("day"), moment().endOf("day")];
        break;
      case "เมื่อวาน":
        newDates = [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ];
        break;
      case "สัปดาห์นี้":
        newDates = [moment().startOf("week"), moment().endOf("week")];
        break;
      case "สัปดาห์ก่อน":
        newDates = [
          moment().subtract(1, "weeks").startOf("week"),
          moment().subtract(1, "weeks").endOf("week"),
        ];
        break;
      case "เดือนนี้":
        newDates = [moment().startOf("month"), moment().endOf("month")];
        break;
      case "เดือนก่อน":
        newDates = [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ];
        break;
      default:
        newDates = null;
    }
    setDates(newDates); // อัปเดตค่าใน RangePicker
  };
  

  // ------------- ส่วนของหน้าต่าง playback 

  const [isPlaying, setIsPlaying] = useState(false);
  const [markers, setMarkers] = useState([]); 
  const [isPlaybackVisible, setIsPlaybackVisible] = useState(false);
  const isPlayingRef = useRef(true); 

  const handlePlay = () => {
    if (!map || markers.length === 0) return;

    setIsPlaying(true);
    isPlayingRef.current = true;
  
    setMarkers((prevMarkers) =>
      prevMarkers.map((marker) => {
        const { arrowMarker, coords, currentStep } = marker;
        const interpolatedCoords = interpolateCoords(coords, 10); // เพิ่มจุดในเส้นทาง
  
        const animate = () => {
          if (
            !isPlayingRef.current ||
            marker.currentStep >= interpolatedCoords.length - 1
          ) {
            setIsPlaying(false);
            isPlayingRef.current = false;
            return;
          }
  
          const start = interpolatedCoords[marker.currentStep];
          const end = interpolatedCoords[marker.currentStep + 1];
          const bearing = calculateBearing(start, end);
  
        // อัปเดตตำแหน่งและหมุนลูกศร
        const arrowIcon = L.divIcon({
          className: "arrow-icon",
          html: ReactDOMServer.renderToString(
            <div
              style={{
                position: "relative",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 253, 0.99)",
                }}
              ></div>
              <img
                src="/assets/icon/arrow.svg"
                alt="arrow"
                style={{
                  width: "15px",
                  height: "15px",
                  transform: `rotate(${bearing}deg)`,
                }}
              />
            </div>
          ),
          iconSize: [40, 40],
          iconAnchor: [20, 20],
        });

        arrowMarker.setIcon(arrowIcon);
        arrowMarker.setLatLng(start);

        marker.currentStep++; // อัปเดตตำแหน่งปัจจุบัน
        requestAnimationFrame(animate); // เรียกฟังก์ชันตัวเองในเฟรมถัดไป
      };

      animate(); // เริ่มการเคลื่อนที่
      return marker; // คืนค่ากลับเข้าสู่ state
    })
  );
};


  const interpolateCoords = (coords, factor = 10) => {
    const interpolated = [];
    for (let i = 0; i < coords.length - 1; i++) {
      const start = coords[i];
      const end = coords[i + 1];
      interpolated.push(start);
  
      for (let j = 1; j < factor; j++) {
        const lat = start[0] + ((end[0] - start[0]) * j) / factor;
        const lng = start[1] + ((end[1] - start[1]) * j) / factor;
        interpolated.push([lat, lng]);
      }
    }
    interpolated.push(coords[coords.length - 1]);
    return interpolated;
  };
  
  
  const calculateBearing = (start, end) => {
    const [lat1, lng1] = start.map((deg) => (deg * Math.PI) / 180); // แปลงเป็นเรเดียน
    const [lat2, lng2] = end.map((deg) => (deg * Math.PI) / 180); // แปลงเป็นเรเดียน
  
    const deltaLng = lng2 - lng1; // ความแตกต่างของลองจิจูด
    const y = Math.sin(deltaLng) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltaLng);
  
    let bearing = (Math.atan2(y, x) * 180) / Math.PI; // แปลงเป็นองศา
    bearing = (bearing + 360) % 360; // ปรับมุมให้อยู่ในช่วง 0-360 องศา
  
    return bearing; // ส่งคืนมุมที่ปรับแล้ว
  };
  
  
  const handlePause = () => {
    isPlayingRef.current = false; // หยุดสถานะการเล่น
    setIsPlaying(false);
  
    // บันทึก currentStep ปัจจุบันของแต่ละ marker
    setMarkers((prevMarkers) =>
      prevMarkers.map((marker) => {
        return {
          ...marker,
          currentStep: marker.currentStep, // เก็บ currentStep ปัจจุบันไว้
        };
      })
    );
  };
  
  
  const handleStop = () => {
    isPlayingRef.current = false; // หยุดสถานะการเล่น
    setIsPlaying(false);
  
    // รีเซ็ตลูกศรทั้งหมดไปยังจุดเริ่มต้น และ currentStep เป็น 0
    markers.forEach((marker) => {
      marker.arrowMarker.setLatLng(marker.coords[0]); // รีเซ็ตตำแหน่งลูกศร
      marker.currentStep = 0; // รีเซ็ต currentStep
    });
  };

  const handleSpeedChange = (speed) => {
    console.log(`Playback speed changed to: x${speed}`);
    // ใช้ค่า speed ใน logic อื่น เช่น ตั้งค่า delay ใน animation
  };

    // -------------------------------------------------------------------------- html ---------------------------------------- 

  return (
    <div className={styles.bodyW3}>
      
      {isPlaybackVisible &&
        map &&
        ReactDOM.createPortal(
          <PlaybackW 
            onPlay={handlePlay}
            onPause={handlePause}
            onStop={handleStop}
            onSpeedChange={handleSpeedChange}
          />,
          map.getContainer() // เพิ่ม PlaybackW ลงใน DOM ของแผนที่
        )}

      <h3>ประวัติย้อนหลัง</h3>
      <span className={styles["span-label"]}> เลือกยานพาหนะ </span>
      <div style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
        <Input
          placeholder="โปรดเลือกยานพาหนะ"
          value={selectedValue}
          onFocus={() => setIsDropdownVisible(true)}
          readOnly
        />
        {isDropdownVisible && (
          <div
            style={{
              position: "absolute",
              paddingTop: "30px",
              zIndex: 1000,
              width: "100%",
              border: "1px solid #d9d9d9",
              background: "#fff",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
              height: "300px",
              overflow: "auto",
            }}
          >
            <Tree
              className="custom-tree"
              blockNode
              treeData={treeData.map((item) => ({
                ...item,
                style: {
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#2e2e2e",
                },
                children: item.children?.map((child) => ({
                  ...child,
                  style: {
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "10px",
                    color: "#2e2e2e",
                  },
                })),
              }))}
              onSelect={handleSelect}
              onExpand={(keys) => setExpandedKeys(keys)}
              expandedKeys={expandedKeys}
              defaultExpandAll={false}
            />
          </div>
        )}
      </div>

      <span className={styles["span-label"]}>วันที่และเวลา</span>

      <RangePicker
        className={styles["range-picker"]}
        style={{ width: "100%" }}
        showTime={{
          format: "HH:mm",
          defaultValue: [moment("00:00", "HH:mm"), moment("23:59", "HH:mm")],
        }}
        format="YYYY-MM-DD HH:mm"
        value={dates}
        onChange={handleRangeChange}
        onOpenChange={handleRangePickerOpen} // ใช้ฟังก์ชันนี้เพื่อเคลียร์ค่าเมื่อเปิด
      />

      <span className={styles["span-label"]}>หรือเลือกช่วงเวลา</span>

      <Select
        style={{ width: "100%", marginBottom: "10px" }}
        value={selectedRange}
        onChange={handleSelectChange}
      >
        <Option value="null">เลือกช่วงเวลา</Option>
        <Option value="วันนี้">วันนี้</Option>
        <Option value="เมื่อวาน">เมื่อวาน</Option>
        <Option value="สัปดาห์นี้">สัปดาห์นี้</Option>
        <Option value="สัปดาห์ก่อน">สัปดาห์ก่อน</Option>
        <Option value="เดือนนี้">เดือนนี้</Option>
        <Option value="เดือนก่อน">เดือนก่อน</Option>
      </Select>


      <div style={{ marginTop: "0px", textAlign: "center" }}>
        <Button type="primary" onClick={handleButtonClick}>
          ค้าหาข้อมูล
        </Button>
      </div>

      <div style={{ padding: "0px" }}>
      <TripHistory />
    </div>

    </div>
  );
};

export default HistoryW3;
