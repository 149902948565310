// src\components\MapComponent.js
import React, { useCallback, forwardRef, useImperativeHandle, useEffect, useRef, useContext } from 'react';
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom/client";
import { useState } from 'react';
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css';
import UserGuidePopup from "../utils/UserGuidePopup";
import * as turf from '@turf/turf';

import { BsMapFill } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';
import { TbRulerMeasure } from 'react-icons/tb';
import { SlSizeFullscreen } from 'react-icons/sl';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { FaDrawPolygon } from "react-icons/fa6";
import { PiFlagPennantFill } from "react-icons/pi";
import { ImBin2 } from "react-icons/im";
import { RiSearchLine } from "react-icons/ri";
import { MdFilterVintage } from "react-icons/md";
import { MdMyLocation } from "react-icons/md";
import { FaCircle } from "react-icons/fa";

import POIContent from '../components/POIContent';
import GeofenceContent from '../components/GeofenceContent';
import { useMap } from "../context/MapContext";
import '../styles/Map.css';

import LoopContext from "../context/LoopContext";


const calculateRotation = (heading, baseRotation) => {
  const normalizedHeading = (heading + 360) % 360; // Normalize heading to 0–360
  return (normalizedHeading - baseRotation + 360) % 360; // คำนวณการหมุนเทียบกับ baseRotation
};

// ฟังชั่น icon คำนวณทิศทาง
export const getIconForHeading = (heading, iconPrefix = 'car_1_') => {
  if (typeof heading !== 'number' || isNaN(heading)) {
    console.error(`Invalid heading: ${heading}`);
    return { icon: `${iconPrefix}1.svg`, rotation: 0 }; // Default return
  }

  const normalizedHeading = (heading + 360) % 360;

  if (normalizedHeading >= 345 || normalizedHeading < 15) {
    return { icon: `${iconPrefix}1.svg`, rotation: calculateRotation(normalizedHeading, 0) }; // N
  } else if (normalizedHeading >= 15 && normalizedHeading < 75) {
    return { icon: `${iconPrefix}2.svg`, rotation: calculateRotation(normalizedHeading, 45) }; // NE
  } else if (normalizedHeading >= 75 && normalizedHeading < 105) {
    return { icon: `${iconPrefix}3.svg`, rotation: calculateRotation(normalizedHeading, 90) }; // E
  } else if (normalizedHeading >= 105 && normalizedHeading < 165) {
    return { icon: `${iconPrefix}4.svg`, rotation: calculateRotation(normalizedHeading, 135) }; // SE
  } else if (normalizedHeading >= 165 && normalizedHeading < 195) {
    return { icon: `${iconPrefix}5.svg`, rotation: calculateRotation(normalizedHeading, 180) }; // S
  } else if (normalizedHeading >= 195 && normalizedHeading < 255) {
    return { icon: `${iconPrefix}6.svg`, rotation: calculateRotation(normalizedHeading, 225) }; // SW
  } else if (normalizedHeading >= 255 && normalizedHeading < 285) {
    return { icon: `${iconPrefix}7.svg`, rotation: calculateRotation(normalizedHeading, 270) }; // W
  } else if (normalizedHeading >= 285 && normalizedHeading < 345) {
    return { icon: `${iconPrefix}8.svg`, rotation: calculateRotation(normalizedHeading, 315) }; // NW
  }

  // Default case
  return { icon: `${iconPrefix}1.svg`, rotation: calculateRotation(normalizedHeading, 0) };
};




const MapComponent = forwardRef(
  (
    {
      isSidebarOpen,
      toggleSidebar,
      activeButton,
      closeSidebar,
      isBottomSidebarOpen,
      toggleBottomSidebar,
      isCollapsed,
      showToast,
      secondsRemaining,
    },
    ref // รับ ref
  ) => {
  const mapRef = useRef(null);
  const currentLayer = useRef(null);
  
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [isMapMenuOpen, setIsMapMenuOpen] = useState(false);
  const timeoutRef = useRef(null); // ใช้สำหรับจัดการ timeout

  const { setMap } = useMap();

  const counterContainerRef = useRef(null);
  const markersRef = useRef({}); // เก็บ marker ตาม imei

  // ฟังชั่นจับตาดูข้อมูลจาก LoopContext แล้วอัปเดท
  const { registerUpdatePositionCallback } = useContext(LoopContext);
  const [icons, setIcons] = useState({}); // state เก็บ icon ที่สร้างบนแผนที่
  const isCallbackRegistered = useRef(false);


  // เปิดเผยฟังก์ชันให้ Home เรียกใช้
  useImperativeHandle(ref, () => ({
    showIcon: (imei) => {
      setIcons((prevIcons) => {
        const updatedIcons = { ...prevIcons };
        if (updatedIcons[imei]) {
          const { marker, position } = updatedIcons[imei];
          marker.setOpacity(1); // แสดง icon
          mapRef.current.flyTo(position, 15); // ย้ายหน้าจอไปยังตำเหน่ง
        }
        return updatedIcons;
      });
    },
  }));


  // ฟังชั่นระบ callback จาก loopcontext แล้วอัปเดทตำเหน่งในแผนที่
  const updatePositionCallback = useCallback((imei, position, heading) => {
    const vehicleIcons = loadVehicleIcons();
  
    const iconPrefix = vehicleIcons[imei]?.icon === "1"
      ? "car_1_"
      : vehicleIcons[imei]?.icon === "2"
      ? "car_2_"
      : "car_1_";
  
    const { icon, rotation } = getIconForHeading(heading, iconPrefix);
  
    setIcons((prevIcons) => {
      const updatedIcons = { ...prevIcons };
  
      if (updatedIcons[imei]) {
        const { marker, tooltip } = updatedIcons[imei];
  
        // อัปเดต Icon
        const currentIconHtml = marker.options.icon.options.html;
        const newIconHtml = `<img src="assets/car_icon_map/${icon}" style="transform: rotate(${rotation}deg);" />`;
  
        if (currentIconHtml !== newIconHtml) {
          const customIcon = L.divIcon({
            html: newIconHtml,
            className: "custom-car-icon",
            iconSize: [40, 40],
            iconAnchor: [20, 20],
          });
          marker.setIcon(customIcon);
        }
  
        // อัปเดตตำแหน่ง Marker
        marker.setLatLng(position);
  
        // อัปเดตตำแหน่ง Tooltip
        if (tooltip) {
          tooltip.setLatLng(position);
        }
  
        updatedIcons[imei].position = position;
      }
  
      return updatedIcons;
    });
  }, []);



  const handleMouseEnterIcon = () => {
    clearTimeout(timeoutRef.current); // ยกเลิกการปิดเมนูถ้ามีการหน่วงเวลา
    setIsMapMenuOpen(true); // เปิดเมนูเมื่อชี้ที่ไอคอน
  };

  const handleMouseLeaveIcon = () => {
    timeoutRef.current = setTimeout(() => {
      setIsMapMenuOpen(false); // ปิดเมนูหลังจากเวลาหน่วง
    }, 100); // หน่วงเวลา 100ms
  };

  const handleMouseEnterMenu = () => {
    clearTimeout(timeoutRef.current); // ยกเลิกการปิดเมนูเมื่อชี้เมนู
    setIsMapMenuOpen(true); // คงเมนูไว้
  };

  const handleMouseLeaveMenu = () => {
    timeoutRef.current = setTimeout(() => {
      setIsMapMenuOpen(false); // ปิดเมนูหลังจากเวลาหน่วง
    }, 100); // หน่วงเวลา 100ms
  };

  

  const mapLayers = useRef({
    osm: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }),
    cartoPositron: L.tileLayer(
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
      {
        attribution: '&copy; CartoDB, OpenStreetMap contributors',
      }
    ),
    openTopoMap: L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenTopoMap, OpenStreetMap contributors',
    }),
    googleStreet: L.gridLayer.googleMutant({
      type: 'roadmap',
      attribution: '© Google',
    }),
    googleSatellite: L.gridLayer.googleMutant({
      type: 'satellite',
      attribution: '© Google',
    }),
    googleHybrid: L.gridLayer.googleMutant({
      type: 'hybrid',
      attribution: '© Google',
    }),
    googleTerrain: L.gridLayer.googleMutant({
      type: 'terrain',
      attribution: '© Google',
    }),
    mapboxStreet: L.tileLayer(
      `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      {
        attribution: '&copy; Mapbox',
        tileSize: 512,
        zoomOffset: -1,
      }
    ),
    mapboxSatellite: L.tileLayer(
      `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      {
        attribution: '&copy; Mapbox',
        tileSize: 512,
        zoomOffset: -1,
      }
    ),
    mapboxTerrain: L.tileLayer(
      `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      {
        attribution: '&copy; Mapbox',
        tileSize: 512,
        zoomOffset: -1,
      }
    ),
  });
  

  useEffect(() => {
    if (!mapRef.current) {
      // สร้างแผนที่
      mapRef.current = L.map("map", {
        center: [13.736717, 100.523186],
        zoom: 12,
        layers: [mapLayers.current.osm],
        zoomControl: false, // ปิด zoom control
      });
      currentLayer.current = mapLayers.current.osm;

      setMap(mapRef.current);
      
      // เพิ่มปุ่มตำแหน่งปัจจุบัน (มุมขวาล่าง)
      const LocateControl = L.Control.extend({
        options: {
          position: "bottomright", // ตำแหน่งมุมขวาล่าง
        },
        onAdd: function () {
          const container = L.DomUtil.create("div", "locate-control");
          container.style.backgroundColor = "white";
          container.style.border = "1px solid #ccc";
          container.style.borderRadius = "50%";
          container.style.width = "30px";
          container.style.height = "30px";
          container.style.display = "flex";
          container.style.justifyContent = "center";
          container.style.alignItems = "center";
          container.style.cursor = "pointer";

       // ใช้ React Icon กับ ReactDOM.createRoot
       const root = ReactDOM.createRoot(container);
       root.render(<MdMyLocation size={20} color="#007BFF" />);

       let currentMarker = null; // ตัวแปรสำหรับเก็บ Marker ที่แสดงตำแหน่งของคุณ

       // เพิ่ม Event เมื่อคลิกปุ่ม
       container.addEventListener("click", () => {
         if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(
             (position) => {
               const { latitude, longitude } = position.coords;
       
               // ย้ายแผนที่ไปยังตำแหน่งปัจจุบัน
               mapRef.current.setView([latitude, longitude], 15);
       
               // หากมี Marker อยู่แล้ว ให้ลบออกจากแผนที่
               if (currentMarker) {
                 mapRef.current.removeLayer(currentMarker);
               }
       
               // สร้าง DivIcon ที่ใช้ React Icon
               const customIcon = L.divIcon({
                 html: ReactDOMServer.renderToString(
                   <FaCircle size={16} color="#00aaff" />
                 ),
                 className: "custom-icon-map", // เพิ่มคลาสเพื่อปรับแต่งเพิ่มเติม
                 iconSize: [12, 12],
                 iconAnchor: [6, 6],
               });
       
               // สร้าง Marker ใหม่
               currentMarker = L.marker([latitude, longitude], { icon: customIcon })
                 .addTo(mapRef.current);

               // เพิ่ม Tooltip
               currentMarker.bindTooltip("ตำแหน่งปัจจุบันของคุณ", {
                 direction: "top", // แสดงข้อความด้านบน
                 offset: L.point(0, -10), // เลื่อน Tooltip ขึ้น
                 className: "custom_tooltip", // ใช้ CSS สำหรับการปรับแต่งเพิ่มเติม
               });
       
              },

                (error) => {
                  console.error("Error fetching location:", error);
                  showToast("ไม่สามารถดึงตำแหน่งของคุณได้", "error");
                }
              );
            } else {
              alert("เบราว์เซอร์ของคุณไม่รองรับ Geolocation");
            }
          });

          return container;
        },
      });


      
      // คอนโทรลกล่องค้นหา (มุมซ้ายบน)
      const SearchControl = L.Control.extend({
        options: {
          position: "topleft", // ตำแหน่งมุมซ้ายบน
        },
        onAdd: function () {
          const container = L.DomUtil.create("div", "search-container");
          container.style.position = "relative";
          container.style.backgroundColor = "white";
          container.style.border = "1px solid #ccc";
          container.style.borderRadius = "5px";
          container.style.width = "250px";
          container.style.height = "30px";

          // สร้าง input
          const input = L.DomUtil.create("input", "search-input", container);
          input.type = "text";
          input.placeholder = "กรุณากรอกที่อยู่";
          input.style.width = "100%";
          input.style.height = "100%";
          input.style.border = "none";
          input.style.borderRadius = "5px";
          input.style.padding = "0px 10px";
          input.style.fontSize = "12px";
          input.style.outline = "none";
          input.style.color = "#8c8c8c";
          input.style.boxSizing = "border-box";

          // สร้าง div สำหรับไอคอนในตำแหน่งขวาสุด
          const iconContainer = L.DomUtil.create("div", "icon-container", container);
          iconContainer.style.position = "absolute";
          iconContainer.style.top = "60%";
          iconContainer.style.right = "10px";
          iconContainer.style.transform = "translateY(-50%)";
          iconContainer.style.fontSize = "20px";
          iconContainer.style.color = "#009c1a";
          iconContainer.style.cursor = "pointer";

          // เรนเดอร์ RiSearchLine ด้วย ReactDOM.createRoot
          const root = ReactDOM.createRoot(iconContainer);
          root.render(<RiSearchLine />);

          // ป้องกันไม่ให้การคลิกหรือพิมพ์ใน input มีผลกับแผนที่
          L.DomEvent.disableClickPropagation(container);

          // เพิ่ม Event เมื่อคลิกที่ไอคอน
          iconContainer.addEventListener("click", () => {
            const query = input.value.trim();
            if (query) {
              alert(`ค้นหา: ${query}`); // ตัวอย่าง action
            }
          });

          return container;
        },
      });
  
      // คอนโทรลกล่อง Zoom Level (มุมซ้ายล่าง)
      const ZoomLevelControl = L.Control.extend({
        options: {
          position: 'bottomleft', // ตำแหน่งมุมซ้ายล่าง
        },
        onAdd: function (map) {
          const container = L.DomUtil.create('div', 'zoom-level-container');
          container.style.backgroundColor = 'white';
          container.style.padding = '0px 5px';
          container.style.borderRadius = '5px';
          container.style.fontSize = '12px';
          container.style.color = "#8c8c8c";
  
          // แสดงระดับการซูมปัจจุบัน
          container.innerHTML = `ZL: ${map.getZoom()}`;
  
          // อัปเดตระดับการซูมเมื่อเปลี่ยน
          map.on('zoomend', () => {
            container.innerHTML = `ZL: ${map.getZoom()}`;
          });
  
          return container;
        },
      });
  
      // คอนโทรลกล่อง "15" (มุมซ้ายล่าง)
      const CounterControl = L.Control.extend({
        options: {
          position: 'bottomleft', // ตำแหน่งมุมซ้ายล่าง
        },
        onAdd: function () {
          const container = L.DomUtil.create('div', 'counter-container');
          container.style.backgroundColor = 'white';
          container.style.padding = '2px 6px';
          container.style.borderRadius = '5px';
          container.style.marginTop = '5px';
          container.style.color = "#8c8c8c";
          container.innerHTML = '<strong>{secondsRemaining}</strong>';
          counterContainerRef.current = container;
          return container;
        },
      });
      
  
      // เพิ่มคอนโทรลทั้งสามลงในแผนที่
      mapRef.current.addControl(new SearchControl()); // มุมซ้ายบน
      mapRef.current.addControl(new ZoomLevelControl()); // มุมซ้ายล่าง (Zoom Level ก่อน)
      mapRef.current.addControl(new CounterControl()); // มุมซ้ายล่าง (กล่อง "15" หลัง)
      mapRef.current.addControl(new LocateControl());


      // ------------------------------- สร้าง icon

      const vehicleIcons = loadVehicleIcons();

      const gpsData = loadGpsData();
      
      const newIcons = {};
      gpsData.forEach(({ imei, g_point, heading, license_plate }) => {
        const [lat, lng] = g_point.split(",").map(Number);
        const position = [lat, lng];
      
        // กำหนด prefix ไอคอนจากข้อมูลใน vehicles
        const iconPrefix = vehicleIcons[imei]?.icon === "1" 
          ? "car_1_" 
          : vehicleIcons[imei]?.icon === "2" 
          ? "car_2_" 
          : "car_1_"; // Default to car_1_
      
        const { icon, rotation } = getIconForHeading(heading, iconPrefix);
      
        // สร้าง Icon
        const customIcon = L.divIcon({
          html: `<img src="assets/car_icon_map/${icon}" style="transform: rotate(${rotation}deg);" />`,
          className: "custom-car-icon",
          iconSize: [40, 40],
          iconAnchor: [20, 20],
        });
      
        // สร้าง Marker
        const marker = L.marker(position, {
          title: `IMEI: ${imei}`,
          icon: customIcon,
        }).addTo(mapRef.current);
      
        // เพิ่ม Tooltip
        const tooltipContent = `${license_plate}`;
        const tooltip = L.tooltip({
          direction: "top",
          offset: L.point(0, -20),
          className: "custom_tooltip",
          permanent: true,
        }).setContent(tooltipContent);
      
        // ผูก Tooltip กับ Marker
        marker.bindTooltip(tooltip);
      
        // เก็บข้อมูลใน icons
        newIcons[imei] = {
          marker,
          position,
          license_plate,
          tooltip,
        };
      });
      
      setIcons(newIcons); // อัปเดต State icons      

      // ------------------------------- จบการสร้าง icon

    
    // ลงทะเบียน callback สำหรับอัปเดตตำแหน่ง
    if (!isCallbackRegistered.current && registerUpdatePositionCallback) {
      console.log("Registering updatePositionCallback from MapComponent...");
      registerUpdatePositionCallback(updatePositionCallback); // ส่ง callback
      isCallbackRegistered.current = true;
    } else if (!registerUpdatePositionCallback) {
      console.warn("registerUpdatePositionCallback is not available");
    }    


    }

  }, [registerUpdatePositionCallback, updatePositionCallback , setMap, showToast]);
  



  // ดึงข้อมูลจาก localStorage
  const loadGpsData = () => {
    const gpsData = JSON.parse(localStorage.getItem("gps_data")) || [];
    const userData = JSON.parse(localStorage.getItem("user")) || {};
  
    // ดึงข้อมูล vehicles จาก userData.groups.vehicles
    const vehicles = userData.groups?.reduce((acc, group) => {
      if (group.vehicles) {
        acc.push(...group.vehicles);
      }
      return acc;
    }, []) || [];
  
    return gpsData.map((data) => {
      // หา license_plate โดยจับคู่ IMEI กับ vehicles
      const matchedVehicle = vehicles.find(
        (vehicle) => vehicle.gps_device && vehicle.gps_device.imei === data.imei
      );
  
      return {
        imei: data.imei,
        g_point: data.redisData.g_point, // ตำแหน่งจาก g_point
        heading: parseFloat(data.redisData.heading) || 0, // แปลง heading เป็น number และกำหนดค่าเริ่มต้นเป็น 0
        license_plate: matchedVehicle ? matchedVehicle.license_plate : null, // ดึงทะเบียนรถ (ถ้าเจอ)
      };
    });
  };

  // ฟังชั่นดึงข้อมูล icon 
  const loadVehicleIcons = () => {
    const userData = localStorage.getItem("user");
    if (!userData) return {};
  
    try {
      const parsedData = JSON.parse(userData);
      return parsedData.vehicles || {}; // คืนค่า vehicles หรือเป็น {} ถ้าไม่มีข้อมูล
    } catch (error) {
      console.error("Error parsing user data:", error);
      return {};
    }
  };
  
  


  const togglePopup = (imei, shouldShow) => {
    setIcons((prevIcons) => {
      const updatedIcons = { ...prevIcons };
  
      if (updatedIcons[imei]) {
        const { marker, popup } = updatedIcons[imei];
        updatedIcons[imei].isPopupVisible = shouldShow;
  
        if (shouldShow) {
          popup.addTo(mapRef.current); // แสดง Popup บนแผนที่
        } else {
          popup.remove(); // ซ่อน Popup
        }
      }
  
      return updatedIcons;
    });
  };  
  


  // ฟังก์ชันปรับขนาดแผนที่เมื่อ Sidebar เปลี่ยนสถานะ
  useEffect(() => {
    if (mapRef.current) {
      // console.log('Sidebar state changed. Adjusting map size...');
      setTimeout(() => {
        mapRef.current.invalidateSize(); // ปรับขนาดแผนที่
      }, 400); // รอให้ Transition ของ Sidebar เสร็จสมบูรณ์

      if (counterContainerRef.current) {
        counterContainerRef.current.innerHTML = `<strong>${secondsRemaining}</strong>`;
      }
      
    }
  }, [isCollapsed , secondsRemaining]); // ฟังการเปลี่ยนแปลงของ isCollapsed


  
  const changeMapLayer = (layerKey) => {
    if (mapRef.current && currentLayer.current !== mapLayers.current[layerKey]) {
      if (currentLayer.current) {
        mapRef.current.removeLayer(currentLayer.current);
      }
  
      // ตรวจสอบว่าเลเยอร์ที่เลือกเป็น Google Maps หรือไม่
      if (['googleStreet', 'googleSatellite', 'googleHybrid', 'googleTerrain'].includes(layerKey)) {
        loadGoogleMapsScript(); // โหลด Google Maps API ถ้ายังไม่มีการโหลด
      }
  
      mapLayers.current[layerKey].addTo(mapRef.current);
      currentLayer.current = mapLayers.current[layerKey];
    }
  };
  


    // ฟังก์ชันสำหรับโหลด Google Maps API Script ครั้งเดียว
    const loadGoogleMapsScript = () => {
        if (!window.google) { // โหลดเฉพาะเมื่อยังไม่มีการโหลด Google API
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        script.async = true;
        script.defer = true;
    
        // เพิ่ม event listener เพื่อตรวจสอบว่า script โหลดสำเร็จแล้ว
        script.onload = () => {
            console.log("Google Maps API has been loaded.");
        };
    
        document.head.appendChild(script);
        } else {
        console.log("Google Maps API is already loaded.");
        }
    };

  


// สถานะในการแสดงปุ่ม
const [showClearButton, setShowClearButton] = useState(false);
const [distancePoints, setDistancePoints] = useState([]);
const [distanceLines, setDistanceLines] = useState([]);
const [polygonPoints, setPolygonPoints] = useState([]);
const [polygonArea, setPolygonArea] = useState(null);
const [polygonCentroid, setPolygonCentroid] = useState(null);
const [distanceMarkers, setDistanceMarkers] = useState([]);  // เก็บ markers ทั้งหมด
const [tempPolygon, setTempPolygon] = useState(null);  // เก็บ Polygon ชั่วคราว
const [tempPolygonLayer, setTempPolygonLayer] = useState(null);  // เก็บ Layer ใน state
const [currentPolygons, setCurrentPolygons] = useState([]);
const [areaLabels, setAreaLabels] = useState([]);
const [markers, setMarkers] = useState([]); // เก็บรายการหมุดจาก POIContent

// ฟังก์ชันที่ตรวจสอบว่ามีข้อมูลใน measurementData หรือไม่
const checkMeasurementData = () => {
  console.log('Checking measurement data:');
  // ตรวจสอบและ log ค่าแต่ละตัวแปร
  // console.log('distancePoints:', distancePoints);
  // console.log('distanceLines:', distanceLines);
  // console.log('polygonPoints:', polygonPoints);
  // console.log('polygonArea:', polygonArea);
  // console.log('polygonCentroid:', polygonCentroid);
  // console.log('distanceMarkers:', distanceMarkers);
  // console.log('tempPolygon:', tempPolygon);
  // console.log('currentPolygon:', currentPolygons);
  console.log("Markers:", markers);

  // ตรวจสอบว่ามีข้อมูลในแต่ละตัวแปรหรือไม่
  if (
    distancePoints.length > 0 || 
    distanceLines.length > 0 ||   
    polygonPoints.length > 0 ||  
    polygonArea ||                
    polygonCentroid ||            
    distanceMarkers.length > 0 || 
    tempPolygon !== null ||
    markers.length > 0
  ) {
    setShowClearButton(true);  // หากมีข้อมูล, แสดงปุ่มเคลียร์
  } else {
    setShowClearButton(false); // หากไม่มีข้อมูล, ซ่อนปุ่มเคลียร์
  }

};

// ฟังก์ชันเคลียร์ข้อมูลทั้งหมด
const clearMeasurementData = () => {
  // เคลียร์ข้อมูลทั้งหมดใน state
  setDistancePoints([]);
  setDistanceLines([]);
  setPolygonPoints([]);
  setPolygonArea(null);
  setPolygonCentroid(null);
  setDistanceMarkers([]);
  setTempPolygon(null);
  setTempPolygonLayer(null);
  setShowClearButton(false);

  // เคลียร์ข้อมูลที่แสดงบนแผนที่
  if (mapRef.current) {
    // ลบ markers ทั้งหมด
    distanceMarkers.forEach(marker => mapRef.current.removeLayer(marker));

    // ลบเส้นทั้งหมด
    distanceLines.forEach(line => mapRef.current.removeLayer(line));

    // ลบ Polygon ชั่วคราว (พื้นที่สีฟ้า)
    if (tempPolygonLayer) {
      mapRef.current.removeLayer(tempPolygonLayer);
    }

    // ลบ Polygon ที่ถูกวาดเสร็จ
    if (polygonPoints.length > 0) {
      const polygon = L.polygon(polygonPoints, { color: 'blue' }).addTo(mapRef.current);
      setPolygonPoints([]); // รีเซ็ตข้อมูล Polygon
      setPolygonArea(null);  // รีเซ็ตข้อมูลพื้นที่
      setPolygonCentroid(null); // รีเซ็ตข้อมูลศูนย์กลาง
      mapRef.current.removeLayer(polygon); // ลบ polygon ที่ถูกวาด
    }

    // ลบหมุดทั้งหมด
    markers.forEach((marker) => {
      mapRef.current.removeLayer(marker); // ลบแต่ละหมุดออกจากแผนที่
    });
    setMarkers([]); // รีเซ็ตสถานะของ markers

  }

  deletePolygons();
  clearAreaLabels();
  
  console.log('Measurement data cleared');
};


// ฟังก์ชันแสดง popup
const handleShowPopup = (message) => {
  setPopupMessage(message); // อัปเดตข้อความ
  setShowPopup(true); // แสดง Popup
};

const handleClosePopup = () => {
  setShowPopup(false); // ปิด Popup
};

useEffect(() => {
  checkMeasurementData();  // เรียกตรวจสอบเมื่อ state มีการเปลี่ยนแปลง
}, [markers, areaLabels, currentPolygons, distancePoints, distanceLines, polygonPoints, polygonArea, polygonCentroid, distanceMarkers, tempPolygon]);  // เช็คทุกครั้งที่ state เปลี่ยนแปลง


// ------------------------- ฟังก์ชันวัดระยะ
const startDistanceMeasurement = () => {
  let isMeasuring = true;
  let points = [];
  let tempLine = null;
  handleShowPopup("โหลดวัดระยะทางทำงาน คลิกซ้ายบนแผนที่เพื่อเริ่มวาดระยะห่าง คลิ๊กขวาเพื่อออกจากโหมด");

  const onMapClick = (e) => {
    if (!isMeasuring) return;

    const clickedLatLng = e.latlng;
    points.push(clickedLatLng);

    // อัปเดตข้อมูล state ทันทีเมื่อคลิก
    setDistancePoints((prevPoints) => [...prevPoints, clickedLatLng]);

    // สร้าง circleMarker
    const circleMarker = L.circleMarker(clickedLatLng, {
      radius: 4,
      color: 'red',
      fillColor: 'red',
      fillOpacity: 0.8,
    }).addTo(mapRef.current);
    setDistanceMarkers((prevMarkers) => [...prevMarkers, circleMarker]);

    // วาดเส้น
    if (points.length > 1) {
      const lastPoint = points[points.length - 2];
      const segmentDistance = mapRef.current.distance(lastPoint, clickedLatLng);

      const line = L.polyline([lastPoint, clickedLatLng], { color: '#00d712b8' }).addTo(mapRef.current);
      setDistanceLines((prevLines) => [...prevLines, line]);

      const midPoint = L.latLng(
        (lastPoint.lat + clickedLatLng.lat) / 2,
        (lastPoint.lng + clickedLatLng.lng) / 2
      );

      const marker = L.marker(midPoint, {
        icon: L.divIcon({
          className: 'distance-label',
          html: `<div style="background: rgba(255, 255, 255, 0.7); padding: 4px 4px; border: 1px solid #ccc; border-radius: 5px; font-size: 10px; font-weight: bold; display: inline-block; white-space: nowrap;">${segmentDistance.toFixed(2)} m</div>`,
        }),
      }).addTo(mapRef.current);

      setDistanceMarkers((prevMarkers) => [...prevMarkers, marker]);
    }
  };

  const onMouseMove = (e) => {
    if (!isMeasuring || points.length === 0) return;

    const lastPoint = points[points.length - 1];
    const currentMouseLatLng = e.latlng;

    if (tempLine) {
      mapRef.current.removeLayer(tempLine);
    }

    tempLine = L.polyline([lastPoint, currentMouseLatLng], { color: 'gray', dashArray: '5, 10' });
    tempLine.addTo(mapRef.current);
  };

  mapRef.current.on('click', onMapClick);
  mapRef.current.on('mousemove', onMouseMove);
  mapRef.current.on('contextmenu', () => {
    isMeasuring = false;
    mapRef.current.off('click', onMapClick);
    mapRef.current.off('mousemove', onMouseMove);
    handleClosePopup();
  
    // ลบ tempLine
    if (tempLine) {
      mapRef.current.removeLayer(tempLine);
      tempLine = null; // รีเซ็ตค่า tempLine ให้เป็น null
    }
  });  
};


// ------------------------- ฟังก์ชันวาดพื้นที่
const startPolygonDrawing = () => {
  let isDrawing = true;
  let polygonPoints = [];
  let tempLineLayerFirst = null;
  let tempLineLayerLast = null;
  let tempPolygonLayer = null;

  handleShowPopup("โหลดวัดขนาดพื้นที่ทำงาน คลิกซ้ายบนแผนที่เพื่อเริ่มวาด คลิ๊กขวาเพื่อออกจากโหมด");

  const onMapClick = (e) => {
    if (!isDrawing) return;

    const clickedLatLng = e.latlng;
    polygonPoints.push([clickedLatLng.lat, clickedLatLng.lng]);

    setPolygonPoints((prevPoints) => [...prevPoints, [clickedLatLng.lat, clickedLatLng.lng]]);

    const circleMarker = L.circleMarker(clickedLatLng, {
      radius: 4,
      color: 'red',
      fillColor: 'blue',
      fillOpacity: 0.8,
    }).addTo(mapRef.current);
    setDistanceMarkers((prevMarkers) => [...prevMarkers, circleMarker]);

    // ลบ tempPolygonLayer ถ้ามีอยู่
    if (tempPolygonLayer) {
      mapRef.current.removeLayer(tempPolygonLayer);
    }

    // วาด Polygon ชั่วคราว
    tempPolygonLayer = L.polygon(polygonPoints, { color: 'blue', fillOpacity: 0.3 }).addTo(mapRef.current);
    setTempPolygonLayer(tempPolygonLayer); // อัปเดต tempPolygonLayer ใน state
  };

  const onMouseMove = (e) => {
    if (!isDrawing || polygonPoints.length === 0) return;

    const currentMouseLatLng = e.latlng;

    if (tempLineLayerFirst) {
      mapRef.current.removeLayer(tempLineLayerFirst);
    }
    if (tempLineLayerLast) {
      mapRef.current.removeLayer(tempLineLayerLast);
    }

    if (polygonPoints.length > 1) {
      const firstPoint = polygonPoints[0];
      tempLineLayerFirst = L.polyline([firstPoint, currentMouseLatLng], { color: 'gray', dashArray: '5,10' }).addTo(mapRef.current);
    }

    const lastPoint = polygonPoints[polygonPoints.length - 1];
    tempLineLayerLast = L.polyline([lastPoint, currentMouseLatLng], { color: 'gray', dashArray: '5,10' }).addTo(mapRef.current);
  };

  const onRightClick = () => {
    // ถ้ามีข้อมูลใน polygonPoints ให้ทำการวาด
    if (polygonPoints.length > 0) {
      // สร้าง polygon ก่อนที่จะอัปเดต state
      const polygon = L.polygon(polygonPoints, { color: 'blue' }).addTo(mapRef.current);

      // คำนวณพื้นที่จาก polygon และเก็บใน state polygonArea
      const area = turf.area(polygon.toGeoJSON());

      // แสดงพื้นที่เป็น ตร.ม.
      const areaInSquareMeters = area.toFixed(2); // ใช้ toFixed() เพื่อจำกัดทศนิยมเป็น 2 ตำแหน่ง

      setPolygonArea(areaInSquareMeters);  // เก็บพื้นที่ใน state

      // หาตำแหน่งกึ่งกลางของ polygon
      const center = turf.centerOfMass(polygon.toGeoJSON()).geometry.coordinates;
      const centerLatLng = L.latLng(center[1], center[0]);

      // สร้าง marker ที่ตำแหน่งกึ่งกลางของ polygon
      const marker = L.marker(centerLatLng, {
        icon: L.divIcon({
          className: 'area-label',
          html: `<div style="background: rgba(255, 255, 255, 0.7); padding: 4px 4px; border: 1px solid #ccc; border-radius: 5px; font-size: 12px; font-weight: bold; display: inline-block; white-space: nowrap;">${areaInSquareMeters} ตร.ม.</div>`,
        }),
      }).addTo(mapRef.current);

      // เก็บ marker ลงใน areaLabels state
      setAreaLabels((prevLabels) => [...prevLabels, marker]);

      // อัพเดต currentPolygons ใน state (เก็บ polygons ทั้งหมด)
      setCurrentPolygons((prevPolygons) => [...prevPolygons, polygon]);
    }

    // ลบ temporary layers
    if (tempLineLayerFirst) {
      mapRef.current.removeLayer(tempLineLayerFirst);
    }
    if (tempLineLayerLast) {
      mapRef.current.removeLayer(tempLineLayerLast);
    }

    // ลบ tempPolygonLayer
    if (tempPolygonLayer) {
      mapRef.current.removeLayer(tempPolygonLayer);
    }

    // ลบการผูก event หลังจากออกจากโหมด
    mapRef.current.off('click', onMapClick);
    mapRef.current.off('mousemove', onMouseMove);
    mapRef.current.off('contextmenu', onRightClick);

    // ออกจากโหมดวาด
    isDrawing = false;  // ตั้งค่ากลับเป็น false เพื่อไม่ให้วาดต่อ
    handleClosePopup();
  };

  // ผูก event
  mapRef.current.on('click', onMapClick);
  mapRef.current.on('mousemove', onMouseMove);
  mapRef.current.on('contextmenu', onRightClick);
};




// ฟังก์ชันลบ polygons ทั้งหมดที่เก็บใน currentPolygons
const deletePolygons = () => {
  if (mapRef.current) {
    currentPolygons.forEach((polygon) => {
      mapRef.current.removeLayer(polygon);
    });
  }
  setCurrentPolygons([]); // รีเซ็ตค่า array ของ polygons

  console.log('All polygons deleted');
};

// ฟังก์ชันสำหรับลบ area-label ทั้งหมด
const clearAreaLabels = () => {
  areaLabels.forEach(marker => {
    mapRef.current.removeLayer(marker);
  });
  setAreaLabels([]); // รีเซ็ต state array
};

// ------------------------------ วาดพื้นที่จบตรงนี้ 



// ------------------------------ ฟังชั่นตรวจสอบและแสดง poi

const [checkedKeys, setCheckedKeys] = useState([]); // เก็บ POI ที่ถูกเลือก
const [mapZoomLevel, setMapZoomLevel] = useState(13); // ระดับการ zoom ของแผนที่
const hiddenMarkers = useRef([]); // เก็บข้อมูล marker ที่ถูกสร้าง


useEffect(() => {
  if (mapRef.current) {
    const handleZoom = () => {
      const currentZoom = mapRef.current.getZoom();
      setMapZoomLevel(currentZoom);
    };

    mapRef.current.on("zoomend", handleZoom); // ฟังเหตุการณ์ zoomend

    // ทำความสะอาด listener เมื่อ component unmount
    return () => {
      mapRef.current.off("zoomend", handleZoom);
    };
  }
}, [mapRef]);


useEffect(() => {
  hiddenMarkers.current.forEach((entry) => {
    const isChecked = checkedKeys.some((key) => {
      const keyParts = key.split('-');
      const keyPoiId = keyParts[keyParts.length - 1];
      return keyPoiId === entry.poiId.toString();
    });

    const canShow = isChecked && mapZoomLevel >= entry.zl_show;

    if (canShow) {
      if (!mapRef.current.hasLayer(entry.marker)) {
        entry.marker.addTo(mapRef.current);
      }
    } else {
      if (mapRef.current.hasLayer(entry.marker)) {
        removeMarkerWithFade(entry.marker); // ใช้ fade-out ก่อนลบ
      }
    }
  });
}, [checkedKeys, mapZoomLevel]);



const removeMarkerWithFade = (marker) => {
  const iconElement = marker.getElement(); // ดึง DOM element ของ marker

  if (iconElement) {
    // เพิ่ม class สำหรับ fade-out
    iconElement.classList.add("fade-out");

    // ลบ marker หลัง animation เสร็จ (1 วินาที)
    setTimeout(() => {
      if (mapRef.current.hasLayer(marker)) {
        mapRef.current.removeLayer(marker);
      }
    }, 1000); // 1000ms = 1s
  }
};



/// วาด POI บนแผนที่
const drawPoisOnMap = () => {
  // hiddenMarkers.current = []; 
  // ดึงข้อมูลจาก localStorage
  const storedGroups = localStorage.getItem("poi");
  if (!storedGroups) {
    console.error("No POI data found in localStorage.");
    return;
  }

  const parsedGroups = JSON.parse(storedGroups);
  const groups = parsedGroups.data || [];

  groups.forEach((group) => {
    group.pois?.forEach((poi) => {
      if (poi.boundary) {
        const [lat, lng] = decodeBoundary(poi.boundary);
        if (lat && lng) {
          const icon = L.divIcon({
            className: 'myCustomIcon',
            html: `
              <div style="
                background-color: ${poi.color || "#000"};
                border-radius: 50%;
                width: ${poi.size || 10}px;
                height: ${poi.size || 10}px;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
                <img src="${poi.icon}" alt="${poi.name}" style="width: ${poi.size * 0.6 || 6}px; height: ${poi.size * 0.6 || 6}px;" />
              </div>
            `,
            iconSize: [poi.size || 10, poi.size || 10],
            iconAnchor: [poi.size / 2 || 5, poi.size / 2 || 5],
          });      

          // วางหมุดบนแผนที่
          const marker = L.marker([lat, lng], { icon });

          // เพิ่ม Tooltip
          marker.bindTooltip(poi.name, {
            permanent: false, // ซ่อน Tooltip โดยค่าเริ่มต้น
            direction: "top", // แสดงข้อความด้านบน
            offset: L.point(0, -10), // เลื่อน Tooltip ขึ้น
            className: 'custom_tooltip', // ใช้ CSS สำหรับการปรับแต่ง
          });

          // แสดง Tooltip เมื่อเมาส์วางบน Marker
          marker.on("mouseover", function () {
            this.openTooltip(); // แสดง Tooltip
          });

          // ซ่อน Tooltip เมื่อเมาส์ออกจาก Marker
          marker.on("mouseout", function () {
            this.closeTooltip(); // ซ่อน Tooltip
          });

          // เก็บ Marker ใน reference สำหรับจัดการภายหลัง
          hiddenMarkers.current.push({
            poiId: poi.id,
            marker,
            zl_show: poi.zl_show, // เก็บ zl_show ไว้สำหรับการตรวจสอบ
          });

        } else {
          console.error(`Invalid Lat/Lng for POI: ${poi.name}`);
        }
      } else {
        console.error(`No boundary data for POI: ${poi.name}`);
      }
    });
  });
};


// ตัวช่วยสำหรับแปลง boundary (ถ้า boundary เป็น WKT หรือ GeoJSON)
const decodeBoundary = (boundary) => {
  if (boundary.startsWith("POINT")) {
    const match = boundary.match(/POINT\(([^ ]+) ([^ ]+)\)/);
    if (match) {
      console.log(`Decoded Boundary: Lat: ${match[2]}, Lng: ${match[1]}`);
      return [parseFloat(match[2]), parseFloat(match[1])];
    }
  }
  console.error(`Invalid boundary format: ${boundary}`);
  return [null, null];
};



  // ฟังก์ชันสำหรับซูมเข้า
  const handleZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  // ฟังก์ชันสำหรับซูมออก
  const handleZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };


// -------------------------------------------------------------- html ---------------------------------------------------

  return (
    <div className="map-container">

    <div>
      {showPopup && (
        <UserGuidePopup message={popupMessage} onClose={handleClosePopup} />
      )}
    </div>

      <div id="map" className="map"></div>
      
      <div className="map-controls">


      <div
        className="control-icon"
        onMouseEnter={handleMouseEnterIcon}
        onMouseLeave={handleMouseLeaveIcon}
      >
        <BsMapFill />
        {isMapMenuOpen && (
          <div
            className="map-menu"
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
          >


            {/* เนื้อหาเมนู */}
            <label>
              <input
                type="radio"
                name="map"
                value="osm"
                onChange={() => changeMapLayer('osm')}
                defaultChecked
              />
              OpenStreetMap
            </label>

            <label>
              <input
                type="radio"
                name="map"
                value="cartoPositron"
                onChange={() => changeMapLayer('cartoPositron')}
              />
              CartoDB Positron
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="openTopoMap"
                onChange={() => changeMapLayer('openTopoMap')}
              />
              OpenTopoMap
            </label>

            <label>
              <input
                type="radio"
                name="map"
                value="googleStreet"
                onChange={() => changeMapLayer('googleStreet')}
              />
              Google Street
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="googleSatellite"
                onChange={() => changeMapLayer('googleSatellite')}
              />
              Google Satellite
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="googleHybrid"
                onChange={() => changeMapLayer('googleHybrid')}
              />
              Google Hybrid
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="googleTerrain"
                onChange={() => changeMapLayer('googleTerrain')}
              />
              Google Terrain
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="mapboxStreet"
                onChange={() => changeMapLayer('mapboxStreet')}
              />
              Mapbox Street
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="mapboxSatellite"
                onChange={() => changeMapLayer('mapboxSatellite')}
              />
              Mapbox Satellite
            </label>
            <label>
              <input
                type="radio"
                name="map"
                value="mapboxTerrain"
                onChange={() => changeMapLayer('mapboxTerrain')}
              />
              Mapbox Terrain
            </label>


    </div>
  )}
</div>



        <button className="control-icon" onClick={() => toggleSidebar('POI')}>
          <PiFlagPennantFill />
        </button>
        <button className="control-icon" onClick={() => toggleSidebar('Geofence')}>
          <BiMapPin />
        </button>
        
        <button
          className="control-icon"
          onClick={startDistanceMeasurement}
        >
          <TbRulerMeasure />
        </button>

        <button className="control-icon" onClick={startPolygonDrawing}>
          <FaDrawPolygon />
        </button>

        <button className="control-icon" onClick={toggleBottomSidebar}>
          <MdFilterVintage />
        </button>
        <button className="control-icon" onClick={handleZoomIn}>
          <FiZoomIn />
        </button>
        <button className="control-icon" onClick={handleZoomOut}>
          <FiZoomOut />
        </button>
        {/* ปุ่มสำหรับเคลียร์ข้อมูล */}
        {showClearButton && (
          <button className="control-icon" onClick={clearMeasurementData}>
            <ImBin2 />
          </button>
        )}
        {/* แสดงแผนที่ หรือ คอมโพเนนต์อื่นๆ */}
      </div>




      {/* Sidebar Content */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>

        {activeButton === 'POI' && <POIContent
        setMarkers={setMarkers} // ส่งฟังก์ชัน setMarkers เป็น prop
        markers={markers} // ส่งรายการ markers ไปให้ POIContent
        onClose={closeSidebar} 
        showToast={showToast} 
        mapRef={mapRef}
        handleShowPopup={handleShowPopup}
        handleClosePopup={handleClosePopup}
        clearMeasurementData={clearMeasurementData}

        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        hiddenMarkers={hiddenMarkers}
        drawPoisOnMap={drawPoisOnMap}

        />}

        {activeButton === 'Geofence' && <GeofenceContent 
        onClose={closeSidebar}
        showToast={showToast} 
        mapRef={mapRef}
        handleShowPopup={handleShowPopup}
        handleClosePopup={handleClosePopup}
        />}

        {activeButton === 'Geofence' && <vehicleDetailsContent
        onClose={closeSidebar}
        showToast={showToast} 
        handleShowPopup={handleShowPopup}
        handleClosePopup={handleClosePopup}
        />}

      </div>

      {/* Bottom Sidebar */}
      <div className={`bottom-sidebar ${isBottomSidebarOpen ? 'open' : ''}`}>
        <div>
          <h3>Plane Measurement</h3>
          <p>เนื้อหาสำหรับการวัดระยะ</p>
          <button onClick={toggleBottomSidebar}>ปิด</button>
        </div>
      </div>
    </div>
  );

}

);

export default MapComponent;
