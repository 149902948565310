import React from 'react';

const VehicleW3 = () => {
  return (
    <div>
      <h1>VehicleW3</h1>
      <p>นี่คือหน้าจัดการงาน</p>
    </div>
  );
};

export default VehicleW3;
