
// src/api.js 
import axios from 'axios';
import config from './config';

const api = axios.create({
    baseURL: config.API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-APP-API-KEY': config.APP_API_KEY,
    },
});

export default api;
