import React, { useState, useEffect } from "react";
import "../styles/UserGuidePopup.css";

const UserGuidePopup = ({ message, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true); // แสดง Popup เมื่อ mount
  }, []);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose(); // เรียก callback หากมี
  };

  return (
    <div className={`user-guide-popup ${visible ? "show" : ""}`}>
      <div className="popup-message">{message}</div>
      <button className="popup-close" onClick={handleClose}>
        ✖
      </button>
    </div>
  );
};

export default UserGuidePopup;
