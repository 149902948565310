// src/components/PortalMenu.js

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const PortalMenu = ({ children, onClose }) => {
  const portalRoot = document.body; // ใช้ body เป็น root
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose(); // เรียกฟังก์ชันปิดเมนู
      }
    };

    // เพิ่ม event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // ลบ event listener เมื่อ component ถูก unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div ref={menuRef}>{children}</div>, // อ้างอิงเมนูด้วย menuRef
    portalRoot
  );
};

export default PortalMenu;
