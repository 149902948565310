import React from 'react';

const DriverW2 = () => {
  return (
    <div>
      <h1>DriverW2</h1>
      <p>นี่คือหน้าจัดการงาน</p>
    </div>
  );
};

export default DriverW2;
