import React from "react";
import '../styles/DashboardW1.css';

const DashboardW1 = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Scrollable Content */}
      <main
        style={{
          flex: 1, // เต็มความสูงที่เหลือ
          overflowY: "auto", // เปิด Scroll เฉพาะส่วนนี้
          padding: "20px",
          backgroundColor: "#f8f9fc",
        }}
      >


      {/* Row 1 */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)", // การ์ด 5 ใบ ขนาดเท่ากัน
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {/* Card 1 */}
        <div style={cardStyle}>
          <h3 style={cardTitle}>จำนวนคนขับทั้งหมด</h3>
          <p style={cardNumber}>2</p>
          <p style={cardSubtitle}>Updated to 2024-11-25</p>
        </div>

        {/* Card 2 */}
        <div style={cardStyle}>
          <h3 style={cardTitle}>จำนวนยานพาหนะทั้งหมด</h3>
          <p style={cardNumber}>2</p>
        </div>

        {/* Card 3 */}
        <div style={cardStyle}>
          <h3 style={cardTitle}>ระยะทางรวม (กม.)</h3>
          <p style={cardNumber}>0.00</p>
          <p style={cardSubtitle}>This week</p>
        </div>

        {/* Card 4 */}
        <div style={cardStyle}>
          <h3 style={cardTitle}>เวลาใช้งานรวม (ชม.)</h3>
          <p style={cardNumber}>0</p>
          <p style={cardSubtitle}>This week</p>
        </div>

        {/* Card 5 */}
        <div style={cardStyle}>
          <h3 style={cardTitle}>การใช้เชื้อเพลิงทั้งหมด (ลิตร)</h3>
          <p style={cardNumber}>0.00</p>
          <p style={cardSubtitle}>This week</p>
        </div>
      </div>






         {/* Row 2 */}
         <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: "20px",
            marginTop: "40px", // เพิ่มระยะห่างด้านบน
          }}
      >
        {/* Reminder Section */}
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>Reminder</h3>
          <div style={{ marginTop: "10px" }}>
            <button style={reminderButton}>Driving License Reminder</button>
            <button style={reminderButton}>Insurance Reminder</button>
          </div>
          {/* Chart */}
          <div
            style={{
              height: "150px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Placeholder for Pie Chart */}
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                background: "conic-gradient(#007bff 33%, #6c757d 33% 66%, #e9ecef 66%)",
              }}
            ></div>
          </div>
          {/* Legend */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10px",
              fontSize: "14px",
            }}
          >
            <span style={{ color: "#007bff" }}>Normal</span>
            <span style={{ color: "#6c757d" }}>Expired</span>
            <span style={{ color: "#e9ecef" }}>Expiring soon</span>
          </div>
        </div>

        {/* Motion Statistics Section */}
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
            Motion Statistics (H)
          </h3>
          <div style={{ marginTop: "10px" }}>
            <button style={reminderButton}>Exercise Duration</button>
            <button style={reminderButton}>Idling Duration</button>
            <button style={reminderButton}>Parked Duration</button>
          </div>
          {/* Chart */}
          <div
            style={{
              height: "200px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Placeholder for Bar Chart */}
            <div
              style={{
                width: "80%",
                height: "150px",
                backgroundColor: "#e9ecef",
                borderRadius: "10px",
              }}
            >
              {/* Example Bar */}
              <div
                style={{
                  height: "80%",
                  width: "10%",
                  backgroundColor: "#007bff",
                  margin: "10px auto",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>



      {/* Row 3 */}
      <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // แบ่ง 2 คอลัมน์ขนาดเท่ากัน
            gap: "20px",
            marginTop: "50px",
            marginBottom: "50px",
          }}
      >
        {/* Fuel Consumption Statistics */}
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
            Fuel Consumption Statistics (L)
          </h3>
          <div style={{ marginTop: "10px" }}>
            <button style={statButton}>Total Fuel</button>
            <button style={statButton}>Fuel / 100km</button>
          </div>
          {/* Chart Placeholder */}
          <div
            style={{
              height: "200px",
              marginTop: "20px",
              backgroundColor: "#e9ecef",
              borderRadius: "10px",
            }}
          >
            {/* สามารถเพิ่มกราฟจริงได้ภายหลัง */}
          </div>
        </div>

        {/* Mileage Statistics */}
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
            Mileage Statistics (km)
          </h3>
          <div style={{ marginTop: "10px" }}>
            <button style={statButton}>Total Mileage</button>
            <button style={statButton}>Average Daily Mileage</button>
          </div>
          {/* Chart Placeholder */}
          <div
            style={{
              height: "200px",
              marginTop: "20px",
              backgroundColor: "#e9ecef",
              borderRadius: "10px",
            }}
          >
            {/* สามารถเพิ่มกราฟจริงได้ภายหลัง */}
          </div>
        </div>
      </div>





      </main>

{/* Footer */}
<footer
  style={{
    height: "40px",
    backgroundColor: "#6c757d",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <p>Footer</p>
</footer>
</div>
);
};

// Reusable Styles
const cardStyle = {
  backgroundColor: "#ffffff",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  textAlign: "center", // จัดข้อความให้อยู่ตรงกลาง
};

// Reminder Button Style
const reminderButton = {
  marginRight: "10px",
  padding: "10px 15px",
  backgroundColor: "#e7f1ff",
  color: "#007bff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

// Button Style
const statButton = {
  marginRight: "10px",
  padding: "10px 15px",
  backgroundColor: "#e7f1ff",
  color: "#007bff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const cardTitle = { fontSize: "16px", color: "#6c757d", marginBottom: "10px" };
const cardNumber = { fontSize: "24px", fontWeight: "bold", color: "#000" };
const cardSubtitle = { fontSize: "12px", color: "#6c757d", marginTop: "10px" };

export default DashboardW1;
