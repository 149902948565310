// src\pages\fleet.js

import React, { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import '../styles/fleet.css';
import { MdInsertChartOutlined } from "react-icons/md";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FaCarSide } from "react-icons/fa";
import { FaUsersLine } from "react-icons/fa6";



// นำเข้าคอนเทนต์จากไฟล์ต่างๆ
import Dashboard from '../components/DashboardW1';
import Driver from '../components/DriverW2';
import Vehicle from '../components/VehicleW3';

const Fleet = () => {
  const [activeTab, setActiveTab] = useState('dashboard'); // ควบคุมแท็บที่เลือก

  // ฟังก์ชันสำหรับเรนเดอร์เนื้อหา
  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <Dashboard />;
      case 'driver':
        return <Driver />;
      case 'vehicle':
        return <Vehicle />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="full-screen-table">
      <table className="full-screen-table">
        <tbody>
          <tr>
            {/* เมนูด้านซ้าย */}
            <td>
              <div className="card card-container">
                <div
                  className={`inner-card ${activeTab === 'dashboard' ? 'active' : ''}`}
                  onClick={() => setActiveTab('dashboard')}
                >
                  <MdOutlineDashboardCustomize size={25} /> Dash
                </div>
                <div
                  className={`inner-card ${activeTab === 'driver' ? 'active' : ''}`}
                  onClick={() => setActiveTab('driver')}
                >
                  <FaUsersLine size={25} /> driver
                </div>
                <div
                  className={`inner-card ${activeTab === 'vehicle' ? 'active' : ''}`}
                  onClick={() => setActiveTab('vehicle')}
                >
                  <FaCarSide size={25} /> vehicle
                </div>
              </div>
            </td>

            {/* พื้นที่แสดงเนื้อหา */}
            <td>
              <div className="content">
                <div className="content-wrapper">
                  {renderContent()} {/* แสดงเนื้อหาตามแท็บที่เลือก */}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Fleet;
