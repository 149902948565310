// src/components/TripHistory.js
import React, { useState } from "react";
import { Button } from "antd";
import { EnvironmentOutlined, CalendarOutlined } from "@ant-design/icons";

import styles from "../styles/TripHistory.module.css";

const TripHistory = () => {
    const [isExpanded, setIsExpanded] = useState(false); // State สำหรับการขยาย
    const [selectedCards, setSelectedCards] = useState([]); // เลือกการ์ด
  
    const toggleExpand = (e) => {
        e.stopPropagation();
        if (isAllSelected()) {
            setSelectedCards([]);
          }
        setIsExpanded((prevIsExpanded) => !prevIsExpanded); 
      };
      
    // ฟังก์ชันเลือกการ์ดใหญ่
    const handleMainCardClick = () => {
        // ป้องกันไม่ให้เลือกการ์ดใหญ่เมื่ออยู่ในสถานะขยาย
        if (isExpanded) {
          console.log("ไม่สามารถเลือกการ์ดใหญ่ได้เมื่ออยู่ในสถานะขยาย");
          return;
        }
      
        // ล้างการเลือกถ้าการ์ดใหญ่ถูกเลือกอยู่ทั้งหมด
        if (isAllSelected()) {
          setSelectedCards([]); // ล้างการเลือกทั้งหมด
        } else {
          // เลือกการ์ดย่อยทั้งหมดถ้าไม่มีการเลือก
          const allCardIds = cards.map((card) => card.id);
          setSelectedCards(allCardIds);
        }
      };
      
    
  
    // ฟังก์ชันเลือกการ์ดย่อย
    const handleSubCardClick = (e, id) => {
      e.stopPropagation(); // ป้องกัน event ส่งไปที่การ์ดใหญ่
      setSelectedCards((prev) =>
        prev.includes(id)
          ? prev.filter((cardId) => cardId !== id) // ถ้าเลือกแล้ว ให้ลบออก
          : [...prev, id] // ถ้ายังไม่เลือก ให้เพิ่มเข้าไป
      );
    };
  
    // เช็คว่าทั้งหมดถูกเลือกหรือยัง
    const isAllSelected = () => {
      return cards.every((card) => selectedCards.includes(card.id));
    };
  
    // Mock data สำหรับการ์ด
    const cards = [
      { id: 1, title: "การ์ดที่ 1", date: "2024-11-01 00:07:49" },
      { id: 2, title: "การ์ดที่ 2", date: "2024-11-25 23:59:36" },
    ];
  
    return (
      <div
        className={`${styles.card} ${isAllSelected() ? styles.selectedCard : ""}`}
        onClick={handleMainCardClick}
      >
        {/* ส่วนที่ 1: สรุป */}
        <div className={styles.summary}>
          <div className={styles.summaryText}>
            <span>2973.42 km</span> <span className={styles.divider}>|</span>{" "}
            <span>6.60 km/h</span>
          </div>
          <Button type="link" className={styles.detailsButton}>
            Details
          </Button>
        </div>
  
        <div className={styles.line}></div>
  
        <div className={styles.tripPoint}>
          <div className={styles.pointDetails}>
            {/* ไอคอน + วันที่ */}
            <div className={styles.dateRow}>
              <EnvironmentOutlined
                className={`${styles.iconStyle} ${styles.green}`}
              />
              <p className={styles.date}>2024-11-01 00:07:49</p>
            </div>
            {/* ตำแหน่ง */}
            <p className={styles.location}>
              ประชาราษฎร์ สาย 2 แขวงบางซื่อ เขตบางซื่อ กรุงเทพมหานคร
            </p>
          </div>
        </div>
  
        <div className={styles.totalTrips}>
          <span className={styles.spantotalTrips}>อีกประมาณ 109 รอบ</span>
          <Button type="link" className={styles.expandButton} onClick={toggleExpand}>
            {isExpanded ? "ซ่อน" : "แสดง"} {/* เปลี่ยนข้อความ */}
          </Button>
        </div>
  
        {/* เนื้อหาที่ขยาย/ยุบ */}
        {isExpanded && (
          <div className={styles.expandedContent}>
            <ul>
              {cards.map((card) => (
                <div
                  key={card.id}
                  className={`${styles.card2} ${
                    selectedCards.includes(card.id) ? styles.selectedCard : ""
                  }`}
                  onClick={(e) => handleSubCardClick(e, card.id)}
                >
                  <div className={styles.summary}>
                    <div className={styles.summaryText}>
                      <span className={styles.titlespan}> 100 กม.</span>
                      <span className={styles.divider}>|</span>
                      <span className={styles.titlespan}>6.60 km/h</span>
                    </div>
                  </div>
                  <div className={styles.line}></div>
  
                  <div className={styles.tripPoint}>
                    <div className={styles.pointDetails}>
                      <div className={styles.dateRow}>
                        <EnvironmentOutlined
                          className={`${styles.iconStyle} ${styles.green}`}
                        />
                        <p className={styles.date}>{card.date}</p>
                      </div>
                      <p className={styles.location2}>
                        ประชาราษฎร์ สาย 2 แขวงบางซื่อ เขตบางซื่อ กรุงเทพมหานคร
                      </p>
                    </div>
                  </div>
  
                  <div className={styles.tripPoint}>
                    <div className={styles.pointDetails}>
                      <div className={styles.dateRow}>
                        <EnvironmentOutlined
                          className={`${styles.iconStyle} ${styles.red}`}
                        />
                        <p className={styles.date}>{card.date}</p>
                      </div>
                      <p className={styles.location2}>
                        ประชาราษฎร์ สาย 2 แขวงบางซื่อ เขตบางซื่อ กรุงเทพมหานคร
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <Button type="link" className={styles.expandButton}>
                โหลดเพิ่ม
              </Button>
            </ul>
          </div>
        )}
  
        <div className={styles.tripPoint}>
          <div className={styles.pointDetails}>
            <div className={styles.dateRow}>
              <EnvironmentOutlined className={`${styles.iconStyle} ${styles.red}`} />
              <p className={styles.date}>2024-11-25 23:59:36</p>
            </div>
            <p className={styles.location}>
              ประชาราษฎร์ สาย 2 แขวงบางซื่อ เขตบางซื่อ กรุงเทพมหานคร
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default TripHistory;
  