// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiRoadMapFill } from "react-icons/ri";
import { HiDocumentReport } from "react-icons/hi";
import { AiFillSetting } from "react-icons/ai";
import { MdCarRepair } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { logout } from '../apiCalls';

const Navbar = ({ changeLanguage, onLogout }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState('th  ');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // ตรวจสอบก่อนว่ามีข้อมูลใน localStorage หรือไม่
    const storedUser = localStorage.getItem('user');

  if (storedUser) {
          try {
              const user = JSON.parse(storedUser);
              setUsername(user.username); // ตั้งค่า username ใน state
          } catch (error) {
              setUsername(''); // ตั้งค่าเป็นค่าว่างในกรณีที่มีข้อผิดพลาด
          }
      }
}, []);





  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    changeLanguage(lang);
    setIsOpen(false);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };


  const handleLogout = () => {
    logout();
    onLogout();
    navigate('/login');
};


  return (
    <nav style={styles.navbar}>
      <img src="/logo.png" alt="Logo" style={styles.logo} />
      <ul style={styles.navList}>

      {/* Custom Dropdown สำหรับเลือกภาษา */}
      <div style={styles.languageDropdown} onClick={() => setIsOpen(!isOpen)}>
            <img
                src={selectedLang === 'en' ? '/assets/flags/flag-usa.png' : '/assets/flags/flag-thailand.png'}
                alt="Selected Flag"
                style={styles.flagIcon}
            />
            {isOpen && (
                <div style={styles.dropdownMenu}>
                    <div onClick={() => handleLanguageChange('en')} style={styles.dropdownItem}>
                        <img src="/assets/flags/flag-usa.png" alt="USA Flag" style={styles.flagIcon} /> English
                    </div>
                    <div onClick={() => handleLanguageChange('th')} style={styles.dropdownItem}>
                        <img src="/assets/flags/flag-thailand.png" alt="Thailand Flag" style={styles.flagIcon} /> ไทย
                    </div>
                </div>
            )}
        </div>









      
        <li style={styles.navItem}>
          <NavLink
            to="/"
            style={({ isActive }) => (isActive ? styles.activeLink : styles.navLink)}
          >
            <div style={styles.linkContent}>
              <RiRoadMapFill size={30} style={{ marginRight: '8px' }} />
              {t('tracking')} {/* ใช้ t เพื่อแปลข้อความ */}
            </div>
          </NavLink>
        </li>

        <li style={styles.navItem}>
          <NavLink
            to="/report"
            style={({ isActive }) => (isActive ? styles.activeLink : styles.navLink)}
          >
            <div style={styles.linkContent}>
              <HiDocumentReport size={30} style={{ marginRight: '8px' }} />
              {t('report')} {/* ใช้ t เพื่อแปลข้อความ */}
            </div>
          </NavLink>
        </li>

        <li style={styles.navItem}>
          <NavLink
            to="/management"
            style={({ isActive }) => (isActive ? styles.activeLink : styles.navLink)}
          >
            <div style={styles.linkContent}>
              <AiFillSetting size={30} style={{ marginRight: '8px' }} />
              {t('management')} {/* ใช้ t เพื่อแปลข้อความ */}
            </div>
          </NavLink>
        </li>

        <li style={styles.navItem}>
          <NavLink
            to="/fleet"
            style={({ isActive }) => (isActive ? styles.activeLink : styles.navLink)}
          >
            <div style={styles.linkContent}>
              <MdCarRepair size={30} style={{ marginRight: '8px' }} />
              {t('maintenance')} {/* ใช้ t เพื่อแปลข้อความ */}
            </div>
          </NavLink>
        </li>

      </ul>

            {/* ปุ่ม User พร้อม Dropdown */}
            <div style={styles.userMenu} onClick={toggleUserMenu}>
              <FaUserCircle size={30} style={styles.userIcon} />
              {username && <span style={{ marginLeft: '8px', color: '#fff' }}>{username}</span>} {/* แสดงชื่อผู้ใช้ */}
              {isUserMenuOpen && (
                <div style={styles.userDropdown}>
                  <div style={styles.dropdownItem}>Settings</div>
                  <div style={styles.dropdownItem} onClick={handleLogout}>Logout</div>
                </div>
              )}
            </div>

    </nav>
  );
};


const styles = {
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    backgroundColor: '#0c9e1b',
    position: 'sticky',
    top: '0',
    left: '0',
    width: '100%',
    height: '50px',
    zIndex: 1000,
  },
  logo: {
    height: '40px',
    marginRight: '1rem',
  },
  navList: {
    display: 'flex',
    listStyleType: 'none',
    margin: '0',
    padding: '0',
    flex: 1,
  },
  navItem: {
    margin: '0 1rem',
  },
  navLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecoration: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
  },
  activeLink: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: '#ffdd57',
    backgroundColor: '#0b8c18',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
  },
  languageDropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  flagIcon: {
    width: '20px',
    height: '15px',
    marginRight: '8px',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  userIcon: {
    color: '#fff',
  },
  userDropdown: {
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    width: '120px',
  },
};


export default Navbar;
