// src\components\GeofenceContent.js
import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import { Tree, TreeSelect } from 'antd';
import "antd/dist/reset.css";

import { CiEdit } from "react-icons/ci";
import { FaMapMarkerAlt } from "react-icons/fa";

import { IoClose } from "react-icons/io5";
import styles from '../styles/Geofence.module.css';
import { RiDragMove2Line } from "react-icons/ri";
import { FaTrashCan } from "react-icons/fa6";
import { BsCircle, BsPentagon } from "react-icons/bs";

import { createGeoFenceGroup,geofenceCreate, getAllGe0fDetails,
   deleteGeoFenceGroup, moveGeoFenceGroup, updateGeoFGroup, updateGeoFence } from "../apiCalls";

const GeofenceContent = ({ onClose, showToast,mapRef, handleShowPopup, handleClosePopup, zoomToLocation }) => {
const [mode, setMode] = useState('list'); // 'list', 'new', 'edit'
const [selectedShape, setSelectedShape] = useState('');

const [geoFenceGroups, setGeoFenceGroups] = useState([]); // เก็บข้อมูลกลุ่ม
const [selectedGroup, setSelectedGroup] = useState(""); // เก็บกลุ่มที่เลือก
const [treeData, setTreeData] = useState([]); // ข้อมูลสำหรับ Tree

const [gName, setGName] = useState(""); // ย่อจาก newGeoG_name
const [gDesc, setGDesc] = useState(""); // ย่อจาก newGeoG_description
const [isLoading, setisLoading] = useState(false); // สำหรับแสดงสถานะโหลด
const [error, setError] = useState(""); // สำหรับแสดงข้อความข้อผิดพลาด

const [geoBoundary, setGeoBoundary] = useState({ lat: null, lng: null });
const [geoRadius, setGeoRadius] = useState(0);
const [circleColor, setCircleColor] = useState('#ff0000'); // ค่าเริ่มต้นคือสีแดง

const [selectedGeoFenceId, setSelectedGeoFenceId] = useState(null); // State สำหรับเก็บ geo_fence_id ตอนกด tree
const [hasFetchedGeoFences, setHasFetchedGeoFences] = useState(false); // ตัวแปรสถานะเช็คการดึงข้อมูล

const [checkedKeys, setCheckedKeys] = useState([]); // ตัวแปรเก็บค่าการติ๊กถูกใน tree

const [isMoving, setIsMoving] = useState(false); // ควบคุมการแสดง dropdown
const [targetGroup, setTargetGroup] = useState(''); // เก็บค่ากลุ่มเป้าหมาย

// State สำหรับการควบคุม Checkbox
const [updateVehicles, setUpdateVehicles] = useState(false);
const [updateSpeed, setUpdateSpeed] = useState(false);
const [updateNotifications, setUpdateNotifications] = useState(false);


// ฟังก์ชันแยกสำหรับดึงข้อมูลจาก localStorage
const loadGeoFenceGroups = () => {
  try {
    const storedGroups = localStorage.getItem("geoFenceGroups");
    if (storedGroups) {
      const groups = JSON.parse(storedGroups);
      if (Array.isArray(groups)) {
        setGeoFenceGroups(groups);

        // เลือกกลุ่มแรกอัตโนมัติ
        if (groups.length > 0) {
          setSelectedGroup(groups[0].id);
        }
      }
    }
  } catch (error) {
    console.error("Error parsing geoFenceGroups from localStorage:", error);
    showToast(error, "error");
  }
};

// useEffect ที่เรียกฟังก์ชัน
useEffect(() => {
  loadGeoFenceGroups();
}, []);




  // ฟังก์ชันเปลี่ยนกลุ่มที่ถูกเลือก
  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };


  // ฟังชั่นสร้างกลุ่ม 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);

    const user = JSON.parse(localStorage.getItem("user"));

    if (!user || !user.id) {
        console.error("User not found in localStorage. Current value:", localStorage.getItem("user"));
        setisLoading(false);
        showToast("User authentication failed. Please login again.", "error");
        return;
    }

    const data = {
        name: gName,
        description: gDesc,
        user_id: user.id, // เพิ่ม user_id ที่ได้จาก localStorage
    };

    const response = await createGeoFenceGroup(data);
    setisLoading(false);

    if (response.success) {
        showToast("กลุ่มถูกสร้างสำเร็จ!", "success");
        setGName("");
        setGDesc("");
        setMode("list");
        fetchGeoFenceDetailsAgain();
    } else {
        showToast(response.message, "error");
    }
};

// ฟังชั่นวาดวงกลมลงไป สำหรับ Geo-f

const [drawingMode, setDrawingMode] = useState('preparing'); // ค่าเริ่มต้นเป็น 'preparing'
const drawingModeRef = useRef('preparing'); // ใช้ useRef สำหรับสถานะโหมดวาด
const circleRef = useRef(null); // ใช้ useRef เก็บวงกลมที่ถูกสร้าง
const centerMarkerRef = useRef(null); // ใช้ useRef เก็บ Center Marker
const edgeMarkerRef = useRef(null); // ใช้ useRef เก็บ Edge Marker
const polygonRef = useRef(null); // เก็บ Polygon ที่กำลังวาด
const markersRef = useRef([]); // เก็บ Array ของ Marker
const intermediateMarkersRef = useRef([]); // เก็บจุดกึ่งกลาง

useEffect(() => {
  resetDrawingMode(); // รีเซ็ตโหมดก่อนเริ่มโหมดใหม่
  if (selectedShape === 'circular') {
    startDrawingResizableCircle();
  } else if (selectedShape === 'polygon') {
    startDrawingPolygon();
  }
}, [selectedShape]);



const resetDrawingMode = () => {
  // ลบอีเวนต์ของโหมดวงกลม
  if (drawingModeRef.current === 'preparing' || drawingModeRef.current === 'editing') {
    if (circleRef.current) {
      mapRef.current.removeLayer(circleRef.current);
      circleRef.current = null;
    }
    if (centerMarkerRef.current) {
      mapRef.current.removeLayer(centerMarkerRef.current);
      centerMarkerRef.current = null;
    }
    if (edgeMarkerRef.current) {
      mapRef.current.removeLayer(edgeMarkerRef.current);
      edgeMarkerRef.current = null;
    }
  }

  // ลบอีเวนต์ของโหมด polygon
  if (polygonRef.current) {
    mapRef.current.removeLayer(polygonRef.current);
    polygonRef.current = null;
  }

  // ลบหมุดสีขาวทั้งหมด
  if (markersRef.current.length > 0) {
    markersRef.current.forEach((marker) => {
      if (marker) {
        mapRef.current.removeLayer(marker); // ลบ Marker จากแผนที่
      }
    });
    markersRef.current = []; // รีเซ็ต Array หมุด
  }

  // ลบหมุดเงา
  if (intermediateMarkersRef.current.length > 0) {
    intermediateMarkersRef.current.forEach((marker) => {
      if (marker) {
        mapRef.current.removeLayer(marker); // ลบ Marker จากแผนที่
      }
    });
    intermediateMarkersRef.current = []; // รีเซ็ต Array หมุด
  }

  // ยกเลิกอีเวนต์ทั้งหมดในแผนที่
  mapRef.current.off('click');
  mapRef.current.off('contextmenu');

  // รีเซ็ตโหมด
  setDrawingModeWithRef(null);
};





// ฟังก์ชันสำหรับเปลี่ยนสีวงกลม
const handleColorChange = (color) => {
  setCircleColor(color); // อัปเดตสีใน state

  // อัปเดตสีของวงกลม
  if (circleRef.current) {
    circleRef.current.setStyle({
      color: color,
      fillColor: color,
    });
  }

  // อัปเดตสีของรูปหลายเหลี่ยม
  if (polygonRef.current) {
    polygonRef.current.setStyle({
      color: color,
      fillColor: color,
    });
  }
};


// ใช้ useEffect สำหรับอัปเดตสีวงกลม
useEffect(() => {
  console.log("circleColor updated:", circleColor, "Mode (state):", drawingMode, "Mode (ref):", drawingModeRef.current); // Debug log
  if (drawingModeRef.current === "editing" && circleRef.current) {
    circleRef.current.setStyle({
      color: circleColor,
      fillColor: circleColor,
    });
    console.log("Circle style updated to color:", circleColor); // ยืนยันว่าฟังก์ชันทำงาน
  }
}, [circleColor]);

const setDrawingModeWithRef = (mode) => {
  setDrawingMode(mode); // อัปเดต state
  drawingModeRef.current = mode; // อัปเดตค่าใน useRef
  console.log("Drawing mode set to:", mode, "(state & ref)"); // Debug log
};

const startDrawingResizableCircle = () => {
  if (!mapRef.current) return;

  // ตั้งค่าเริ่มต้นสำหรับโหมดเตรียมการ
  setDrawingModeWithRef('preparing'); // เปลี่ยนโหมดเป็น preparing
  handleShowPopup("โหลดสร้างวงกลม: คลิกซ้ายบนแผนที่เพื่อเริ่มวาง คลิกขวาเพื่อยกเลิกโหมด");

  // ฟังก์ชันคำนวณรัศมีตามระดับ Zoom
  const calculateInitialRadius = (zoom) => {
    if (zoom >= 15) return 500 / Math.pow(2, zoom - 14); // ลดรัศมีเมื่อ zoom มากกว่า 14
    if (zoom <= 13) return 500 * Math.pow(2, 14 - zoom); // เพิ่มรัศมีเมื่อ zoom น้อยกว่า 14
    return 500; // ใช้รัศมี 500 หน่วยสำหรับ zoom 14
  };

  // ฟังก์ชันยกเลิกการวาดวงกลม
  const cancelDrawing = () => {
    console.log("Cancel drawing called. Current mode (state):", drawingMode, "Current mode (ref):", drawingModeRef.current);

    if (drawingModeRef.current === 'preparing') {
      console.log("Exiting preparing mode.");
      mapRef.current.off('click', onMapClick); // ปิด Event click
      mapRef.current.off('contextmenu', cancelDrawing); // ปิด Event ยกเลิก
      handleClosePopup();
      setDrawingModeWithRef(null); // รีเซ็ตโหมด
    } else if (drawingModeRef.current === 'editing') {
      console.log("Exiting editing mode.");
      if (centerMarkerRef.current) {
        centerMarkerRef.current.off(); // ปิด Event ของ Center Marker
        centerMarkerRef.current.dragging.disable(); // ปิดการลาก
      }
      if (edgeMarkerRef.current) {
        edgeMarkerRef.current.off(); // ปิด Event ของ Edge Marker
        edgeMarkerRef.current.dragging.disable(); // ปิดการลาก
      }
      mapRef.current.off('contextmenu', cancelDrawing); // ปิด Event ยกเลิก
      handleClosePopup();
      setDrawingModeWithRef(null); // รีเซ็ตโหมด
    }
  };

  // ฟังก์ชันที่ทำงานเมื่อผู้ใช้คลิกในแผนที่
  const onMapClick = (e) => {
    if (drawingModeRef.current !== 'preparing') return;

    console.log("Switching to editing mode.");
    const { lat, lng } = e.latlng; // รับพิกัดจากการคลิก
    const zoom = mapRef.current.getZoom(); // ระดับการ zoom ปัจจุบัน
    const initialRadius = calculateInitialRadius(zoom); // คำนวณรัศมีเริ่มต้น

    // ตั้งค่า Boundary และ Radius
    setGeoBoundary({ lat, lng });
    setGeoRadius(initialRadius);

    // วาดวงกลม
    const circle = L.circle([lat, lng], {
      radius: initialRadius,
      color: circleColor, // ใช้สีจาก state
      fillColor: circleColor, // ใช้สีจาก state
      fillOpacity: 0.4,
    }).addTo(mapRef.current);
    circleRef.current = circle; // เก็บวงกลมใน useRef

    // วาดจุดตรงกลาง
    const centerMarker = L.marker([lat, lng], {
      draggable: true,
      icon: L.divIcon({
        className: 'custom-center-marker',
        html: `<div style="width: 12px; height: 12px; background: #ffffff; border: 2px solid #000; border-radius: 50%; cursor: grab;"></div>`,
      }),
    }).addTo(mapRef.current);
    centerMarkerRef.current = centerMarker; // เก็บ Center Marker ใน useRef

    // คำนวณตำแหน่งจุดบนเส้นรอบวง
    const angle = Math.random() * 2 * Math.PI;
    const edgeLat = lat + (initialRadius / 111320) * Math.cos(angle);
    const edgeLng =
      lng +
      (initialRadius / (111320 * Math.cos(lat * (Math.PI / 180)))) * Math.sin(angle);

    // วาดจุดบนเส้นรอบวง
    const edgeMarker = L.marker([edgeLat, edgeLng], {
      draggable: true,
      icon: L.divIcon({
        className: 'custom-edge-marker',
        html: `<div style="width: 12px; height: 12px; background: #ffffff; border: 2px solid #000; border-radius: 50%; cursor: grab;"></div>`,
      }),
    }).addTo(mapRef.current);
    edgeMarkerRef.current = edgeMarker; // เก็บ Edge Marker ใน useRef

    setDrawingModeWithRef('editing'); // เปลี่ยนโหมดเป็น editing

    handleShowPopup(
      "คลิ๊กจุดตรงกลางเพื่อย้ายตำแหน่ง คลิ๊กจุดรอบวงเพื่อปรับรัศมี คลิ๊กขวาออกจากโหมดแก้ไข"
    );

    // เพิ่ม Event สำหรับการลากจุด
    centerMarker.on('drag', (event) => {
      const newLatLng = event.target.getLatLng();
      circle.setLatLng(newLatLng);
      edgeMarker.setLatLng([
        newLatLng.lat + (circle.getRadius() / 111320) * Math.cos(angle),
        newLatLng.lng +
          (circle.getRadius() / (111320 * Math.cos(newLatLng.lat * (Math.PI / 180)))) *
            Math.sin(angle),
      ]);

      // อัปเดต Boundary
      setGeoBoundary({ lat: newLatLng.lat, lng: newLatLng.lng });
    });

    edgeMarker.on('drag', (event) => {
      const edgeLatLng = event.target.getLatLng();
      const centerLatLng = centerMarker.getLatLng();
      const newRadius = centerLatLng.distanceTo(edgeLatLng);
      circle.setRadius(newRadius);

      // อัปเดต Radius
      setGeoRadius(newRadius);
    });
  };

  // ผูก Event คลิกซ้ายและคลิกขวา
  mapRef.current.on('click', onMapClick); // คลิกซ้ายเพื่อวางวงกลม
  mapRef.current.on('contextmenu', cancelDrawing); // คลิกขวาเพื่อยกเลิกโหมด
};



// ฟังชั่นวาดรูปหลายเหลี่ยม สำหรับ Geo F


const startDrawingPolygon = () => {
  if (!mapRef.current) return;

  setDrawingModeWithRef('preparing'); // ตั้งโหมดเริ่มต้น
  handleShowPopup("โหลดสร้าง Polygon: คลิกซ้ายเพื่อเพิ่มจุด คลิกขวาเพื่อยกเลิก");

  markersRef.current = []; // รีเซ็ต Array หมุด
  let points = []; // เก็บจุดที่ผู้ใช้คลิก

  const onMapClick = (e) => {
    const { lat, lng } = e.latlng;
  
    if (
      markersRef.current.length > 2 &&
      markersRef.current[0].getLatLng().distanceTo([lat, lng]) < 10
    ) {
      // ปิด Polygon หากคลิกใกล้จุดเริ่มต้น
      polygonRef.current = L.polygon(
        markersRef.current.map((marker) => marker.getLatLng()),
        {
          color: circleColor,
          fillColor: circleColor,
          fillOpacity: 0.4,
        }
      ).addTo(mapRef.current);
  
      mapRef.current.off('click'); // ปิดการวาด
      updateIntermediateMarkers(); // อัปเดตจุดกึ่งกลาง
      return;
    }
  
    // เพิ่ม Marker จริง
    const marker = L.marker([lat, lng], {
      icon: L.divIcon({
        className: 'custom-white-circle',
        html: `<div style="width: 14px; height: 14px; background: white; border: 2px solid blue; border-radius: 50%; cursor: grab;"></div>`,
      }),
      draggable: true,
    }).addTo(mapRef.current);
  
    markersRef.current.push(marker);
  
    marker.on('drag', () => {
      updatePolygon();
      updateIntermediateMarkers();
    });
  
    updatePolygon();
    updateIntermediateMarkers();
  };
  
  

  mapRef.current.on('click', onMapClick);
  mapRef.current.on('contextmenu', cancelDrawing2); // ใช้ cancelDrawing สำหรับคลิกขวา
};


const cancelDrawing2 = () => {
  // ยกเลิกการคลิกซ้ายและคลิกขวา
  mapRef.current.off('click');
  mapRef.current.off('contextmenu');
  handleClosePopup();

  // ปิดการแก้ไข Polygon
  if (polygonRef.current && polygonRef.current.editing) {
    polygonRef.current.editing.disable(); // ปิดการแก้ไข
  }

  // ปิดการลาก Marker ทั้งหมด แต่ไม่ลบออกจากแผนที่
  if (markersRef.current && markersRef.current.length > 0) {
    markersRef.current.forEach((marker) => {
      if (marker.dragging) {
        marker.dragging.disable(); // ปิดการลาก
      }
    });
  }

  setDrawingModeWithRef(null); // รีเซ็ตโหมดการวาด
};


const updateIntermediateMarkers = () => {
  // ลบ Marker เงาเดิม
  intermediateMarkersRef.current.forEach((marker) => {
    mapRef.current.removeLayer(marker);
  });
  intermediateMarkersRef.current = [];

  const markers = markersRef.current;

  for (let i = 0; i < markers.length; i++) {
    const nextIndex = (i + 1) % markers.length; // วนลูประหว่างจุดแรกและจุดสุดท้าย

    if (nextIndex === 0 && polygonRef.current === undefined) {
      // ข้ามจุดแรกกับจุดสุดท้าย ถ้า Polygon ยังไม่ปิด
      continue;
    }

    const latLng1 = markers[i].getLatLng();
    const latLng2 = markers[nextIndex].getLatLng();
    const midLat = (latLng1.lat + latLng2.lat) / 2;
    const midLng = (latLng1.lng + latLng2.lng) / 2;

    // เพิ่ม Marker เงา
    const intermediateMarker = L.marker([midLat, midLng], {
      icon: L.divIcon({
        className: 'custom-white-shadow-marker',
        html: `<div style="width: 12px; height: 12px; background: white; border: 1px solid gray; opacity: 0.7; border-radius: 50%; cursor: pointer;"></div>`,
      }),
      draggable: true, // ให้ลากได้
    }).addTo(mapRef.current);

    // ผูกอีเวนต์ลากให้ Marker เงา
    intermediateMarker.on('dragstart', () => {
      intermediateMarker.setOpacity(1); // เพิ่มความชัด
    });

    intermediateMarker.on('dragend', (e) => {
      const { lat, lng } = e.target.getLatLng();
      addRealMarkerDuringDrag(lat, lng, i + 1, intermediateMarker); // แปลง Marker เงาเป็น Marker จริง
    });

    intermediateMarkersRef.current.push(intermediateMarker);
  }
};




const updatePolygon = () => {
  const points = markersRef.current.map((marker) => marker.getLatLng());
  if (polygonRef.current) {
    polygonRef.current.setLatLngs(points);
  } else {
    polygonRef.current = L.polygon(points, {
      color: circleColor,
      fillColor: circleColor,
      fillOpacity: 0.4,
    }).addTo(mapRef.current);
  }
};



const addRealMarkerDuringDrag = (lat, lng, index, intermediateMarker) => {
  // สร้าง Marker จริงเมื่อปล่อยลาก
  const realMarker = L.marker([lat, lng], {
    icon: L.divIcon({
      className: 'custom-white-circle',
      html: `<div style="width: 14px; height: 14px; background: white; border: 2px solid blue; border-radius: 50%; cursor: grab;"></div>`,
    }),
    draggable: true,
  }).addTo(mapRef.current);

  // แทรก Marker จริงใน markersRef
  markersRef.current.splice(index, 0, realMarker);

  // ลบ Marker เงาหลังจากสร้าง Marker จริง
  mapRef.current.removeLayer(intermediateMarker);
  intermediateMarkersRef.current = intermediateMarkersRef.current.filter(
    (marker) => marker !== intermediateMarker
  );

  // ผูกอีเวนต์ลากให้ Marker จริง
  realMarker.on('drag', (e) => {
    const newLatLng = e.target.getLatLng();
    markersRef.current[index] = realMarker;
    updatePolygon(); // อัปเดต Polygon
    updateIntermediateMarkers(); // อัปเดต Marker เงา
  });

  updatePolygon(); // อัปเดต Polygon
  updateIntermediateMarkers(); // อัปเดตจุดกึ่งกลางใหม่
};









// เรียก api สร้าง Geofence 
const [name, setName] = useState('');
const [description, setDescription] = useState('');
const [speedLimit, setSpeedLimit] = useState('');
const [duration, setDuration] = useState('');

const handleSave = async () => {

  setisLoading(true);

  const user = JSON.parse(localStorage.getItem("user"));
  
  if (!user || !user.id) {
    alert("ผู้ใช้งานไม่พบในระบบ กรุณาเข้าสู่ระบบอีกครั้ง");
    setisLoading(false);
    return;
  }

  if (!selectedGroup) {
    alert("กรุณาเลือกกลุ่มก่อนบันทึก");
    setisLoading(false);
    return;
  }

  const data = {
    name,
    description,
    group_id: selectedGroup, // ระบุกลุ่มที่เลือก
    user_id: user.id, // เพิ่ม user_id จาก localStorage
    type: selectedShape,
    boundary:
      selectedShape === 'circular'
        ? { lat: geoBoundary.lat, lng: geoBoundary.lng } // สำหรับวงกลม
        : markersRef.current.map((marker) => marker.getLatLng()), // สำหรับรูปหลายเหลี่ยม
    radius: selectedShape === 'circular' ? geoRadius : null, // ใช้รัศมีจาก state
    speed_limit: speedLimit,
    duration,
    color: circleColor, // เพิ่มสีในข้อมูลที่ส่ง
  };

  try {
    const response = await geofenceCreate(data);
    if (response.success) {
      showToast("ดำเนินการสำเร็จ!", "success");
      setisLoading(false);
      fetchGeoFenceDetailsAgain();
      setMode('list'); // กลับไปหน้ารายการ
      resetDrawingMode();
    } else {
      showToast(response.message, "error");
      setisLoading(false);
    }
  } catch (error) {
    console.error('Error creating geofence:', error);
    showToast(error, "error");
    setisLoading(false);
  }
};






// เรียก api เพื่อดึงข้อมูลรายละเอียด geo-f 

  // ดึงข้อมูล GeoFence Groups
  useEffect(() => {
    fetchGeoFenceDetails();
  }, [showToast]);

    // ฟังก์ชันที่สามารถบังคับให้ดึงข้อมูลซ้ำได้
    const fetchGeoFenceDetailsAgain = () => {
      fetchGeoFenceDetails(true); // forceFetch = true บังคับให้ดึงข้อมูลใหม่
    };
  
  // ฟังก์ชันที่ใช้เรียก API
  const fetchGeoFenceDetails = async (forceFetch = false) => {
    if (hasFetchedGeoFences && !forceFetch) {
      console.log("GeoFences already fetched, skipping fetch.");
      return; // ถ้าเรียกมาแล้วและไม่ได้บังคับให้ดึงใหม่ ก็จะข้ามการเรียก API
    }

    setisLoading(true);
    setError("");

    try {
      const result = await getAllGe0fDetails(); // เรียก API
      if (result.success === false) {
        setError(result.message || "Failed to fetch geo-fence details.");
        showToast(result.message || "An error occurred while fetching geo-fence details.", "error");
      } else {
        console.log("Fetched GeoFence Details:", result.geoFenceGroups); // Log ข้อมูลที่ดึงมา
        setGeoFenceGroups(result.geoFenceGroups); // เก็บข้อมูลใน state

        // เรียก generateTreeData เพื่ออัปเดต Tree
        generateTreeData(result.geoFenceGroups);
        loadGeoFenceGroups();
      }
      setHasFetchedGeoFences(true); // ตั้งค่าว่าดึงข้อมูลแล้ว
    } catch (err) {
      setError("Error fetching geo-fence details.");
      showToast("Error occurred while fetching geo-fence details.", "error");
    }

    setisLoading(false);
  };

// ฟังก์ชันสร้าง Tree Data
const generateTreeData = (groups) => {
  const formattedTreeData = groups.map((group) => ({
    title: group.name, // ชื่อกลุ่ม
    key: `group-${group.id}`, // ใช้ id ของกลุ่มเป็น key
    children: group.geo_fences?.map((fence, index) => {
      // ตรวจสอบว่า fence.geo_fence_id มีค่าหรือไม่
      if (!fence.id) {
        console.error(`GeoFence ID is missing for ${fence.name}`);
      }

      return {
        title: `${fence.name}`, // ชื่อ GeoFence
        key: `group-${group.id}-fence-${fence.id || index}`, // ใช้ geo_fence_id หรือ index เป็น Key
        description: fence.description || "", // เพิ่ม description หากจำเป็น
      };
    }) || [],
  }));

  console.log("Formatted Tree Data:", formattedTreeData); // ตรวจสอบข้อมูล

  // อัปเดต Tree Data โดยตรง
  setTreeData(formattedTreeData);

  // เรียกฟังก์ชัน drawGeoFencesOnMap
  drawGeoFencesOnMap(groups);
};

  
  // สร้าง state สำหรับเก็บ GeoFence layers
  const [geoFenceLayers, setGeoFenceLayers] = useState({});


  const drawGeoFencesOnMap = (groups) => {
    if (!mapRef.current) {
      console.warn("Map reference is not initialized.");
      return;
    }
  
    console.log("Drawing GeoFences on map with groups:", groups);
  
    // ลบเลเยอร์เดิมทั้งหมด
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Polygon || layer instanceof L.Circle) {
        mapRef.current.removeLayer(layer);
      }
    });
  
    // วาด GeoFences ใหม่และเก็บไว้ใน geoFenceLayers
    const newGeoFenceLayers = {};
  
    groups.forEach((group) => {
      if (!group.geo_fences || group.geo_fences.length === 0) {
        console.warn(`Group ${group.id} has no GeoFences.`);
        return;
      }
  
      group.geo_fences.forEach((fence) => {
        console.log(`Processing GeoFence: ${fence.name}`);

        // หากยังไม่มี parsed_boundary ให้คำนวณ
        if (!fence.parsed_boundary) {
          fence.parsed_boundary = transformGeometry(fence.boundary, fence.type);
          console.log(`Parsed Boundary for GeoFence ${fence.name}:`, fence.parsed_boundary);
        }

        const { parsed_boundary, color, type, radius } = fence;
        if (!parsed_boundary) {
          console.warn(`Invalid boundary for GeoFence ${fence.name}: ${fence.boundary}`);
          return;
        }

        let geoFenceCenter = null;

        // วาด GeoFence
        if (type === "polygon" && Array.isArray(parsed_boundary) && parsed_boundary.length > 2) {
          // คำนวณจุดกลางของ Polygon
          geoFenceCenter = getPolygonCenter(parsed_boundary);
  
          const polygon = L.polygon(
            parsed_boundary.map((coord) => [coord.lat, coord.lng]),
            {
              color: color || "#3388ff",
              fillColor: color || "#3388ff",
              fillOpacity: 0.4,
            }
          );
  
          // เก็บแผนที่ไว้ใน geoFenceLayers แต่ยังไม่แสดง
          newGeoFenceLayers[fence.id] = polygon;
        } else if (type === "circular" && parsed_boundary.lat && parsed_boundary.lng) {
          // คำนวณจุดกลางของ Circle
          geoFenceCenter = { lat: parsed_boundary.lat, lng: parsed_boundary.lng };
  
          const circle = L.circle([parsed_boundary.lat, parsed_boundary.lng], {
            radius: radius || 0,
            color: color || "#3388ff",
            fillColor: color || "#3388ff",
            fillOpacity: 0.4,
          });
  
          // เก็บแผนที่ไว้ใน geoFenceLayers แต่ยังไม่แสดง
          newGeoFenceLayers[fence.id] = circle;
        } else {
          console.warn(`Unsupported type or invalid boundary for GeoFence ${fence.name}`);
        }
  
        // แสดงข้อความที่ตำแหน่งกลางของ GeoFence
        if (geoFenceCenter) {
          const tooltip = L.tooltip({
            permanent: true,
            direction: "center",
            className: styles.geoFenceTooltip,
          })
            .setLatLng([geoFenceCenter.lat, geoFenceCenter.lng])
            .setContent(fence.name);
  
          // Tooltip จะไม่ถูกเพิ่มลงในแผนที่ทันที
          newGeoFenceLayers[fence.id].bindTooltip(tooltip);
        }
      });
    });
  
    // อัปเดต geoFenceLayers state เพื่อเก็บข้อมูลที่วาดไว้
    setGeoFenceLayers(newGeoFenceLayers);
  };
  
  
  
  // ฟังก์ชันคำนวณจุดกลางของ Polygon
  const getPolygonCenter = (polygonCoords) => {
    let latSum = 0;
    let lngSum = 0;
    const length = polygonCoords.length;
  
    polygonCoords.forEach(coord => {
      latSum += coord.lat;
      lngSum += coord.lng;
    });
  
    return {
      lat: latSum / length,
      lng: lngSum / length,
    };
  };
  
  

  const transformGeometry = (boundary, type) => {
    if (type === 'polygon' && boundary.startsWith('POLYGON')) {
      const coords = boundary
        .match(/\(\((.*?)\)\)/)[1]
        .split(',')
        .map((point) => {
          const [lng, lat] = point.trim().split(' ');
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        });
      return coords;
    } else if (type === 'circular' && boundary.startsWith('POINT')) {
      const [lng, lat] = boundary.match(/\((.*?)\)/)[1].split(' ');
      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    }
    return null;
  };


// ฟังก์ชันที่ใช้ซ่อน/แสดง GeoFence ตามการติ๊กใน Tree
const handleCheck = (checkedKeys) => {
  console.log('Checked Keys:', checkedKeys);

  setCheckedKeys(checkedKeys);

  // ซ่อนทั้งหมดก่อน
  Object.values(geoFenceLayers).forEach((layer) => {
    mapRef.current.removeLayer(layer);
  });

  // แสดงเฉพาะ GeoFences ที่ถูกเลือก
  checkedKeys.forEach((key) => {
    const geoFenceId = key.split('-')[3]; // ดึง geo_fence_id จาก key
    const layer = geoFenceLayers[geoFenceId];
    if (layer) {
      layer.addTo(mapRef.current); // แสดง GeoFence ที่ถูกเลือก
      console.log(`GeoFence with ID ${geoFenceId} is shown.`);
    }
  });
};

  // ใช้ useEffect เพื่อตรวจสอบค่าของ selectedGeoFenceId ทุกครั้งที่มีการอัปเดต
  useEffect(() => {
    if (selectedGeoFenceId !== null) {
      console.log('Current selectedGeoFenceId:', selectedGeoFenceId); // Log ค่า selectedGeoFenceId
    }
  }, [selectedGeoFenceId]); // เฝ้าติดตามการเปลี่ยนแปลงของ selectedGeoFenceId

  

  // ฟังชั่นลบ geo-f 

  const handleIconTrashClick = async () => {

    const result = await deleteGeoFenceGroup(checkedKeys); 
    if (result.success) {
        showToast("ดำเนินการสำเร็จ", "success");
        fetchGeoFenceDetailsAgain();
    } else {
        showToast(result.message, "error");
    }
    
  };
  
  
    // ฟังชั่นเปิดเมนู dropdown เพื่อเลือกเป้าหมายการย้าย 
    const handleIconMoveClick = () => {
      setIsMoving((prev) => !prev); // เปิดหรือปิด dropdown
      setTargetGroup(''); // รีเซ็ตค่ากลุ่มเป้าหมาย
    };


    // ยืนยันการย้าย
    const handleMoveConfirm = async () => {
      if (!targetGroup) {
        showToast("กรุณาเลือกกลุ่มเป้าหมาย", "error");
        return;
      }
    
      const result = await moveGeoFenceGroup(checkedKeys, targetGroup);
      if (result.success) {
        showToast("ย้าย GeoFence สำเร็จ", "success");
        fetchGeoFenceDetailsAgain();
        setIsMoving(false); // ปิด dropdown
      } else {
        showToast(result.message, "error");
      }
    };
    
    const handleMoveCancel = () => {
      setIsMoving(false); // ปิด dropdown
    };
    






    const [currentGroup, setCurrentGroup] = useState(null); // เก็บข้อมูลกลุ่มปัจจุบันที่จะแก้ไข

    const handleEdit = (key) => {
        // ฟังก์ชันรีเซ็ตค่า

      // ดึงข้อมูลจาก Local Storage
      const storedData = JSON.parse(localStorage.getItem("geoFenceGroups")) || [];
      console.log("ข้อมูลจาก Local Storage:", storedData);
    
      if (key.includes("fence")) {
        console.log("แก้ไขลูก:", key);
    
        // ค้นหา GeoFence ที่ต้องการแก้ไข
        const geoFence = storedData
          .flatMap((group) =>
            group.geo_fences?.map((fence) => ({
              ...fence,
              geo_fence_group_id: group.id, // ใช้ `id` ของกลุ่มแทน
            })) || []
          )
          .find(
            (fence) => `group-${fence.geo_fence_group_id}-fence-${fence.id}` === key
          );
    
        if (geoFence) {
          console.log("GeoFence ที่พบ:", geoFence);
    
          // ตั้งค่า CurrentGeoId
          setCurrentGeoId(geoFence.id); // เพิ่มบรรทัดนี้เพื่อเก็บค่า ID
    
          // ตั้งค่าข้อมูลสำหรับฟอร์ม
          setGeoName(geoFence.name);
          setGeoDesc(geoFence.description || "");
          setGeoColor(geoFence.color || "#000000");
          setSpeedLimit(geoFence.speed_limit || "");
          setDuration(geoFence.duration || "");
          setEnterAlert(geoFence.notify_enter || false);
          setExitAlert(geoFence.notify_exit || false);
          
          // จัดการข้อมูล Vehicles สำหรับ TreeSelect
          const vehicleIds = geoFence.vehicles?.map((vehicle) => vehicle.id) || [];
          console.log("Vehicle IDs ที่เชื่อมโยง:", vehicleIds);

          // ตั้งค่า selectedVehicles
          setSelectedVehicles(vehicleIds);

          setMode("edit-Geo-F");
        } else {
          console.error("ไม่พบ GeoFence ที่ต้องการแก้ไข:", key);
        }
      } else {
        console.log("แก้ไขกลุ่ม:", key);
    
        // ค้นหากลุ่มที่ต้องการแก้ไข
        const group = storedData.find(
          (group) => `group-${group.id}` === key
        );
    
        if (group) {
          console.log("GeoFence Group ที่พบ:", group);
        
          // เคลียร์ TreeSelect
          setSelectedVehicles([]); // เคลียร์ค่า vehicles ที่เลือกไว้
        
          // เคลียร์ค่า speedLimit, duration, enterAlert, exitAlert
          setSpeedLimit(""); // ค่าเริ่มต้นของ speed limit
          setDuration(""); // ค่าเริ่มต้นของ duration
          setEnterAlert(false); // ค่าเริ่มต้นของ enter alert
          setExitAlert(false); // ค่าเริ่มต้นของ exit alert
          setUpdateNotifications(false); // ค่าเริ่มต้นของ exit alert
          setUpdateSpeed(false); // ค่าเริ่มต้นของ exit alert
          setUpdateVehicles(false); // ค่าเริ่มต้นของ exit alert

          // ตั้งค่า currentGroup
          setCurrentGroup(group);
        
          // เปลี่ยนโหมดเป็น edit-Geo-F-Group
          setMode("edit-Geo-F-Group");
        } else {
          console.error("ไม่พบกลุ่มที่ต้องการแก้ไข:", key);
        }
        
      }
    };
    
    
    const handlePin = (key) => {
    
      const storedData = JSON.parse(localStorage.getItem("geoFenceGroups")) || [];
    
      // ค้นหา GeoFence ที่ตรงกับ key
      const geoFence = storedData
        .flatMap((group) =>
          group.geo_fences?.map((fence) => ({
            ...fence,
            geo_fence_group_id: group.id,
          })) || []
        )
        .find(
          (fence) => `group-${fence.geo_fence_group_id}-fence-${fence.id}` === key
        );
    
      if (!geoFence) {
        console.error("GeoFence not found for key:", key);
        return;
      }
    
      // ตรวจสอบว่ามี parsed_boundary หรือยัง ถ้าไม่มีให้คำนวณ
      if (!geoFence.parsed_boundary) {
        geoFence.parsed_boundary = transformGeometry(geoFence.boundary, geoFence.type);
      }
    
      // คำนวณจุดกึ่งกลาง (center) ของ GeoFence
      let geoFenceCenter = null;
      if (geoFence.type === "polygon") {
        geoFenceCenter = getPolygonCenter(geoFence.parsed_boundary);
      } else if (geoFence.type === "circular") {
        geoFenceCenter = geoFence.parsed_boundary;
      }
    
      if (geoFenceCenter) {
        // ซูมและย้ายไปยังจุดกึ่งกลาง
        if (mapRef.current) {
          mapRef.current.setView([geoFenceCenter.lat, geoFenceCenter.lng], 15);
          console.log(`Map moved to GeoFence center at:`, geoFenceCenter);
        } else {
          console.error("Map reference is not available.");
        }
      } else {
        console.error("Unable to calculate GeoFence center.");
      }
    };
    
    
    
 // ฟังก์ชัน Submit การแก้ไขกลุ่ม
const handleSaveGroup = (group) => {
  // ตรวจสอบว่า Group มีข้อมูลครบถ้วนหรือไม่
  if (!group || !group.id) {
    console.error("Group data is missing!");
    return;
  }

  // รวมข้อมูลทั้งหมดเพื่อส่งไปยัง API
  const updatedGroup = {
    id: group.id,
    name: group.name,
    description: group.description,
    vehicles: updateVehicles ? selectedVehicles : [], 
    speed_limit: updateSpeed ? speedLimit : 0, 
    duration: updateSpeed ? duration : 0,
    notify_enter: enterAlert,
    notify_exit: exitAlert,
    updateNotifications, 
    updateVehicles,
    updateSpeed, 
  };

  console.log("Updated Group Data to Save:", updatedGroup);

  // เรียกใช้ API เพื่ออัปเดตข้อมูล
  updateGeoFGroup(updatedGroup)
  .then((response) => {
    showToast("อัปเดตกลุ่มสำเร็จ", "success"); // หรือเพิ่ม response ถ้าจำเป็น
    fetchGeoFenceDetailsAgain();
    loadGeoFenceGroups();
    setMode("list"); // กลับไปที่โหมด list หลังอัปเดตสำเร็จ
  })
  .catch((error) => {
    showToast(error.message || "เกิดข้อผิดพลาดในการอัปเดตกลุ่ม", "error");
  });
};




const [currentGeoId, setCurrentGeoId] = useState(null); // เก็บ ID ของ GeoFence ที่ถูกแก้ไข
const [geoName, setGeoName] = useState(""); // ชื่อ
const [geoDesc, setGeoDesc] = useState(""); // คำอธิบาย
const [geoColor, setGeoColor] = useState("#000000"); // สี
const [enterAlert, setEnterAlert] = useState(false); // เตือนเข้าสถานี
const [exitAlert, setExitAlert] = useState(false); // เตือนออกสถานี



const handleSaveGeo = async () => {
  // ตรวจสอบข้อมูลที่จำเป็น
  if (!geoName || !geoDesc || !geoColor) {
    showToast("กรุณากรอกข้อมูลที่จำเป็นให้ครบ!", "info");
    return;
  }

  // สร้างข้อมูล Geofence พร้อม id
  const geoData = {
    id: currentGeoId,
    name: geoName,
    description: geoDesc,
    color: geoColor,
    speed_limit: speedLimit || 0, 
    duration: duration || 0,
    notify_enter: enterAlert || false,
    notify_exit: exitAlert || false,
    vehicles: selectedVehicles,
  };

  try {
    // เรียกฟังก์ชัน updateGeoFence
    const result = await updateGeoFence(geoData);

    if (result.success) {
      showToast("ดำเนินการสำเร็จ!", "success");
      // อัปเดต Local Storage ให้ตรงกับข้อมูลที่แก้ไขแล้ว
      const storedData = JSON.parse(localStorage.getItem("geoFenceData")) || [];
      const updatedData = storedData.map((group) => {
        const updatedGeoFences = group.geo_fences.map((fence) =>
          fence.id === geoData.id
            ? {
                ...fence,
                name: geoData.name,
                description: geoData.description,
                color: geoData.color,
                speed_limit: geoData.speed_limit,
                duration: geoData.duration,
                notify_enter: geoData.notify_enter,
                notify_exit: geoData.notify_exit,
                vehicles: geoData.vehicles,
              }
            : fence
        );
        return { ...group, geo_fences: updatedGeoFences };
        
      });

      localStorage.setItem("geoFenceData", JSON.stringify(updatedData));

      // เปลี่ยนโหมดกลับไปที่ list
      fetchGeoFenceDetailsAgain();
      loadGeoFenceGroups();
      setMode("list");
    } else {
      // alert(`Error: ${result.message}`);
      showToast(result.message, "error");
    }
  } catch (error) {
    console.error("Error updating GeoFence:", error);
    showToast("An unexpected error occurred. Please try again.", "error");
  }
};


// -------------------------------- เลือกยานพาหนะ 

const [vehicleTreeData, setVehicleTreeData] = useState([]);
const [selectedVehicles, setSelectedVehicles] = useState([]);

  // ฟังก์ชันสำหรับโหลดข้อมูลจาก localStorage
  const loadUserData = () => {
    const userData = JSON.parse(localStorage.getItem("user")); // ดึงข้อมูลจาก localStorage
    if (userData) {
      const groups = userData.groups.map(group => {
        const children = (group.vehicles || []).map(vehicle => ({
          title: `${vehicle.license_plate}`,
          key: `vehicle-${vehicle.id}`,
          value: `vehicle-${vehicle.id}`,
        }));
  
        return {
          title: group.name,
          key: `group-${group.id}`,
          value: `group-${group.id}`,
          ...(children.length > 0 ? { children } : {}), // เพิ่ม children เฉพาะเมื่อมีข้อมูล
        };
      });
  
      console.log("TreeData:", groups); // ตรวจสอบ TreeData ใน Console
      setVehicleTreeData(groups); // ตั้งค่าข้อมูล TreeData
    }
  };

  // โหลดข้อมูลครั้งแรกเมื่อ component ถูก mount
  useEffect(() => {
    loadUserData();
  }, []);



  // ======================================================= html ============================================================


  return (
    <div className={styles.cardSidebar}>
      {/* ปุ่มปิด */}
      <button className={styles.closeButton} onClick={onClose}>
        <IoClose size={24} />
      </button>

      {/* ชื่อหัวข้อ */}
      <p className={styles.geofenceTitle}>
        {mode === 'list' && 'Geofence'}
        {mode === 'newGeo' && 'Create Geofence'}
        {mode === 'new_Geo_Group' && 'Create Group'}
        {mode === 'edit-Geo-F-Group' && 'Edit Group'}
        {mode === 'edit-Geo-F' && 'Edit Geofence'}
      </p>

      {/* Spin loading */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinnerX"></div>
        </div>
      )}

      {!isLoading && mode === 'list' && (
        <>
          {/* ค้นหา */}
          <div className={styles.inputField}>
            <input type="text" placeholder="Please enter a name" />
          </div>

          {/* กลุ่มปุ่ม */}
          <div className={styles.buttonGroup}>
            {/* ปุ่มด้านซ้าย */}
            <div className={styles.leftButtons}>
              <button
                className={styles.button}
                onClick={() => setMode('newGeo')}
              >
                + New
              </button>
              <button className={styles.button}
              onClick={() => setMode('new_Geo_Group')}
              >
                + Add group</button>
            </div>

            {/* ไอคอนด้านขวา */}
            <div className={styles.rightIcons}>

              {/* ไอคอนย้าย  */}
              <RiDragMove2Line
                size={18}
                className={styles.icon}
                onClick={handleIconMoveClick} // เพิ่มฟังก์ชัน onClick
              />
              
              {/* ไอคอนลบ  */}
              <FaTrashCan
                size={18}
                className={styles.icon}
                onClick={handleIconTrashClick} // เพิ่มฟังก์ชัน onClick
              />
              
            </div>
          </div>

              {/* Dropdown ย้ายกลุ่ม */}
              {isMoving && (
                <div className={styles.moveDropdown}>
                  <label htmlFor="moveGroup" className={styles.label}>
                    ย้ายไปยังกลุ่ม
                  </label>

                  <div className={styles.contentRow}>

                  <select
                    id="moveGroup"
                    className={styles.select}
                    value={targetGroup}
                    onChange={(e) => setTargetGroup(e.target.value)}
                  >
                    <option value="" disabled>
                      กรุณาเลือกกลุ่ม
                    </option>
                    {geoFenceGroups.length === 0 ? (
                      <option value="" disabled>
                        ไม่มีข้อมูลกลุ่ม
                      </option>
                    ) : (
                      geoFenceGroups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))
                    )}
                  </select>

                    <div className={styles.buttonGroup}>
                      <button className={styles.okButton} onClick={handleMoveConfirm}>
                        OK
                      </button>
                      <button className={styles.cancelButton} onClick={handleMoveCancel}>
                        X
                      </button>
                    </div>
                  </div>
                </div>
              )}


          {/* ส่วนรายการกลุ่ม */}


          <div className={styles.treeWrapper}>

          <Tree
            checkable
            defaultExpandAll
            treeData={treeData}
            selectable={false}
            onCheck={handleCheck}
            titleRender={(nodeData) => {
              const isParent = nodeData.children && nodeData.children.length >= 0;

              return (
                <div
                  className={`${styles.treeNodeTitleWrapper} ${
                    isParent ? styles.parentNode : styles.childNode
                  }`}
                >
                  <div className={styles.treeNodeTitle}>
                    <strong>{nodeData.title}</strong>
                    {nodeData.description && (
                      <span style={{ marginLeft: "10px", color: "gray" }}>
                        {nodeData.description}
                      </span>
                    )}
                  </div>
                  <div className={styles.treeNodeIcon}>
                    {/* ไอคอนแก้ไข */}
                    <CiEdit
                      title="แก้ไข"
                      size={16}
                      onClick={(e) => {
                        e.stopPropagation(); // ป้องกันการกระจาย event ไปยัง Tree
                        if (typeof handleEdit === "function") handleEdit(nodeData.key);
                      }}
                    />
                    {/* ไอคอนปักหมุด (เฉพาะ node ย่อย) */}
                    {!isParent && (
                      <FaMapMarkerAlt
                        title="ปักหมุด"
                        size={16}
                        onClick={(e) => {
                          e.stopPropagation(); // ป้องกันการกระจาย event ไปยัง Tree
                          if (typeof handlePin === "function") handlePin(nodeData.key);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            }}
          />

          </div> {/*treeWrapper */}



        </>
      )}

      {mode === 'newGeo' && (
        <>
                <div className={styles.shapeSelector}>
                  <div
                    className={`${styles.shapeIcon} ${
                      selectedShape === 'circular' ? styles.activeShape : ''
                    }`}
                    onClick={() => {
                      if (selectedShape !== 'circular') {
                        resetDrawingMode();
                        setSelectedShape('circular');
                      }
                    }}
                  >
                    <BsCircle size={24} />
                    <div className={styles.shapeLabel}>วงกลม</div>
                  </div>
                  <div
                    className={`${styles.shapeIcon} ${
                      selectedShape === 'polygon' ? styles.activeShape : ''
                    }`}
                    onClick={() => {
                      if (selectedShape !== 'polygon') {
                        resetDrawingMode();
                        setSelectedShape('polygon');
                      }
                    }}
                  >
                    <BsPentagon size={24} />
                    <div className={styles.shapeLabel}>หลายเหลี่ยม</div>
                  </div>
                </div>


                <form className={styles.form}>
                  {/* ชื่อ */}
                  <label htmlFor="name" className={styles.label}>
                    ชื่อ
                  </label>
                  <input
                      id="name"
                      type="text"
                      placeholder="Enter geofence name"
                      className={styles.input}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                  {/* คำอธิบาย */}
                  <label htmlFor="description" className={styles.label}>
                    คำอธิบาย
                  </label>
                  <textarea
                    id="description"
                    placeholder="Enter description"
                    className={styles.textarea}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>

                      {/* กลุ่ม */}
                      <label htmlFor="group" className={styles.label}>
                        กลุ่ม
                      </label>
                      <select
                        id="group"
                        className={styles.select}
                        value={selectedGroup} // กำหนดค่าที่ถูกเลือก
                        onChange={handleGroupChange}
                      >
                        {geoFenceGroups.length === 0 ? (
                          <option value="" disabled>
                            ไม่มีข้อมูลกลุ่ม
                          </option>
                        ) : (
                          geoFenceGroups.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))
                        )}
                      </select>

                    {/* ข้อมูลพิกัด */}
                    <label htmlFor="boundary" className={styles.label}>
                      พิกัด: 
                      {selectedShape === 'circular' ? (
                        // สำหรับวงกลม
                        <span id="boundary">{`Lat: ${geoBoundary.lat || 'N/A'}, Lng: ${geoBoundary.lng || 'N/A'}`}</span>
                      ) : (
                        // สำหรับรูปหลายเหลี่ยม
                        <span id="boundary">
                          {markersRef.current.length > 0
                            ? markersRef.current.map((marker, index) => {
                                const { lat, lng } = marker.getLatLng();
                                return (
                                  <div key={index}>{`Point ${index + 1}: Lat: ${lat}, Lng: ${lng}`}</div>
                                );
                              })
                            : 'N/A'}
                        </span>
                      )}
                    </label>

                    {/* รัศมี */}
                    {selectedShape === 'circular' && (
                      <label htmlFor="radius" className={styles.label}>
                        รัศมี: <span id="radiusValue">{geoRadius || 'N/A'}</span> เมตร
                      </label>
                    )}

                      {/* สี */}
                      <label htmlFor="color" className={styles.label}>
                        สี
                      </label>
                      <input
                        id="color"
                        type="color"
                        className={styles.colorPicker}
                        value={circleColor}
                        onChange={(e) => handleColorChange(e.target.value)}
                      />
                </form>



          {/* ปุ่ม Save และ Back */}
          <div className={styles.footerButtons}>
            <button
              type="button"
              className={styles.saveButton}
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className={styles.backButton}
              onClick={() => setMode('list')}
            >
              Back
            </button>
          </div>
        </>
      )}

      {mode === "new_Geo_Group" && (
        <>
          <form className={styles.form} onSubmit={handleSubmit}>
            {/* ชื่อ */}
            <label htmlFor="newGeoG_name" className={styles.label}>
              ชื่อ
            </label>
            <input
              id="newGeoG_name"
              type="text"
              placeholder="Enter geofence group name"
              className={styles.input}
              value={gName}
              onChange={(e) => setGName(e.target.value)}
              required
            />

            {/* คำอธิบาย */}
            <label htmlFor="newGeoG_desc" className={styles.label}>
              คำอธิบาย
            </label>
            <textarea
              id="newGeoG_desc"
              placeholder="Enter group description"
              className={styles.textarea}
              value={gDesc}
              onChange={(e) => setGDesc(e.target.value)}
              required
            ></textarea>

            {/* แสดงข้อผิดพลาด */}
            {error && <p className={styles.error}>{error}</p>}
          </form>

          {/* ปุ่ม Save และ Back */}
          <div className={styles.footerButtons}>
            <button
              type="submit"
              className={styles.saveButton}
              onClick={handleSubmit}
              disabled={isLoading} // ปิดปุ่มถ้าอยู่ในสถานะโหลด
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
            <button
              className={styles.backButton}
              onClick={() => setMode("list")}
              disabled={isLoading} // ปิดปุ่มถ้าอยู่ในสถานะโหลด
            >
              Back
            </button>
          </div>
        </>
      )}


      {mode === "edit-Geo-F-Group" && (
          <>
            {currentGroup ? (
              <>
                <form className={styles.form}>
                  {/* ชื่อ */}
                  <label htmlFor="group_name" className={styles.label}>
                    ชื่อกลุ่ม
                  </label>
                  <input
                    id="group_name"
                    type="text"
                    placeholder="Enter group name"
                    className={styles.input}
                    value={currentGroup.name || ""}
                    onChange={(e) =>
                      setCurrentGroup({ ...currentGroup, name: e.target.value })
                    }
                  />

                  {/* คำอธิบาย */}
                  <label htmlFor="group_desc" className={styles.label}>
                    คำอธิบาย
                  </label>
                  <textarea
                    id="group_desc"
                    placeholder="Enter group description"
                    className={styles.textarea}
                    value={currentGroup.description || ""}
                    onChange={(e) =>
                      setCurrentGroup({
                        ...currentGroup,
                        description: e.target.value,
                      })
                    }
                  ></textarea>

                  {/* ส่วนอื่นๆ */}
                  <h2><br /></h2>
                  <h2>การแจ้งเตือน</h2>
                  
                  <label>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      checked={updateVehicles}
                      onChange={(e) => setUpdateVehicles(e.target.checked)}
                    />
                    อัปเดตยานพาหนะทุกสถานีภายในกลุ่มนี้
                  </label>

                  <h4>เลือกยานพาหนะ</h4>
                  <TreeSelect
                    treeData={vehicleTreeData}
                    value={selectedVehicles.map((id) => `vehicle-${id}`)}
                    onChange={(value) => {
                      const filteredValues = value.filter((val) =>
                        val.startsWith("vehicle-")
                      );
                      const vehicleIds = filteredValues.map((val) =>
                        val.replace("vehicle-", "")
                      );
                      setSelectedVehicles(vehicleIds); // ตั้งค่าเป็น ID ของ vehicles
                    }}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    placeholder="เลือกยานพาหนะ"
                    style={{ width: "100%" }}
                    treeDefaultExpandAll={true}
                  />

                  {/* Overspeed Alert */}
                  <fieldset className={styles.fieldset}>

                      <label>
                        <input
                          type="checkbox"
                          className={styles.checkbox}
                          checked={updateSpeed}
                          onChange={(e) => setUpdateSpeed(e.target.checked)}
                        />
                        อัปเดตความเร็วทุกสถานีภายในกลุ่มนี้
                      </label>

                    <label htmlFor="speedLimit" className={styles.label}>
                      จำกัดความเร็ว (km/h)
                    </label>
                    <input
                      id="speedLimit"
                      type="number"
                      placeholder="Enter speed limit"
                      className={styles.input}
                      value={speedLimit}
                      onChange={(e) => setSpeedLimit(e.target.value)}
                    />

                    <label htmlFor="duration" className={styles.label}>
                      ระยะเวลา (วินาที)
                    </label>
                    <input
                      id="duration"
                      type="number"
                      placeholder="Enter duration"
                      className={styles.input}
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </fieldset>

                  {/* Notification Options */}
                  <fieldset className={styles.fieldset}>

                      <label>
                        <input
                          type="checkbox"
                          className={styles.checkbox}
                          checked={updateNotifications}
                          onChange={(e) => setUpdateNotifications(e.target.checked)}
                        />
                        อัปเดตเข้า/ออกสถานี ทุกสถานีภายในกลุ่มนี้
                      </label>

                    <label htmlFor="notify" className={styles.labelnotify}>
                      เตือนเข้าออกสถานี
                    </label>
                    <div className={styles.checkboxGroup}>
                      <label htmlFor="notifyEnter" className={styles.checkboxLabel}>
                        <input
                          id="notifyEnter"
                          type="checkbox"
                          className={styles.notifyChack}
                          checked={enterAlert}
                          onChange={(e) => setEnterAlert(e.target.checked)}
                        />
                        เข้า
                      </label>

                      <label htmlFor="notifyExit" className={styles.checkboxLabel}>
                        <input
                          id="notifyExit"
                          type="checkbox"
                          className={styles.notifyChack}
                          checked={exitAlert}
                          onChange={(e) => setExitAlert(e.target.checked)}
                        />
                        ออก
                      </label>
                    </div>
                  </fieldset>
                </form>

                {/* ปุ่ม Save และ Back */}
                <div className={styles.footerButtons}>
                  <button
                    type="submit"
                    className={styles.saveButton}
                    onClick={() =>
                      handleSaveGroup(currentGroup, {
                        updateVehicles,
                        updateSpeed,
                        updateNotifications,
                      })
                    }
                  >
                    Save
                  </button>
                  <button
                    className={styles.backButton}
                    onClick={() => setMode("list")}
                  >
                    Back
                  </button>
                </div>
              </>
            ) : (
              <p>Loading group details...</p>
            )}
          </>
        )}


      {mode === "edit-Geo-F" && (
        <>
          <form className={styles.form}>
            {/* ชื่อ */}
            <label htmlFor="geo_name" className={styles.label}>
              ชื่อ
            </label>
            <input
              id="geo_name"
              type="text"
              placeholder="Enter geofence name"
              className={styles.input}
              value={geoName}
              onChange={(e) => setGeoName(e.target.value)}
            />

            {/* คำอธิบาย */}
            <label htmlFor="geo_desc" className={styles.label}>
              คำอธิบาย
            </label>
            <textarea
              id="geo_desc"
              placeholder="Enter description"
              className={styles.textarea}
              value={geoDesc}
              onChange={(e) => setGeoDesc(e.target.value)}
            ></textarea>

            {/* สี */}
            <label htmlFor="geo_color" className={styles.label}>
              สี
            </label>
            <input
              id="geo_color"
              type="color"
              className={styles.colorPicker}
              value={geoColor}
              onChange={(e) => setGeoColor(e.target.value)}
            />

            <h2><br /></h2>
            <h2>การแจ้งเตือน</h2>
            <h4>เลือกยานพาหนะ</h4>
            <TreeSelect
              treeData={vehicleTreeData}
              value={selectedVehicles.map(id => `vehicle-${id}`)}
              onChange={(value) => {
                console.log("Original value:", value); // ตรวจสอบค่าที่ได้จาก TreeSelect
                const filteredValues = value.filter(val => val.startsWith("vehicle-"));
                const vehicleIds = filteredValues.map(val => val.replace("vehicle-", ""));
                console.log("Filtered vehicle IDs:", vehicleIds); // ตรวจสอบค่า ID หลังจากลบ prefix
                setSelectedVehicles(vehicleIds); // ตั้งค่าเป็น ID ของ vehicles
              }}              
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              placeholder="เลือกยานพาหนะ"
              style={{ width: "100%" }}
              treeDefaultExpandAll={true}
              // กำหนดเงื่อนไขการค้นหา
              filterTreeNode={(inputValue, treeNode) => {
                return (
                  treeNode.key.startsWith("vehicle-") &&
                  treeNode.title.toLowerCase().includes(inputValue.toLowerCase())
                );
              }}  
            />

            {/* Overspeed Alert */}
            <fieldset className={styles.fieldset}>
              <label htmlFor="speedLimit" className={styles.label}>
                จำกัดความเร็ว (km/h)
              </label>
              <input
                id="speedLimit"
                type="number"
                placeholder="Enter speed limit"
                className={styles.input}
                value={speedLimit}
                onChange={(e) => setSpeedLimit(e.target.value)}
              />

              <label htmlFor="duration" className={styles.label}>
                ระยะเวลา (วินาที)
              </label>
              <input
                id="duration"
                type="number"
                placeholder="Enter duration"
                className={styles.input}
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </fieldset>

            {/* Notification Options */}
            <fieldset className={styles.fieldset}>
            <label htmlFor="notify" className={styles.labelnotify}>
                เตือนเข้าออกสถานี
              </label>
              <div className={styles.checkboxGroup}>
                <label htmlFor="notifyEnter" className={styles.checkboxLabel}>
                  <input
                    id="notifyEnter"
                    type="checkbox"
                    className={styles.notifyChack}
                    checked={enterAlert}
                    onChange={(e) => setEnterAlert(e.target.checked)}
                  />
                  เข้า
                </label>

                <label htmlFor="notifyExit" className={styles.checkboxLabel}>
                  <input
                    id="notifyExit"
                    type="checkbox"
                    className={styles.notifyChack}
                    checked={exitAlert}
                    onChange={(e) => setExitAlert(e.target.checked)}
                  />
                  ออก
                </label>
              </div>
            </fieldset>
          </form>

          {/* ปุ่ม Save และ Back */}
          <div className={styles.footerButtons}>
            <button
              type="button"
              className={styles.saveButton}
              onClick={handleSaveGeo}
            >
              Save
            </button>
            <button
              className={styles.backButton}
              onClick={() => setMode("list")}
            >
              Back
            </button>
          </div>
        </>
      )}




    </div>
  );
};

export default GeofenceContent;
