import React, { useState } from 'react';
import '../styles/OverviewW1.css';

const OverviewW1 = () => {
  const [activeTab, setActiveTab] = useState('device');

  const OverviewW1 = [
    { title: 'Total', value: 100, detail: 'Total 3' },
    { title: 'Activated', value: 100, detail: 'Activated 3' },
    { title: 'Inactivated', value: 0, detail: 'Inactivated 0' },
    { title: 'Expired', value: 0, detail: 'Expired 0' },
    { title: 'Expiring soon', value: 0, detail: 'Expiring soon 0' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'device':
        return (
          <>
            <div className="table-section">
              <h2>Device Overview</h2>
              <table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Device Name</th>
                    <th>Address</th>
                    <th>Mileage</th>
                    <th>GPS Positioning</th>
                    <th>GSM</th>
                    <th>Speed (km/h)</th>
                    <th>State</th>
                    <th>Group</th>
                    <th>Position Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>LL301-66527</td>
                    <td>Parse Address</td>
                    <td>0</td>
                    <td>15</td>
                    <td>4</td>
                    <td>0</td>
                    <td>Offline</td>
                    <td>Default Group</td>
                    <td>2024-11-18 10:56:12</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case 'motion':
        return (
          <div className="table-section">
            <h2>Motion Overview</h2>
            <p>ข้อมูล Motion</p>
          </div>
        );
      case 'alert':
        return (
          <div className="table-section">
            <h2>Alert Overview</h2>
            <p>ข้อมูล Alert</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'device' ? 'active' : ''}`}
          onClick={() => setActiveTab('device')}
        >
          Device Overview
        </button>
        <button
          className={`tab-button ${activeTab === 'motion' ? 'active' : ''}`}
          onClick={() => setActiveTab('motion')}
        >
          Motion Overview
        </button>
        <button
          className={`tab-button ${activeTab === 'alert' ? 'active' : ''}`}
          onClick={() => setActiveTab('alert')}
        >
          Alert Overview
        </button>
      </div>
      <div className="content-container">
        {renderContent()}
      </div>
    </div>
  );
};

export default OverviewW1;
