// src/RequireAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const checkLoginStatus = () => {
    const token = localStorage.getItem('authToken');
    return !!token; // ถ้ามี token แสดงว่าผู้ใช้ล็อกอินแล้ว
};

const RequireAuth = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null); 

    useEffect(() => {
        const loggedIn = checkLoginStatus();
        setIsAuthenticated(loggedIn);

        if (!loggedIn) {
            navigate('/login'); // ถ้ายังไม่ได้ล็อกอิน ให้ไปหน้า /login
        }
    }, [navigate]);

    // ถ้ากำลังตรวจสอบการล็อกอินอยู่ หรือยังไม่ล็อกอิน ให้ return null
    if (isAuthenticated === null) {
        return null; // หรือสามารถใส่ loading spinner ได้
    }

    return isAuthenticated ? children : null;
};

export default RequireAuth;
