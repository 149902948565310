import React, { useState } from "react";
import styles from "../styles/Management.module.css";

const Management = () => {
  const [isLeftColumnVisible, setLeftColumnVisible] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);

  // ข้อมูลจำลอง
  const treeData = [
    {
      id: "all",
      name: "กลุ่มทั้งหมด (10)",
      children: [
        { id: "groupA", name: "กลุ่ม A (5)" },
        { id: "groupB", name: "กลุ่ม B (3)" },
        { id: "groupC", name: "กลุ่ม C (2)" },
      ],
    },
  ];

  const toggleLeftColumn = () => {
    setLeftColumnVisible(!isLeftColumnVisible);
  };

  const handleSelect = (id) => {
    setSelectedGroup(id); // บันทึก ID ของรายการที่เลือก
  };

  return (
    <div className={styles["full-screen-table"]}>
      <div
        className={styles["left-column"]}
        style={{
          width: isLeftColumnVisible ? "200px" : "50px", // ปรับขนาด
          transition: "width 0.3s ease",
        }}
      >
        <div className={styles["card-1"]}>
          {/* ปุ่มบนขวาของการ์ด */}
          <button
            onClick={toggleLeftColumn}
            className={styles["toggle-button"]}
          >
            {isLeftColumnVisible ? "ปิด" : "เปิด"}
          </button>
          {/* Tree View แสดงเฉพาะเมื่อเปิด */}
          {isLeftColumnVisible && (
            <div className={styles["tree-view"]}>
              {treeData.map((group) => (
                <div key={group.id}>
                  <div
                    className={`${styles["tree-item"]} ${
                      selectedGroup === group.id ? styles["selected"] : ""
                    }`}
                    onClick={() => handleSelect(group.id)}
                  >
                    {group.name}
                  </div>
                  {group.children &&
                    group.children.map((child) => (
                      <div
                        key={child.id}
                        className={`${styles["tree-item-child"]} ${
                          selectedGroup === child.id ? styles["selected"] : ""
                        }`}
                        onClick={() => handleSelect(child.id)}
                      >
                        {child.name}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles["right-column"]}>
        <div className={styles["card-2"]}>
          <div className={styles["content-wrapper"]}>
            <h2 className={styles["welcome-text"]}>ข้อมูลในระบบ</h2>
            <div>
              <h3>กลุ่ม A</h3>
              <h3>กลุ่ม B</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
