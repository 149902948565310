//  src/pages/Home.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import L from 'leaflet';
import { TbMessage2 } from "react-icons/tb";
import { FaCarSide, FaHistory  } from "react-icons/fa";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

import 'leaflet.gridlayer.googlemutant';
import 'leaflet/dist/leaflet.css';
import '../styles/Home.css';

import ToastNotification from '../utils/ToastNotification';
import { MapProvider } from "../context/MapContext";
import TrackW1 from '../components/TrackW1';
import AlertW2 from '../components/AlertW2';
import HistoryW3 from '../components/HistoryW3';
import MapComponent from '../components/MapComponent';
import { updateData,getUserDetails,userUpdate } from "../apiCalls";

import LoopContext from "../context/LoopContext";

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [activeCard, setActiveCard] = useState(1); //เก็บเมนูที่เลือก
  const [isBottomSidebarOpen, setIsBottomSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [groups, setGroups] = useState(() => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    return user.groups || [];
  });  
  const [expandedGroups, setExpandedGroups] = useState([]); // เพิ่ม expandedGroups
  const [isLoading, setIsLoading] = useState(false); // เพิ่มสถานะการโหลด
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [notifications, setNotifications] = useState([]);
  // state ควบคุมการแสดงผลเนื้อหา
  const [isContentVisible, setIsContentVisible] = useState(true);

  const { GpsStatus , secondsRemaining } = useContext(LoopContext);





  const mapRef = useRef(null);

  // ฟังก์ชันเรียก showIcon ใน MapComponent
  const handleShowIcon = (imei) => {
    if (mapRef.current && mapRef.current.showIcon) {
      mapRef.current.showIcon(imei);
    }
  };


  // ------------------------------------------------ แจ้งเตือน popup
  const showToast = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  
    // ตั้งเวลาหายไปของแจ้งเตือน
    setTimeout(() => {
      removeToast(id);
    }, 6000);
  };
  

  const removeToast = (id) => {
    setNotifications((prev) => prev.filter((toast) => toast.id !== id));
  };

  const handleTestToast = (message, type) => {
    showToast(message, type);
  };
  
  // ------------------------ เปืด Sidebar ------------------------

  const toggleSidebar = (button) => {
    if (button === activeButton) {
      // หากกดปุ่มเดิมซ้ำ ให้ปิด sidebar
      setIsSidebarOpen(false);
      setActiveButton(null);
    } else {
      // หากกดปุ่มใหม่ ให้เปิด sidebar และแสดงเนื้อหาของปุ่มนั้น
      setActiveButton(button);
      setIsSidebarOpen(true);
    }
  };

  const toggleBottomSidebar = () => {
    setIsBottomSidebarOpen(!isBottomSidebarOpen);
  };
  

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setActiveButton(null);
  };

  const handleCardClick = (cardNumber) => {
    setActiveCard(cardNumber);
  };

  // ------------------------ เริ่มต้นหน้า

  useEffect(() => {
      refreshGroups();
  }, []);


  // ฟังชั่นเรียก api เมื่อกดปุ่ม รีเฟชร หรือ เรียกจาก useEffect
  const refreshGroups = async () => {
    setIsLoading(true);
  
    const pendingChanges = JSON.parse(localStorage.getItem("pendingChanges")) || [];
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || !user.id) {
      console.error("User not found in localStorage.");
      setIsLoading(false);
      return;
    }
  
    try {
      // อัปเดตรายการเปลี่ยนแปลง (ถ้ามี)
      if (pendingChanges.length > 0) {
        console.log("Updating pending changes:", pendingChanges);
        const updateResult = await updateData("groups", pendingChanges);
  
        if (updateResult.success) {
          console.log("Pending changes updated successfully.");
          localStorage.removeItem("pendingChanges");
        } else {
          console.error("Failed to update pending changes:", updateResult.message);
          handleTestToast("Failed to update changes.", "error");
        }
      }
  
      // ดึงข้อมูลใหม่จาก API
      const userDetailsResult = await getUserDetails(user.id);
  
      if (userDetailsResult.success) {
        // อัปเดตข้อมูลใน localStorage
        localStorage.setItem("user", JSON.stringify(userDetailsResult.user));
        
        // โหลดกลุ่มใหม่พร้อมข้อมูลติดดาว
        loadGroups();
      } else {
        console.error("Failed to fetch user details:", userDetailsResult.message);
        handleTestToast("Failed to fetch user details.", "error");
      }
    } catch (error) {
      console.error("Error refreshing groups:", error);
      handleTestToast("An error occurred while refreshing groups.", "error");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const loadGroups = () => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const starredVehicles = JSON.parse(localStorage.getItem("starredVehicles")) || {};
      const groupsFromLocalStorage = user.groups || [];
  
      // สร้าง `groups` โดยใช้ข้อมูลใน localStorage
      const updatedGroups = groupsFromLocalStorage.map((group) => ({
        ...group,
        vehicles: group.vehicles.map((vehicle) => ({
          ...vehicle,
          isStarred: !!starredVehicles[vehicle.id],
          gpsStatus: "", // ใช้ค่าเริ่มต้น
          timeDifference: "", // ใช้ค่าเริ่มต้น
          dataDate: "", // ใช้ค่าเริ่มต้น
          gpsStatusText: "",
        })),
        is_expanded: group.is_expanded ?? false,
      }));
  
      setGroups([...updatedGroups]);
    } catch (error) {
      console.error("Error loading groups:", error);
      setGroups([]);
    }
    // เรียก GpsStatus เพื่อเริ่มคำนวณ GPS หลังจากสร้างข้อมูลกลุ่ม
    GpsStatus();
  };
  

    // ฟังก์ชัน toggleGroup สำหรับเปิด/ปิดกลุ่ม
    const toggleGroup = (groupId, isExpanded) => {
      // อัปเดต `groups` ใน State
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                is_expanded: isExpanded, // ใช้ค่าสถานะที่รับเข้ามา
              }
            : group
        )
      );
    
      // อัปเดต `expandedGroups` ใน State
      setExpandedGroups((prev) =>
        isExpanded
          ? [...prev, groupId] // เพิ่ม ID กลุ่มที่เปิดเข้าไป
          : prev.filter((id) => id !== groupId) // ลบ ID กลุ่มที่ปิดออก
      );
    
      // บันทึกการเปลี่ยนแปลง (ถ้าจำเป็น)
      saveGroupChange(groupId, isExpanded);
    };
    
    // ฟังชั่นบันทึกการเปลี่ยนแปลงสถานะกลุ่ม
    const saveGroupChange = (groupId, isExpanded) => {
      const pendingChanges = JSON.parse(localStorage.getItem("pendingChanges")) || [];
      const updatedChanges = pendingChanges.filter((change) => change.groupId !== groupId);
    
      updatedChanges.push({ groupId, isExpanded }); // เพิ่มข้อมูลใหม่
      localStorage.setItem("pendingChanges", JSON.stringify(updatedChanges));
    };
    
    
    const handleToggle = () => {
      if (isCollapsed) {
        // เมื่อเปิด: รอ 0.3 วินาทีก่อนแสดงเนื้อหา
        setIsCollapsed(false);
        setTimeout(() => setIsContentVisible(true), 300);
      } else {
        // เมื่อปิด: ซ่อนเนื้อหาทันที
        setIsContentVisible(false);
        setIsCollapsed(true);
      }
    };





// -----------------------------------
useEffect(() => {
  const fetchDataAndUpdateUser = async () => {
    try {
      // รอ 10 วินาทีก่อนเริ่มทำงาน
      await new Promise((resolve) => setTimeout(resolve, 10000));
      
      // ดึงตำแหน่งที่ตั้ง
      const getLocation = () => {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                const locationData = { latitude, longitude };
                localStorage.setItem('userLocation', JSON.stringify(locationData));
                // console.log('ตำแหน่ง:', locationData);
                resolve(locationData);
              },
              (error) => {
                // console.error('Error getting location:', error);
                reject(error);
              },
              { enableHighAccuracy: true }
            );
          } else {
            // console.error('Geolocation is not supported by this browser.');
            reject(new Error('Geolocation is not supported by this browser.'));
          }
        });
      };

      // ดึง IP Address
      const fetchIpAddress = async () => {
        try {
          const response = await fetch('https://api.ipify.org?format=json');
          const data = await response.json();
          localStorage.setItem('ipAddress', data.ip);
          // console.log('IP Address:', data.ip);
          return data.ip;
        } catch (error) {
          // console.error('Error fetching IP address:', error);
          throw error;
        }
      };

      // เรียกใช้ทั้งสองฟังก์ชัน
      await getLocation();
      await fetchIpAddress();

      // เรียก userUpdate หลังจากได้ข้อมูลครบ
      const updateResult = await userUpdate();
      // console.log('User updated successfully:', updateResult);
    } catch (error) {
      // console.error('Error in fetching data or updating user:', error);
    }
  };

  fetchDataAndUpdateUser();
}, []);






  return (
    <div className={`home-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>

    <MapProvider>

      {/* Toast Notification */}
      <ToastNotification
        notifications={notifications}
        onRemove={removeToast}
      />

        <table className="full-screen-table">
          <tbody>
            <tr>
              <td className="td1">
                <div className="card-container">
                  {/* การ์ดพื้นหลัง */}
                  <div className="background-card">
                    {/* การ์ดที่แทรกเข้าไปข้างใน */}
                    <div className="inner-card-container">
                      <button
                        className={`inner-card ${activeCard === 1 ? 'active' : ''}`}
                        onClick={() => handleCardClick(1)}
                      >
                        <FaCarSide size={24} /> รายการ
                      </button>
                      <button
                        className={`inner-card ${activeCard === 2 ? 'active' : ''}`}
                        onClick={() => handleCardClick(2)}
                      >
                        <TbMessage2 size={24} /> แจ้งเตือน
                      </button>
                      <button
                        className={`inner-card ${activeCard === 3 ? 'active' : ''}`}
                        onClick={() => handleCardClick(3)}
                      >
                        <FaHistory size={24} /> ย้อนหลัง
                      </button>
                    </div>

                  </div>
                </div>
              </td>



              <td className={`td-card-2 ${isCollapsed ? 'collapsed' : ''}`}>
                <div className={`card card-2 ${isCollapsed ? 'collapsed2' : ''}`}>
                {isContentVisible && (
                  <div className="content-wrapper">
                    {/* แสดงเนื้อหาตามการ์ดที่ถูกเลือก */}
                    {activeCard === 1 && (

                      <TrackW1
                        groups={groups}
                        isLoading={isLoading}
                        refreshGroups={refreshGroups}
                        loadGroups={loadGroups}
                        setGroups={setGroups} 
                        toggleGroup={toggleGroup}
                        showToast={showToast}
                        showIcon={handleShowIcon}
                      />

                    )}
                    {activeCard === 2 && <AlertW2 />}
                    {activeCard === 3 && <HistoryW3 />}
                  </div>
                )}
                </div>
                <button
                  className={`toggle-button ${isCollapsed ? "button-right" : "button-left"}`}
                  onClick={handleToggle}
                >
                  {isCollapsed ? <IoMdArrowDropright size={20} /> : <IoMdArrowDropleft size={20} />}
                </button>


              </td>



              <td className="map-td">
                <MapComponent
                  ref={mapRef}
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  activeButton={activeButton}
                  closeSidebar={closeSidebar}
                  isBottomSidebarOpen={isBottomSidebarOpen}
                  toggleBottomSidebar={toggleBottomSidebar}
                  isCollapsed={isCollapsed}
                  showToast={showToast}
                  secondsRemaining={secondsRemaining}
                />
              </td>




            </tr>
          </tbody>
        </table>
        
      </MapProvider>

    </div>
  );
};

export default Home;
