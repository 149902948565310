import React from "react";
import "../styles/ToastNotification.css"; // ไฟล์ CSS ของ Toast
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';

const ToastNotification = ({ notifications, onRemove }) => {
  return (
    <div className="toast-container">
      {notifications.map((toast) => (
        <div
          key={toast.id}
          className={`toast ${toast.type}`}
          onClick={() => onRemove(toast.id)} // ลบ Toast เมื่อคลิก
        >
  {toast.type === "success" && <FaCheckCircle />}
  {toast.type === "error" && <FaTimesCircle />}
  {toast.type === "info" && <FaInfoCircle />}
          <span>{toast.message}</span>
        </div>
      ))}
    </div>
  );
};

export default ToastNotification;
