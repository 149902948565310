import React, { useState, useEffect, useRef } from 'react';
import { Tree } from 'antd';
import { Spin } from "antd";
import { FaFolder } from 'react-icons/fa';
import '../styles/Fuelmanag.css';

const Fuelmanag = () => {
  const [loading, setLoading] = useState(false); // สถานะกำลังโหลด
  const [selectedOption, setSelectedOption] = useState('option1');

  // ฟังก์ชันเปลี่ยนค่าที่เลือก
  const handleMenuClick = (option) => {
    setSelectedOption(option);
  };

  // รัน spin
  const handleAddData = () => {
    setLoading(true); // เริ่มโหลด
    setTimeout(() => {
      setLoading(false); // ปิดการโหลดหลัง 2 วินาที
    }, 2000);
  };

  // ------------------ ส่วนของ tree หน้าแรก 

  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // ฟังก์ชันโหลดข้อมูลจาก localStorage และสร้าง Tree
  const loadUserData = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      const groups = userData.groups.map((group) => {
        const children = (group.vehicles || []).map((vehicle) => {
          const iconPath = `/assets/car_icon_list/${vehicle.icon ? vehicle.icon + '.svg' : '1.svg'}`;
        
          // ดึงข้อมูล gps_status จาก localStorage
          const gpsStatusData = JSON.parse(localStorage.getItem('gps_status')) || {};
          const vehicleStatus = gpsStatusData[vehicle.id]?.gpsStatus || 'offline'; // สถานะของยานพาหนะ
        
          // กำหนดสีพื้นหลังตามสถานะ
          const backgroundColor =
            vehicleStatus === "offline"
              ? "#424242" // สีเทา
              : vehicleStatus === "stop"
              ? "#f00232" // สีแดง
              : vehicleStatus === "idle"
              ? "#bfbf30" // สีเหลือง
              : vehicleStatus === "run"
              ? "#00bf17" // สีเขียว
              : vehicleStatus === "v"
              ? "#0d7ad4" // สีน้ำเงิน
              : "#424242"; // สีเริ่มต้น (สีเทา)
        
          return {
            title: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {/* พื้นหลังของไอคอน */}
                <div
                  style={{
                    width: '18px',
                    height: '18px',
                    backgroundColor, // ใช้สีพื้นหลังที่คำนวณจาก gpsStatus
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    transform: 'scaleX(-1)', // พลิกแนวนอน
                  }}
                >
                  <img src={iconPath} alt="vehicle icon" style={{ width: '14px', height: '14px' }} />
                </div>
                <span>{vehicle.license_plate} [ {vehicle.gps_device?.imei || 'No IMEI'} ]</span>
              </div>
            ),
            key: `vehicle-${vehicle.id}`,
            value: `vehicle-${vehicle.id}`,
            className: 'child-node',
          };
        });
        

        return {
          title: (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <FaFolder style={{ color: '#2e2e2e', fontSize: '14px' }} />
              <span>{group.name}</span>
            </div>
          ),
          key: `group-${group.id}`,
          value: `group-${group.id}`,
          children: children.length > 0 ? children : undefined,
          className: 'parent-node',
        };
      });

      setTreeData(groups);
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownVisible(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const handleSelect = (selectedKeys, { node }) => {
    if (node.key.startsWith("group-")) {
      setExpandedKeys((prevKeys) =>
        prevKeys.includes(node.key)
          ? prevKeys.filter((key) => key !== node.key)
          : [...prevKeys, node.key]
      );
      return;
    }

    setSelectedValue(node.title);
    setIsDropdownVisible(false);
  };


  


  return (
    <div className="page-container">
      {/* ส่วนด้านบน: เมนู */}
      <div className="menu-bar">
        <button
          className={`menu-button ${selectedOption === 'option1' ? 'active' : ''}`}
          onClick={() => handleMenuClick('option1')}
        >
          สายน้ำมัน
        </button>
        <button
          className={`menu-button ${selectedOption === 'option2' ? 'active' : ''}`}
          onClick={() => handleMenuClick('option2')}
        >
          ตัวเลือกอื่น
        </button>
      </div>

      {/* ส่วนด้านล่าง: เนื้อหาเปลี่ยนตามตัวเลือก */}
      <div className="content-area">
        {selectedOption === 'option1' && (
          <div className="two-column-layout">


            {/* คอลัมน์ซ้าย */}
            <div className="left-column">
              <h2>ข้อมูลเสริม</h2>
              <p>เนื้อหาที่เกี่ยวข้องในส่วนซ้าย เช่น เมนูย่อย หรือข้อมูลเพิ่มเติม</p>
              <Tree
                className="custom-tree"
                blockNode
                treeData={treeData.map((item) => ({
                  ...item,
                  style: {
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    fontWeight: 'bold',
                    fontSize: '10px',
                    color: '#2e2e2e',
                  },
                  children: item.children?.map((child) => ({
                    ...child,
                    style: {
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                      fontWeight: 'bold',
                      fontSize: '10px',
                      color: '#2e2e2e',
                    },
                  })),
                }))}
                onSelect={handleSelect}
                onExpand={(keys) => setExpandedKeys(keys)}
                expandedKeys={expandedKeys}
                defaultExpandAll={false}
              />



            </div>

            {/* คอลัมน์ขวา */}
            <div className="right-column">

                  {/* Spin Loader */}
                  {loading && (
                    <div className="loading-overlay">
                      <Spin size="large" />
                    </div>
                  )}

              {!loading && (
                <>
                  {/* แถบสถานะ */}
                  <div className="status-bar">
                    <span className='AAA'>ทะเบียน : 12-3456</span>
                    <span className='AAA'>สถานะน้ำมัน: ไม่สมบูรณ์</span>
                  </div>

                  {/* ปุ่มเพิ่มข้อมูล */}
                  <div className="action-bar">
                    <button onClick={handleAddData}>เพิ่มข้อมูล</button>
                  </div>

                  {/* ตารางข้อมูล */}
                  <div className="data-table">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>เปอร์เซ็นต์</th>
                          <th>แรงดันจากสายน้ำมัน</th>
                          <th>วันที่บันทึก</th>
                          <th>การจัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>90%</td>
                          <td>0.8v</td>
                          <td>14-12-2024</td>
                          <td>
                            <button onClick={() => alert("แก้ไขข้อมูล")}>แก้ไข</button>
                            <button onClick={() => alert("ลบข้อมูล")}>ลบ</button>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>30%</td>
                          <td>6.3v</td>
                          <td>11-12-2024</td>
                          <td>
                            <button onClick={() => alert("แก้ไขข้อมูล")}>แก้ไข</button>
                            <button onClick={() => alert("ลบข้อมูล")}>ลบ</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        )}



        {selectedOption === 'option2' && (
          <div>
            <h1>จัดการเชื้อเพลิง - รายการ 2</h1>
            <p>เนื้อหาของตัวเลือกที่ 2</p>
          </div>
        )}



      </div>
    </div>
  );
};

export default Fuelmanag;
