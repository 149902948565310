import React, { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import '../styles/report.css';
import { MdHomeFilled } from "react-icons/md";
import { MdInsertChartOutlined } from "react-icons/md";
import { BiCalendarEvent } from "react-icons/bi";
import { BiTask } from "react-icons/bi";

// นำเข้าคอนเทนต์จากไฟล์ต่างๆ
import OverviewW1 from '../components/OverviewW1';
import ReportContent from '../components/ReportW2';
import AutoReport from '../components/AutoReportW3';
import Task from '../components/TaskW4';

const Report = () => {
  const [activeTab, setActiveTab] = useState('overview'); // ควบคุมแท็บที่เลือก

  // ฟังก์ชันสำหรับเรนเดอร์เนื้อหา
  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <OverviewW1 />;
      case 'report':
        return <ReportContent />;
      case 'auto-report':
        return <AutoReport />;
      case 'task':
        return <Task />;
      default:
        return <OverviewW1 />;
    }
  };

  return (
    <div className="full-screen-table">
      <table className="full-screen-table">
        <tbody>
          <tr>
            {/* เมนูด้านซ้าย */}
            <td>
              <div className="card card-container">
                <div
  className={`inner-card ${activeTab === 'overview' ? 'active' : ''}`}
  onClick={() => setActiveTab('overview')}
>
  <MdHomeFilled size={25} /> ภาพรวม
</div>
<div
  className={`inner-card ${activeTab === 'report' ? 'active' : ''}`}
  onClick={() => setActiveTab('report')}
>
  <MdInsertChartOutlined size={25} /> รายงาน
</div>
<div
  className={`inner-card ${activeTab === 'auto-report' ? 'active' : ''}`}
  onClick={() => setActiveTab('auto-report')}
>
  <BiCalendarEvent size={25} /> Auto Report
</div>
<div
  className={`inner-card ${activeTab === 'task' ? 'active' : ''}`}
  onClick={() => setActiveTab('task')}
>
  <BiTask size={25} /> Task
</div>
              </div>
            </td>

            {/* พื้นที่แสดงเนื้อหา */}
            <td>
              <div className="content">
                <div className="content-wrapper">
                  {renderContent()} {/* แสดงเนื้อหาตามแท็บที่เลือก */}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Report;
