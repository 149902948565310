// src/components/TrackW1.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCircle, FaStar, FaRegStar, FaSortAlphaDown } from "react-icons/fa";
import { TbRefresh } from "react-icons/tb";
import { TiWarning } from "react-icons/ti";
import { IoEye } from "react-icons/io5";
import { GrMoreVertical } from "react-icons/gr";
import { BsFuelPumpFill } from "react-icons/bs";
import { FaTemperatureHalf } from "react-icons/fa6";
import { FaSatellite } from "react-icons/fa";

import LoopContext from "../context/LoopContext";
import { useMap } from "../context/MapContext";

import '../styles/TrackW1.css';
import PortalMenu from "./PortalMenu";
import { createGroup, deleteGroupFromAPI, moveVehicleToGroup, updateGroupName } from "../apiCalls";


const TrackW1 = ({ groups, isLoading, refreshGroups, loadGroups, setGroups, toggleGroup, showToast, showIcon }) => {
  const [menuOpenGroupId, setMenuOpenGroupId] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showInputGroup, setShowInputGroup] = useState(false); 
  // State สำหรับเปิดเมนู popup ที่กด 3 จุดในรายการรถ 
  const [menuOpenActionId, setMenuOpenActionId] = useState(null); // ควบคุมการเปิด/ปิดเมนูใหม่
  const [menuPositionAction, setMenuPositionAction] = useState({ top: 0, left: 0 }); // ตำแหน่งเมนูใหม่
  const [subMenuVisible, setSubMenuVisible] = useState(false); // แสดง/ซ่อนเมนูย่อย
  const [userGroups, setUserGroups] = useState([]);
  // State สำหรับการแก้ไขชื่อกลุ่ม
  const [editingGroupId, setEditingGroupId] = useState(null); // ID ของกลุ่มที่กำลังแก้ไข
  const [editedGroupName, setEditedGroupName] = useState(""); // ชื่อกลุ่มที่แก้ไข
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [isFiltering, setIsFiltering] = useState(false); // สถานะว่ากำลังกรองอยู่หรือไม่
  const [filteredVehicles, setFilteredVehicles] = useState([]); // รถที่ผ่านการกรอง
  
  // ฟังชั่นจับตาดูข้อมูลจาก LoopContext แล้วอัปเดท
  const { data } = useContext(LoopContext);
  const { updateCarMarker } = useMap();

  useEffect(() => {
    // อัปเดตสถานะ GPS ใน groups เมื่อ Context เปลี่ยน
    if (data.length > 0) {
      const { gpsData } = data[data.length - 1]; // ดึงข้อมูลล่าสุด
      const updatedGroups = groups.map((group) => ({
        ...group,
        vehicles: group.vehicles.map((vehicle) => ({
          ...vehicle,
          gpsStatus: gpsData[vehicle.id]?.gpsStatus || "unknown",
          timeDifference: gpsData[vehicle.id]?.timeDifference || "N/A",
          dataDate: gpsData[vehicle.id]?.dataDate || "N/A",
          gpsStatusText: gpsData[vehicle.id]?.gpsStatusText || "N/A",
        })),
      }));
      setGroups(updatedGroups);
    }
  }, [data]); // ติดตามการเปลี่ยนแปลงของ `data`

  
  const navigate = useNavigate();

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  
    const filteredVehiclesByGroup = groups
      .map(group => ({
        ...group,
        vehicles: group.vehicles.filter(vehicle => {
          if (filter === "all") return true;
          if (filter === "online") return vehicle.gpsStatus !== "offline"; // กรองสถานะ online
          if (filter === "offline") return vehicle.gpsStatus === "offline"; // กรองสถานะ offline
          if (filter === "star") return vehicle.isStarred; // กรองรถที่ติดดาว
          return true;
        }),
      }))
      .filter(group => group.vehicles.length > 0);
  
    if (filter === "star") {
      const expandedGroupIds = filteredVehiclesByGroup.map(group => group.id);
      setGroups(prevGroups =>
        prevGroups.map(group => ({
          ...group,
          is_expanded: expandedGroupIds.includes(group.id),
        }))
      );
    }
  
    setFilteredVehicles(filteredVehiclesByGroup);
    setIsFiltering(filter !== "all");
  };
  
  


  // ฟังก์ชันจัดการการกดติดดาว

  const toggleStar = (vehicleId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) => ({
        ...group,
        vehicles: group.vehicles.map((vehicle) =>
          vehicle.id === vehicleId
            ? { ...vehicle, isStarred: !vehicle.isStarred } // สลับสถานะ
            : vehicle
        ),
      }))
    );
  
    // เก็บค่าสถานะใน localStorage
    const starredVehicles = JSON.parse(localStorage.getItem("starredVehicles")) || {};
    starredVehicles[vehicleId] = !starredVehicles[vehicleId]; // สลับค่าของรถใน localStorage
    localStorage.setItem("starredVehicles", JSON.stringify(starredVehicles));
  };
  
  
  // -------------------- ข้างบนเป็นส่วนของการกรองค่า 
  
  const handleTestToast = (message, type) => {
    showToast(message, type);
  };
  

  const toggleMenu = (groupId, event) => {
    const rect = event.target.getBoundingClientRect(); // ตำแหน่งของปุ่ม
    const viewportWidth = window.innerWidth; // ความกว้างหน้าจอ
    const viewportHeight = window.innerHeight; // ความสูงหน้าจอ
    const menuWidth = 200; // ความกว้างโดยประมาณของเมนู
    const menuHeight = 150; // ความสูงโดยประมาณของเมนู
  
    let top = rect.bottom + window.scrollY;
    let left = rect.left + window.scrollX;
  
    // ปรับตำแหน่งถ้าเมนูเกินขอบจอ
    if (top + menuHeight > viewportHeight) {
      top = rect.top + window.scrollY - menuHeight; // แสดงด้านบนแทน
    }
    if (left + menuWidth > viewportWidth) {
      left = viewportWidth - menuWidth - 10; // ลดขอบซ้าย
    }
  
    setMenuPosition({ top, left });
    setMenuOpenGroupId(menuOpenGroupId === groupId ? null : groupId);
  };
  

  const [expandedGroups, setExpandedGroups] = useState([]);
  
  // ใช้ groups จาก props เพื่ออัพเดต userGroups
  useEffect(() => {
    // console.log("ข้อมูลใน localStorage:", localStorage);
    if (groups && groups.length > 0) {
      setUserGroups(groups);
    }
  }, [groups]);


  const getGroupContentStyle = (groupId) => {
    const group = groups.find(g => g.id === groupId);
    if (!group || !group.is_expanded) return { maxHeight: "0px", overflow: "hidden" };
  
    return {
      maxHeight: "300px", // ความสูงสูงสุด (จับคู่กับ CSS)
      overflowY: "auto",  // เปิดการเลื่อนแนวตั้ง
      transition: "max-height 0.3s ease-in-out",
    };
  };
  
  
  
  useEffect(() => {
    // console.log("Groups updated:", groups); // Debug เพื่อดูว่าข้อมูลเปลี่ยนแปลงหรือไม่
  }, [groups]);
  
  useEffect(() => {
    // console.log("Expanded Groups updated:", expandedGroups); // Debug การเปลี่ยนแปลง
  }, [expandedGroups]);


// ฟั่งชั่นเรียก api เพื่อสร้างกลุ่มใหม่
const [groupName, setGroupName] = useState("");
const handleCreateGroup = async () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user || !user.id) {
    alert("ไม่สามารถระบุผู้ใช้ได้");
    return;
  }

  if (!groupName.trim()) {
    alert("กรุณากรอกชื่อกลุ่ม");
    return;
  }

  // สร้างกลุ่มใหม่
  const result = await createGroup({ name: groupName, user_id: user.id });

  if (result.success) {
    handleTestToast('ดำเนินการสำเร็จ', 'success');
    setShowInputGroup(false)
    setGroupName(""); // ล้างชื่อกลุ่ม

    // เรียกฟังก์ชันที่ส่งมาจาก Home (refreshGroups)
    if (refreshGroups) {
      refreshGroups();
    }
  } else {
    alert(`การสร้างกลุ่มล้มเหลว: ${result.message}`);
    handleTestToast('การสร้างกลุ่มล้มเหลว', 'error');
  }
};


// ฟังก์ชันจัดการการเปิดเมนูใหม่
const toggleActionMenu = (id, event) => {
  const rect = event.target.getBoundingClientRect(); // คำนวณตำแหน่งปุ่ม
  setMenuPositionAction({
    top: rect.bottom + window.scrollY, // ตำแหน่ง Y: ด้านล่างของปุ่ม
    left: rect.left + window.scrollX, // ตำแหน่ง X: ซ้ายของปุ่ม
  });
  setMenuOpenActionId(menuOpenActionId === id ? null : id); // สลับเปิด/ปิดเมนู
};


 // -------------- ฟังชั่นลบกลุ่ม 

 const deleteGroup = async (groupId) => {
  try {
    // เรียก API เพื่อลบกลุ่ม
    const result = await deleteGroupFromAPI(groupId);
    if (result.success) {
      showToast("ลบกลุ่มสำเร็จ", "success");
      setMenuOpenGroupId(null);

      // อัปเดตกลุ่มใน state (ลบออกจากรายการ)
      setGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));
    } else {
      showToast(`ไม่สามารถลบกลุ่มได้: ${result.message}`, "error");
    }
  } catch (error) {
    showToast("เกิดข้อผิดพลาดในการลบกลุ่ม", "error");
  }
};

 // -------------- ฟังชั่นย้ายกลุ่ม

const handleMoveVehicle = async (vehicleId, newGroupId) => {
  try {
    const result = await moveVehicleToGroup(vehicleId, newGroupId); // เรียก API
    if (result.success) {
      showToast("ย้ายยานพาหนะสำเร็จ", "success");
      setMenuOpenActionId(null);
      // รีเฟรชข้อมูลหลังการย้าย
      refreshGroups();
    } else {
      showToast(`ไม่สามารถย้ายยานพาหนะได้: ${result.message}`, "error");
    }
  } catch (error) {
    showToast("เกิดข้อผิดพลาดในการย้ายยานพาหนะ", "error");
  }
};




// ------------ ฟังชั่นแก้ชื่อกลุ่ม


const handleEditGroup = (groupId, currentName) => {
  setEditingGroupId(groupId); // กำหนด ID กลุ่มที่กำลังแก้ไข
  setEditedGroupName(currentName); // ตั้งค่าชื่อกลุ่มเดิม
};

const handleSaveGroupName = async (groupId) => {
  try {
    // เรียก API เพื่อบันทึกชื่อกลุ่มที่แก้ไข
    // ตัวอย่างการเรียก API (ต้องเพิ่มใน apiCalls.js)
    const result = await updateGroupName(groupId, editedGroupName);

    if (result.success) {
      showToast("บันทึกชื่อกลุ่มสำเร็จ", "success");

      // อัปเดตชื่อใน state
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId ? { ...group, name: editedGroupName } : group
        )
      );
    } else {
      showToast(`ไม่สามารถบันทึกชื่อกลุ่มได้: ${result.message}`, "error");
    }
  } catch (error) {
    showToast("เกิดข้อผิดพลาดในการบันทึกชื่อกลุ่ม", "error");
  } finally {
    setEditingGroupId(null); // ยกเลิกโหมดแก้ไข
  }
};

const handleCancelEdit = () => {
  setEditingGroupId(null); // ยกเลิกโหมดแก้ไข
  setEditedGroupName(""); // ล้างชื่อกลุ่มที่แก้ไข
};


// ฟังชั่นเมื่อคลิกที่รถ

const handleVehicleClick = (imei) => {

  console.log("Click" , imei);
  showIcon(imei);

};



// ---------------------------------------------------------------------- HTML ----------------------------------------------- 

  return (
    <>
      <h2 className="welcome-text">หน้าต่างที่ 1</h2>

      <input type="text" className="search-input" placeholder="ค้นหา..." />

      <div className="button-row button-row-second">





          <div className="button-group">
            <button
              className={`small-button ${selectedFilter === "all" ? "active" : ""}`}
              onClick={() => handleFilterChange("all")}
            >
              All
            </button>
            <button
              className={`small-button online-icon ${selectedFilter === "online" ? "active" : ""}`}
              onClick={() => handleFilterChange("online")}
            >
              <FaCircle size={16} />
            </button>
            <button
              className={`small-button offline-icon ${selectedFilter === "offline" ? "active" : ""}`}
              onClick={() => handleFilterChange("offline")}
            >
              <TiWarning size={18} />
            </button>
            <button
              className={`small-button star-icon ${selectedFilter === "star" ? "active" : ""}`}
              onClick={() => handleFilterChange("star")}
            >
              <FaStar size={18} />
            </button>
          </div>






        
        <div className="button-group-reverse">
        <button className="small-button refresh" onClick={refreshGroups}>
          <TbRefresh size={24} color="green" />
        </button>


          <button className="small-button"><FaSortAlphaDown /></button>
          <button className="small-button">AA</button>
        </div>
      </div>

      {showInputGroup && (
        <div className="input-group">
          <input
            type="text"
            className="input-field"
            placeholder="ป้อนชื่อกลุ่ม..."
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)} // เก็บค่าจาก Input
          />
          <button className="btn-ok" onClick={handleCreateGroup}>
            ok
          </button>
          <button
            className="btn-cancel"
            onClick={() => setShowInputGroup(false)}
          >
            X
          </button>
        </div>
      )}


      {/* Spin loading */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinnerX"></div>
        </div>
      )}

      {/* แสดงกลุ่ม */}
      <div className="parent-container">
        {!isLoading && (isFiltering ? filteredVehicles : groups).map((group) => (
          <div key={group.id} className="collapsible-group">
            <div
              className="group-header"
              onClick={() => toggleGroup(group.id, !group.is_expanded)} 
              style={{ cursor: "pointer" }}
            >
              <div className="left-content">
                {editingGroupId === group.id ? (
                  <div className="edit-container">
                    <input
                      type="text"
                      value={editedGroupName}
                      onChange={(e) => setEditedGroupName(e.target.value)}
                      className="edit-group-input"
                    />
                    <button
                      className="btn-save-ok"
                      onClick={() => handleSaveGroupName(group.id)}
                    >
                      OK
                    </button>
                    <button className="btn-cancel-x" onClick={handleCancelEdit}>
                      X
                    </button>
                  </div>
                ) : (
                  `${group.name} (${group.vehicles.length || 0})`
                )}
              </div>
              <div className="right-content">
                <span className="eye-icon"><IoEye size={18} /></span>
                <span
                  className="more-icon"
                  onClick={(event) => {
                    event.stopPropagation(); // ป้องกันการส่งเหตุการณ์ไปที่ group-header
                    toggleMenu(group.id, event);
                  }}
                >
                  <GrMoreVertical size={20} />
                </span>
              </div>
            </div>
            <div
              id={`group-content-${group.id}`}
              className={`group-content ${group.is_expanded ? "expanded" : ""}`}
              style={getGroupContentStyle(group.id)}
            >
              {/* แสดงยานพาหนะ */}
              {group.vehicles.map((vehicle) => (
                <div key={vehicle.id} className="card-item"
                onClick={() => handleVehicleClick(vehicle.gps_device.imei)} // เมื่อคลิกจะเรียกฟังก์ชัน
                style={{ cursor: "pointer" }} // เปลี่ยน cursor
                >
                
                <span
                  className="car-icon"
                  style={{
                    backgroundColor:
                      vehicle.gpsStatus === "offline"
                        ? "#424242"
                        : vehicle.gpsStatus === "stop"
                        ? "#f00232"
                        : vehicle.gpsStatus === "idle"
                        ? "#bfbf30"
                        : vehicle.gpsStatus === "run"
                        ? "#00bf17"
                        : vehicle.gpsStatus === "v"
                        ? "#0d7ad4"
                        : "#424242",
                  }}
                >
                  <img
                    src={
                      vehicle.icon === null || vehicle.icon === 1
                        ? "assets/car_icon_list/1.svg"
                        : vehicle.icon === 2
                        ? "assets/car_icon_list/2.svg"
                        : "assets/car_icon_list/1.svg"
                    }
                    alt="Vehicle Icon"
                  />
                </span>


                  <div className="card-text">
                    <div className="card-title">
                      {vehicle.license_plate || "ไม่มีข้อมูล"}
                      <div className="icon-group">
                        <span className="status-text">{vehicle.gpsStatusText || "N/A"}</span>

                        <span
                          className="more-icon"
                          onClick={(event) => toggleActionMenu(vehicle.id, event)}
                        >
                          <GrMoreVertical size={20} />
                        </span>

                      </div>
                    </div>
                    <div className="card-info">
                      <div className="card-description-time">
                        {/* <span className="card-imei">{vehicle.imei || "ไม่มี IMEI"}</span> */}
                        <span className="card-time">{vehicle.dataDate || "ไม่มีเวลา"}</span>
                      </div>
                      <div className="sensor-icons">
                        <div className="left-icons">
                          <span className="fuel-icon sensor-icon2"><BsFuelPumpFill size={10} /> {vehicle.fuel || "30"}%</span>
                          <span className="temp-icon sensor-icon2"><FaTemperatureHalf size={10} /> {vehicle.temperature || "10"}</span>
                        </div>
                        <div className="right-icons">
                          <span
                            className={`b-icon star-icon ${vehicle.isStarred ? "active" : ""}`} // เพิ่ม class ถ้าติดดาว
                            onClick={() => toggleStar(vehicle.id)} // เรียกฟังก์ชัน toggleStar
                          >
                            {vehicle.isStarred ? <FaStar /> : <FaRegStar />}
                          </span>
                          <span className="b-icon"><IoEye size={18} /></span>
                        </div>
                      </div>
                    </div>
                  </div>


            {/* เมนู Action */}
            {menuOpenActionId === vehicle.id && (
              <PortalMenu onClose={() => setMenuOpenActionId(null)}>
                <div
                  className="action-menu"
                  style={{
                    position: "absolute",
                    top: menuPositionAction.top,
                    left: menuPositionAction.left,
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    width: "200px",
                    zIndex: 9999,
                  }}
                >
            <ul>
            <li
                onMouseEnter={() => setSubMenuVisible(true)} // แสดงเมนูย่อยเมื่อเมาส์เข้า
                onMouseLeave={() => setSubMenuVisible(false)} // ซ่อนเมนูย่อยเมื่อเมาส์ออก
              >
                ย้ายกลุ่ม
                {subMenuVisible && (
                  <div className="sub-menu">
                    <ul>
                      {groups.map((group) => (
                        <li
                          key={group.id}
                          id={`group-${group.id}`}
                          onClick={() => handleMoveVehicle(vehicle.id, group.id)} // เรียกฟังก์ชันย้าย
                        >
                          {group.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>

          <li>แก้ไข</li>
          <li>รายงาน</li>
          <li>คำสั่ง</li>
          <li onClick={() => navigate('/fuel')} > เชื้อเพลิง </li>
        </ul>
      </div>
    </PortalMenu>
  )}



                </div>
              ))}
            </div>
          </div>
        ))}



  {menuOpenGroupId && (
          <PortalMenu onClose={() => setMenuOpenGroupId(null)}>
            <div
              className="group-menu"
              style={{
                position: "absolute",
                top: menuPosition.top,
                left: menuPosition.left,
                backgroundColor: "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                width: "200px",
                zIndex: 9999, // สำหรับกรณีที่จำเป็น
              }}
            >
              <ul>
              <li
                  onClick={() => {
                    setShowInputGroup(true); // เปิด Input Group
                    setMenuOpenGroupId(null); // ปิด PortalMenu
                  }}
                >
                  สร้างกลุ่ม
                </li>
                <li onClick={() => alert("Sticky on top")}>ปักหมุดไว้บนสุด</li>
                <li onClick={() => alert("Remove from top")}>ยกเลิกปักหมุด</li>
                
                <li
                  onClick={() => {
                    const group = groups.find((g) => g.id === menuOpenGroupId);
                    if (group && !group.is_default) { // ตรวจสอบว่าไม่ใช่กลุ่ม default ก่อน
                      handleEditGroup(group.id, group.name); // เปิดโหมดแก้ไข
                      setMenuOpenGroupId(null); // ปิดเมนู
                    }
                  }}
                  className={groups.find((g) => g.id === menuOpenGroupId)?.is_default ? "disabled" : ""}
                >
                  แก้ไข
                </li>


                  {/* ---------------- ปุ่มลบ  */}
                <li
                  onClick={() => {
                    if (groups.find((g) => g.id === menuOpenGroupId)?.vehicles.length === 0) {
                      deleteGroup(menuOpenGroupId);
                    }
                  }}
                  className={
                    groups.find((g) => g.id === menuOpenGroupId)?.vehicles.length > 0
                      ? "disabled"
                      : ""
                  }
                >
                  ลบ
                </li>

              </ul>
            </div>
          </PortalMenu>
        )}


     </div> {/* parent-container */}

    </>
  );
};

export default TrackW1;

