// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Report from './pages/report';
import Management from './pages/management';
import Fleet from './pages/fleet';
import LoginPage from './pages/LoginPage';
import RequireAuth from './RequireAuth';
import Fuelmanag from './components/Fuelmanag';

import { LoopProvider } from "./context/LoopContext";
import Track from "./components/TrackW1";

import './i18n';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC-rE7sut5FJo174h_Gri9DeLQW56sGJQw",
  authDomain: "gps-track-d94ef.firebaseapp.com",
  projectId: "gps-track-d94ef",
  storageBucket: "gps-track-d94ef.firebasestorage.app",
  messagingSenderId: "232755689446",
  appId: "1:232755689446:web:be330c349beb2ca1a78827",
  measurementId: "G-QRYL0BZ6NT"
};

// เริ่มต้น Firebase
const app = initializeApp(firebaseConfig);

// เริ่มต้น Firebase Cloud Messaging
const messaging = getMessaging(app);



// จัดการข้อความเมื่อแอปอยู่ในโฟร์กราวด์
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // กำหนดวิธีการแสดงการแจ้งเตือนในโฟร์กราวด์
});


const App = () => {
  const { i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken')); // ติดตามสถานะการล็อกอินด้วย state

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // ลบ token
    setIsLoggedIn(false); // อัปเดตสถานะการล็อกอิน
  };

  useEffect(() => {
    // ตรวจสอบ token ใน localStorage เมื่อโหลดแอป
    const token = localStorage.getItem('authToken');
    setIsLoggedIn(!!token);
  }, []);


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
  }, []);
  


  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
  
          // เมื่อ Service Worker พร้อมแล้ว เราถึงขอ FCM Token
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              getToken(messaging, { vapidKey: 'BIgQZLkbNkoJqm1yGaelgkFV9ZIZ3vRk9k7zwVqY5HH9bUJywxGEWxn_Cpb8ifMiuDMyDbz1pTLcD-ha0flpOvk' }).then((currentToken) => {
                if (currentToken) {
                  console.log('FCM Token:', currentToken);
                } else {
                  console.log('No registration token available.');
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token.', err);
              });
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
        }).catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
  }, []);
  

  
  return (
    
    <Router>
      <div style={styles.app}>
        {/* แสดง Navbar เฉพาะเมื่อผู้ใช้ล็อกอินแล้ว */}
        {isLoggedIn && <Navbar changeLanguage={changeLanguage} onLogout={handleLogout} />}
        <div style={styles.content}>
          <Routes>
            {/* แสดงหน้า Login โดยไม่ต้องใช้ layout */}
            <Route path="/login" element={<LoginPage onLogin={() => setIsLoggedIn(true)} />} />
            
            <Route path="/fuel" element={ <RequireAuth> <Fuelmanag /> </RequireAuth> } />

            {/* แสดงหน้าอื่นๆ ใน RequireAuth เมื่อผู้ใช้ล็อกอินแล้ว */}
            <Route
              path="/"
              element={
                <RequireAuth>
                  <LoopProvider>
                  <Home />
                  </LoopProvider>
                </RequireAuth>
              }
            />
            <Route
              path="/report"
              element={
                <RequireAuth>
                  <Report />
                </RequireAuth>
              }
            />
            <Route
              path="/management"
              element={
                <RequireAuth>
                  <Management />
                </RequireAuth>
              }
            />
            <Route
              path="/fleet"
              element={
                <RequireAuth>
                  <Fleet />
                </RequireAuth>
              }
            />
          </Routes>
          
        </div>
      </div>
    </Router>
  );
};



const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
};

export default App;
