// src\context\loopContext.js


import React, { createContext, useState, useEffect, useRef } from "react";
import { getGps_data } from "../apiCalls";

const LoopContext = createContext(); // เปลี่ยนชื่อ Context ให้สอดคล้อง

export const LoopProvider = ({ children }) => {
  const [data, setData] = useState([]); // เก็บข้อมูล GPS และเวลา
  const [secondsRemaining, setSecondsRemaining] = useState(15); // เริ่มต้นที่ 15 วินาที
  const [intervalRef, setIntervalRef] = useState(null); // เก็บ reference ของ interval
  const [onUpdatePosition, setOnUpdatePosition] = useState(() => () => {}); // Callback สำหรับอัปเดตตำแหน่ง
  const onUpdatePositionRef = useRef();

  const registerUpdatePositionCallback = (callback) => {
    console.log("Callback registered in LoopContext");
    onUpdatePositionRef.current = callback; // เก็บ callback ล่าสุดไว้ใน ref
  };

  
  // ฟังก์ชันเริ่มต้น loop
  const startLoop = () => {
    if (intervalRef) {
      console.warn("Loop is already running. Cannot start a new one.");
      return; // ไม่เริ่ม loop ใหม่หากมี loop อยู่แล้ว
    }
  
    // ดึงข้อมูลผู้ใช้จาก localStorage
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const imeis = [];
  
    // ดึง imei จาก vehicles ใน user.groups
    user.groups?.forEach((group) => {
      group.vehicles?.forEach((vehicle) => {
        if (vehicle.gps_device?.imei) {
          imeis.push(vehicle.gps_device.imei); // เก็บ IMEI
        }
      });
    });
  
    setSecondsRemaining(15); // รีเซ็ต secondsRemaining ทุกครั้งก่อนเริ่ม loop
  
    const newIntervalRef = setInterval(() => {
      setSecondsRemaining((prev) => {
        if (prev === 1) {
          clearInterval(newIntervalRef); // หยุด loop เมื่อ countdown ถึง 0
          setIntervalRef(null); // ล้างค่า intervalRef
          // เรียกฟังก์ชัน getGps_data พร้อม imei
          fetchGpsData(imeis);
          return 0; // ค้างที่ 0
        }
        return prev - 1; // ลด secondsRemaining ทีละ 1
      });
    }, 1000); // ลดทุก 1 วินาที
  
    setIntervalRef(newIntervalRef); // เก็บ reference ของ interval
  };

  

// ฟังก์ชันเรียก getGps_data พร้อม imei
const fetchGpsData = async (imeis) => {
  try {
    const response = await getGps_data(imeis);

    if (response.success) {

      // ดึงข้อมูล gps_data เดิมจาก localStorage
      const existingData = JSON.parse(localStorage.getItem("gps_data")) || [];

      // รวมข้อมูลเดิมและข้อมูลใหม่ โดยตรวจสอบว่าไม่มีการซ้ำกัน
      const updatedData = [...existingData];

      response.data.forEach((newItem) => {
        const index = updatedData.findIndex((item) => item.imei === newItem.imei);
        if (index !== -1) {
          // อัปเดตข้อมูลเดิม
          updatedData[index] = newItem;
        } else {
          // เพิ่มข้อมูลใหม่
          updatedData.push(newItem);
        }
      });

      // บันทึกข้อมูลที่อัปเดตลงใน localStorage
      localStorage.setItem("gps_data", JSON.stringify(updatedData));
      GpsStatus(); // เรียก GpsStatus เพื่อคำนวณสถานะใหม่ แล้วอัปเดท ui และเริ่ม loop อีกครั้ง
    } else {
      console.warn("Failed to fetch GPS data:", response.message);
    }
  } catch (error) {
    console.error("Error calling getGps_data:", error);
  }
};


  // ฟังก์ชันหยุด loop
  const stopLoop = () => {
    if (intervalRef) {
      clearInterval(intervalRef);
      setIntervalRef(null); // ล้างค่า intervalRef
    } else {
      console.warn("No loop is currently running to stop.");
    }
  };

  // ฟังก์ชัน reset นับถอยหลัง (กรณีต้องการใช้แยกต่างหาก)
  const resetCountdown = (time = 15) => {
    setSecondsRemaining(time); // รีเซ็ตเวลานับถอยหลัง
    console.log(`Countdown reset to ${time} seconds.`);
  };

  // ล้าง interval เมื่อ component ถูก unmount
  useEffect(() => {
    return () => {
      stopLoop();
    };
  }, []);


  // useEffect(() => {
  //   console.log("secondsRemaining:", secondsRemaining); 
  // }, [secondsRemaining]);


// ------------------------------------------------------ gps status ------------------------------------------------------

  const GpsStatus = async () => {
    try {
      startLoop();
      
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const vehicleIds = user.groups?.flatMap((group) =>
        group.vehicles.map((vehicle) => vehicle.id)
      ) || [];
  
      if (vehicleIds.length === 0) {
        console.warn("No vehicles found to calculate GPS status.");
        return;
      }
  
      let updatedStatus = {};
  
      const imeis = getImeisFromVehicleIds(vehicleIds);
      const gpsData = getGpsDataFromImeis(imeis);
      updatedStatus = calculateGpsStatus(gpsData, vehicleIds);

      // เพิ่มข้อมูล GPS ใน Context
      setData((prevData) => [
        ...prevData,
        { gpsData: updatedStatus, timestamp: new Date().toISOString() },
      ]);


      // ส่งพิกัดไปยัง Callback
    // ใน GpsStatus ให้เรียก callback จาก `onUpdatePositionRef`
    gpsData.forEach(({ imei, redisData }) => {
      if (redisData?.g_point) {
        const position = redisData.g_point.split(",").map(Number);
        const heading = parseFloat(redisData.heading) || 0;

        if (onUpdatePositionRef.current) {
          onUpdatePositionRef.current(imei, position, heading); // ใช้ callback ล่าสุด
        } else {
          console.warn("onUpdatePositionRef is not set!");
        }
      }
    });
     

    } catch (error) {
      console.error("Error in GpsStatus:", error);
    }
  };
  



  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    return isoDate.replace("T", " ").split(".")[0]; // แทนที่ T ด้วยช่องว่าง และลบเศษวินาที
  };




  const calculateGpsStatus = (gpsData, vehicleIds) => {
    const updatedStatus = vehicleIds.reduce((acc, vehicleId, index) => {
      const gpsInfo = gpsData[index]; // ข้อมูล gps_data ที่ตรงกับ vehicleId
      const { status, timeDifference } = checkOnlineStatus(gpsInfo);
  
      let gpsStatusText = "N/A";
  
      // เพิ่มข้อความสำหรับสถานะต่าง ๆ
      switch (status) {
        case "stop":
          gpsStatusText = `จอด ${timeDifference}`;
          break;
        case "idle":
          gpsStatusText = `สตาร์ท ${timeDifference}`;
          break;
        case "run":
          const speed = gpsInfo?.redisData?.speed || 0;
          gpsStatusText = `กำลังวิ่ง ${speed} Km/h`;
          break;
        case "v":
          gpsStatusText = `รับดาวเทียมไม่ได้ ${timeDifference}`;
          break;
        case "offline":
        default:
          gpsStatusText = `ออฟไลน์ ${timeDifference}`;
      }
  
      acc[vehicleId] = {
        gpsStatus: status,
        timeDifference: timeDifference,
        dataDate: formatDate(gpsInfo?.redisData?.data_date) || "N/A",
        gpsStatusText: gpsStatusText,
      };
  
      return acc;
    }, {});
  
    // บันทึกเฉพาะ gpsStatus ลงใน localStorage
    const gpsStatusOnly = Object.keys(updatedStatus).reduce((acc, vehicleId) => {
      acc[vehicleId] = { gpsStatus: updatedStatus[vehicleId].gpsStatus };
      return acc;
    }, {});
  
    localStorage.setItem("gps_status", JSON.stringify(gpsStatusOnly));
  
    return updatedStatus;
  };
  
  
  
  

  const checkOnlineStatus = (gpsInfo) => {
    // ตรวจสอบว่า gpsInfo มีข้อมูลครบถ้วนหรือไม่
    if (!gpsInfo || !gpsInfo.redisData || !gpsInfo.redisData.data_date) {
      return {
        status: "offline",
        timeDifference: "N/A",
      };
    }
  
    const rawDataDate = gpsInfo.redisData.data_date; // ดึงค่า data_date ดิบ
    const currentTime = new Date();
    const dataTime = new Date(rawDataDate);
  
    const timeDifferenceInMinutes = Math.floor((currentTime - dataTime) / (60 * 1000)); // คำนวณความแตกต่างในนาที
  
    // หากข้อมูลเก่ากว่า 30 นาที ถือว่า offline
    if (timeDifferenceInMinutes > 30) {
      return {
        status: "offline",
        timeDifference: `${timeDifferenceInMinutes} min+`,
      };
    }
  
    // หากไม่ offline ตรวจสอบสถานะเพิ่มเติม
    const gpsStatus = gpsInfo.redisData.gps_status || "V";
    const accStatus = gpsInfo.redisData.acc_status === "1"; // แปลงเป็น boolean
    // ปรับปรุงการแปลงค่า speed
    const speed = gpsInfo.redisData.speed 
      ? parseFloat(gpsInfo.redisData.speed.trim()) || 0 
      : 0;
  
    if (gpsStatus === "A") {
      if (!accStatus && speed === 0) {
        return {
          status: "stop",
          timeDifference: `${timeDifferenceInMinutes} min+`,
        };
      }
      if (accStatus && speed === 0) {
        return {
          status: "idle",
          timeDifference: `${timeDifferenceInMinutes} min+`,
        };
      }
      if (accStatus && speed > 0) {
        return {
          status: "run",
          timeDifference: `${timeDifferenceInMinutes} min+`,
        };
      }
    }
  
    // กรณี gps_status = V
    if (gpsStatus === "V") {
      return {
        status: "v",
        timeDifference: `${timeDifferenceInMinutes} min+`,
      };
    }
  
    // กรณีอื่น ๆ ถือว่า offline
    return {
      status: "offline",
      timeDifference: `${timeDifferenceInMinutes} min+`,
    };
  };
  
  
  
  const getImeisFromVehicleIds = (vehicleIds) => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const vehicles = user.groups?.flatMap(group => group.vehicles) || []; // รวมยานพาหนะทั้งหมดในกลุ่ม
  
    // ดึง imei ที่ตรงกับ vehicleIds
    const imeis = vehicleIds.reduce((acc, vehicleId) => {
      const vehicle = vehicles.find(v => v.id === vehicleId);
      if (vehicle?.gps_device?.imei) {
        acc.push(vehicle.gps_device.imei);
      }
      return acc;
    }, []);
  
    return imeis; // คืนค่า imei ทั้งหมดที่หาได้
  };

  const getGpsDataFromImeis = (imeis) => {
    const gpsData = JSON.parse(localStorage.getItem("gps_data")) || [];
  
    // ดึงข้อมูล gps_data ที่ตรงกับ imei
    const matchedGpsData = imeis.map(imei => {
      return gpsData.find(data => data.imei === imei) || null;
    });
  
    return matchedGpsData; // คืนค่าข้อมูล gps_data ที่ตรงกับ imei
  };
  



  
  return (
    <LoopContext.Provider value={{ data, secondsRemaining, registerUpdatePositionCallback, GpsStatus, startLoop }}>
      {children}
    </LoopContext.Provider>
  );
};

export default LoopContext; // Export ชื่อ Context
