import React, { createContext, useContext, useState } from "react";

// สร้าง Context
const MapContext = createContext();

// Hook สำหรับใช้งาน MapContext
export const useMap = () => useContext(MapContext);

// Provider สำหรับห่อ component ที่ต้องการใช้ map
export const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null); // เก็บ state ของ map

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};
